import React from 'react';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  const { make, model, location } = useStoreState((state) => state.listings);
  const regionUrlPrefix = useStoreState(
    (state) => state.session.regionUrlPrefix
  );
  const search = get(location, 'search');

  if (make || model || search)
    return (
      <Link to={`${regionUrlPrefix}/cars/used`}>
        <FontAwesomeIcon icon="times-circle" className="mr06em" />
        Clear
      </Link>
    );

  return null;
};
