import React from 'react';
import { Button, SkeletonLine } from 'components/az';
import FadeInDiv from 'components/anim/FadeInDiv';

export default function RadioBlock({ title, items, value, loading, save }) {
  if (loading === undefined) return null;

  return (
    <FadeInDiv>
      <h3>{title}</h3>
      {loading ? (
        <SkeletonLine />
      ) : (
        items.map((item) => (
          <Button
            viewtype={value === item.id ? 'red' : undefined}
            className="mr10 mb10"
            key={item.id}
            value={item.id}
            onClick={() => save(item.id)}
          >
            {item.name}
          </Button>
        ))
      )}
    </FadeInDiv>
  );
}
