import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';

export default () => {
  const value = useStoreState((state) => state.garage.make);
  const items = useStoreState((state) => state.core.makes);
  const setData = useStoreActions((actions) => actions.garage.setData);

  const onChange = (make) => setData({ make });

  return (
    <div>
      <label>Make</label>
      <DefSelect
        placeholder="Make"
        value={value}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};
