import userImg from 'assets/img/user.jpg';
import { trim, get as _get } from 'lodash';
import BaseModel from 'models';
import { buildImageUrl } from 'utility';

export default class UserModel extends BaseModel {
  get absoluteUrl() {
    return `/@${this.username}`;
  }

  get realname() {
    return trim(`${this.first_name} ${this.last_name}`);
  }

  get name() {
    return this.first_name || `@${this.username}`;
  }

  get avatarUrl() {
    if (!this.avatar) return userImg;

    const edits = {
      resize: {
        width: 400,
        height: 400,
        fit: 'cover',
      },
      rotate: null,
    };
    return buildImageUrl(this.avatar, edits);
  }

  get cnt() {
    return {
      cars: _get(this, ['cnt_cars'], 0),
      saved: _get(this, ['cnt_saved'], 0),
      saved_dealers: _get(this, ['cnt_saved_dealers'], 0),
      saved_services: _get(this, ['cnt_saved_services'], 0),
      messages: _get(this, ['cnt_unread_messages'], 0),
    };
  }
}
