import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { api } from 'api';
import CarUserModel from 'models/caruser';
import ListingCard from 'components/Cards/Listing';
import Loading from 'components/Loading';

const Content = styled.div`
  max-width: 800px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 20px 10px;
  }

  h2 {
    margin-bottom: 0;
  }
`;

const ListingWrapper = styled.div``;

export default ({ business }) => {
  const [listings, setListings] = useState(null);

  useEffect(() => {
    let didCancel = false;

    const fetch = async () => {
      const params = {
        business: business.id,
      };
      const data = await api.getData('/api/listings/', params);
      if (!didCancel) {
        setListings(data.results.map((x) => new CarUserModel(x)));
      }
    };

    fetch();

    return () => {
      didCancel = true;
    };
  }, [business.id]);

  if (listings === null) return <Loading />;

  return (
    <div>
      <Header>
        <h2>Listings</h2>
        {business.cars_count > 0 && (
          <div>
            {business.cars_count} car{business.cars_count !== 1 && 's'} for sale
          </div>
        )}
      </Header>

      <Content>
        {listings.map((item) => (
          <ListingWrapper key={item.id}>
            <ListingCard listing={item} />
          </ListingWrapper>
        ))}
      </Content>
    </div>
  );
};
