import React from "react";
import { Redirect } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

export default () => {
  const signOut = useStoreActions(actions => actions.session.signOut);
  signOut();

  return <Redirect to="/" />;
};
