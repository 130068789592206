import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'components/az';
import styled from 'styled-components';

const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  a {
    text-align: center;
  }
`;

const ListingOwnerButtons = ({ listing }) => {
  return (
    <Content>
      <Button
        viewtype="yellow"
        size="small"
        as={Link}
        to={listing.myAbsoluteUrl}
      >
        Dashboard
      </Button>
      {!listing.isArchived && (
        <>
          <Button
            viewtype="yellow"
            size="small"
            as={Link}
            to={`${listing.myAbsoluteUrl}/settings`}
          >
            Edit details
          </Button>
          <Button
            viewtype="yellow"
            size="small"
            as={Link}
            to={`${listing.myAbsoluteUrl}/photos`}
          >
            Edit photos
          </Button>
        </>
      )}
    </Content>
  );
};

export default ListingOwnerButtons;
