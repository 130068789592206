import React from 'react';
import styled, { css } from 'styled-components';

const Popover = styled.div`
  position: absolute;
  max-width: 350px;
  min-width: 300px;
  padding: 20px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.border.color};
  /* box-shadow: ${(props) => props.theme.shadow.medium}; */
  box-shadow: ${(props) => props.theme.shadow.hover};
  border-radius: 20px;
  top: 54px;
  /* bottom: -10px; */
  z-index: 1000;

  ${(props) =>
    props.top &&
    css`
      top: ${props.top}px;
    `};

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `};

  ${(props) =>
    props.placement === 'bottomLeft'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`;

export default ({ children, ...props }) => (
  <Popover {...props}>{children}</Popover>
);
