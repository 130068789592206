import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSpring } from 'react-spring';
import { Button } from 'components/az';

export default () => {
  const ref = useRef();
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((state) => state.newcar.nznew);
  const save = (nznew) => setData({ nznew, percent: 55 });

  const [, setY] = useSpring(() => ({ y: 0 }));
  useEffect(() => {
    setY({
      y: ref.current.offsetTop,
      reset: true,
      from: { y: window.scrollY },
      onFrame: (props) => window.scroll(0, props.y),
    });
  }, [setY]);

  return (
    <div ref={ref} className="mb10">
      <h3>Origin</h3>

      <Button
        viewtype={value === true ? 'red' : undefined}
        className="mr10 mb10"
        onClick={() => save(true)}
      >
        NZ new
      </Button>
      <Button
        viewtype={value === false ? 'red' : undefined}
        className="mb10"
        onClick={() => save(false)}
      >
        Imported
      </Button>
    </div>
  );
};
