import React from 'react';
import { times } from 'lodash';
import Tag from 'components/az/Tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServiceTags = ({ request }) => {
  return (
    <>
      {request.rating > 0 && (
        <small className="text-red mr10">
          {times(request.rating, () => (
            <FontAwesomeIcon icon={['fas', 'star']} />
          ))}
        </small>
      )}
      {request.status === 'PENDING' && <Tag color="yellow">Pending</Tag>}
      {request.status === 'ACCEPTED' && <Tag color="green">Accepted</Tag>}
      {request.status === 'DECLINED' && <Tag color="red">Declined</Tag>}
      {request.status === 'CANCELLED' && <Tag color="red">Cancelled</Tag>}
      {request.status === 'ARCHIVED' && <Tag>Archived</Tag>}
    </>
  );
};

export default ServiceTags;
