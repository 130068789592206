import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { useToggle } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/Modal';
import ModalNav from 'components/Mobile/ModalNav';
import styled from 'styled-components';
import { Button } from 'components/az';

const SocialIcons = styled.div`
  padding: 20px;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.theme.colors.secondary};

  a {
    color: ${(props) => props.theme.colors.secondary};
    padding: 5px;
  }
`;

export default () => {
  const regionUrlPrefix = useStoreState(
    (state) => state.session.regionUrlPrefix
  );
  const [on, toggle] = useToggle();

  //   <li>
  //   <NavLink to="/" exact>
  //     <FontAwesomeIcon icon={['fas', 'home']} fixedWidth />
  //     Home
  //   </NavLink>
  // </li>

  return (
    <>
      <Button shape="round" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'bars']} />
      </Button>
      {on && (
        <Modal close={toggle}>
          <ModalNav onClick={toggle}>
            <ul className="mb20">
              <li>
                <NavLink to={`${regionUrlPrefix}/cars`}>
                  <FontAwesomeIcon icon={['fas', 'car']} fixedWidth />
                  Auto market
                </NavLink>
              </li>
              <li>
                <NavLink to="/sell">
                  <FontAwesomeIcon
                    icon={['fas', 'digital-tachograph']}
                    fixedWidth
                  />
                  Sell a car
                </NavLink>
              </li>
              <li>
                <NavLink to={`${regionUrlPrefix}/dealers`}>
                  <FontAwesomeIcon icon={['fas', 'kiwi-bird']} fixedWidth />
                  Dealers
                </NavLink>
              </li>
              <li>
                <NavLink to={`${regionUrlPrefix}/services`}>
                  <FontAwesomeIcon icon={['fas', 'oil-can']} fixedWidth />
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink to="/garage">
                  <FontAwesomeIcon icon={['fas', 'warehouse']} fixedWidth />
                  My garage
                </NavLink>
              </li>
            </ul>
          </ModalNav>
          <SocialIcons onClick={toggle}>
            <NavLink to="/privacy">Privacy</NavLink>
            {' · '}
            <NavLink to="/terms">Terms</NavLink>
            {' · '}
            <a
              href="https://twitter.com/autozealand"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth />
            </a>
            {' · '}
            <a
              href="https://www.facebook.com/autozealand"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', 'facebook-f']} fixedWidth />
            </a>
          </SocialIcons>
        </Modal>
      )}
    </>
  );
};
