import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/az';
import { DayPickerSingleDateController } from 'react-dates';
import Popover from 'components/Popover';
import { useClickAway, useToggle } from 'react-use';
import classnames from 'classnames';
import moment from 'moment';
import { ClearButton } from 'components/az/Button';
import { useWider } from 'hooks';
import Modal from 'components/Modal';
import { api } from 'api';

const DateButton = styled(Button)`
  text-align: left;
  font-size: 16px;

  span {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 14px;
    display: block;
    margin-bottom: 4px;

    strong {
      color: ${(props) => props.theme.colors.text};
    }
  }
`;

const DateButtonWrapper = styled.div`
  position: relative;
`;

const ModalContent = styled.div`
  padding: 20px 0;
  height: 350px;
`;

const DATE_LABELS = {
  wof: 'WoF expires',
  rego: 'Licence expires',
  servicedue: 'Next service',
};

const DATE_EXPIRED_LABELS = {
  wof: 'WoF expired',
  rego: 'Licence expired',
  servicedue: 'Service planned',
};

export default ({ slug, initDate, field }) => {
  const [date, setDate] = useState(initDate);
  const [focused, setFocused] = useState(true);
  const ref = useRef(null);
  const [on, toggle] = useToggle(false);
  const isWider = useWider('tablet');

  useClickAway(ref, () => {
    toggle(false);
  });

  const onDateChange = async (d) => {
    setDate(d);
    toggle(false);
    try {
      await api.patchData(`/api/my/cars/${slug}/`, {
        [field]: d ? d.format('YYYY-MM-DD') : null,
      });
    } catch (e) {
      // nothing
    }
  };

  const months = date ? date.diff(moment(), 'months', true) : 0;
  const text = months >= 0 ? DATE_LABELS[field] : DATE_EXPIRED_LABELS[field];

  const button = (
    <DateButton block onClick={toggle}>
      <span>
        {text} {date && <strong>{date.fromNow()}</strong>} on
      </span>
      {date ? (
        <>
          <time
            dateTime={date.format('YYYY-MM-DD')}
            className={classnames({
              'text-warning': months >= 0 && months < 1,
              'text-error': months < 0,
            })}
          >
            {date.format('ddd, DD MMMM YYYY')}
          </time>
        </>
      ) : (
        <time className="text-warning">Add date</time>
      )}
    </DateButton>
  );

  if (isWider)
    return (
      <DateButtonWrapper ref={ref}>
        {button}
        {on && (
          <Popover noPadding top={70} placement="bottomLeft">
            <DayPickerSingleDateController
              noBorder
              hideKeyboardShortcutsPanel
              date={date}
              focused={focused}
              onDateChange={onDateChange}
              onFocusChange={setFocused}
              numberOfMonths={1}
              firstDayOfWeek={1}
              renderCalendarInfo={() => (
                <div className="pl20 mb20">
                  <ClearButton onClick={() => onDateChange(null)} />
                </div>
              )}
            />
          </Popover>
        )}
      </DateButtonWrapper>
    );

  return (
    <>
      {button}
      {on && (
        <Modal close={toggle}>
          <ModalContent>
            <DayPickerSingleDateController
              noBorder
              hideKeyboardShortcutsPanel
              date={date}
              focused={focused}
              onDateChange={onDateChange}
              onFocusChange={setFocused}
              firstDayOfWeek={1}
              numberOfMonths={12}
              orientation="verticalScrollable"
            />
          </ModalContent>
          <div className="py20 px20">
            <ClearButton onClick={() => onDateChange(null)} />
          </div>
        </Modal>
      )}
    </>
  );
};
