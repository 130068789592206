import React, { useState } from 'react';
import { Button, Input, Textarea } from 'components/az';
import styled from 'styled-components';
import { defErrorToast, priceFormatter } from 'utility';
import { api } from 'api';
import { useToggle } from 'react-use';
import { useStoreActions } from 'easy-peasy';
import { trim } from 'lodash';

const Wrapper = styled.div`
  padding: 30px;
  background: ${(props) => props.theme.colors.grey6};
  border-top: 1px solid #fff;

  label {
    display: block;
    color: ${(props) => props.theme.colors.secondary};
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const RecordRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'desc desc desc'
    'qty price total'
    'btns btns btns';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-areas:
      'desc qty price total'
      'btns . . .';
    grid-template-columns: 3fr 1fr 1fr 1fr;
  }
`;

const DescDiv = styled.div`
  grid-area: desc;
`;

const QtyDiv = styled.div`
  grid-area: qty;
  justify-self: end;
  text-align: right;
  input {
    text-align: right;
  }
`;

const PriceDiv = styled.div`
  grid-area: price;
  justify-self: end;
  text-align: right;
  input {
    text-align: right;
  }
`;

const TotalDiv = styled.div`
  text-align: right;
  grid-area: total;
  justify-self: end;
`;

const TextDiv = styled.div`
  padding-top: 10px;
`;

const BtnsDiv = styled.div`
  padding-top: 10px;
  grid-area: btns;
`;

const DetailsForm = ({ request, cancel }) => {
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState('');

  const setRequest = useStoreActions((action) => action.session.setRequest);
  const [loading, setLoading] = useToggle();

  const canSave = trim(description).length > 0;

  const save = async () => {
    setLoading(true);
    try {
      const response = await api.postData(
        `/api/service-requests/${request.id}/details/`,
        { description, unit_price: price, quantity: qty }
      );
      setRequest(response);
      cancel();
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  return (
    <RecordRow>
      <DescDiv>
        <label>Description of goods / services</label>
        <Textarea
          placeholder="Description of goods / services"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          rows={4}
        />
      </DescDiv>
      <QtyDiv>
        <label>Quantity</label>
        <Input
          type="number"
          step="0.01"
          min="0"
          placeholder="Qty."
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />
      </QtyDiv>
      <PriceDiv>
        <label>Unit price</label>
        <Input
          type="number"
          step="0.01"
          min="0"
          placeholder="Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </PriceDiv>
      <TotalDiv>
        <label>Total</label>
        <TextDiv>{priceFormatter.format(1 * qty * price)}</TextDiv>
      </TotalDiv>
      <BtnsDiv>
        <Button
          viewtype={canSave ? 'red' : undefined}
          disabled={!canSave}
          icon={['far', 'save']}
          title="Save"
          onClick={save}
          className="mr10"
          loading={loading}
        >
          Save
        </Button>
        <Button title="Delete" onClick={cancel}>
          Cancel
        </Button>
      </BtnsDiv>
    </RecordRow>
  );
};

export default ({ request }) => {
  const [on, toggle] = useToggle(request.details.length === 0);
  const [loading, setLoading] = useToggle();

  const setRequest = useStoreActions((action) => action.session.setRequest);

  const finalise = async () => {
    setLoading(true);
    try {
      const response = await api.postData(
        `/api/service-requests/${request.id}/archive/`
      );
      setRequest(response);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  if (request.status !== 'ACCEPTED') return null;

  return (
    <Wrapper>
      {on ? (
        <DetailsForm request={request} cancel={() => toggle(false)} />
      ) : (
        <>
          <Button
            viewtype="yellow"
            icon="plus"
            onClick={toggle}
            className="mr20"
          >
            Add a record
          </Button>
          or
          <Button
            viewtype="red"
            className="ml20"
            onClick={finalise}
            loading={loading}
          >
            Finalise
          </Button>
        </>
      )}
    </Wrapper>
  );
};
