import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Body from './Fields/Body';
import Gearbox from './Fields/Gearbox';
import Drive from './Fields/Drive';
import Engine from './Fields/Engine';
import FilterButton from './FilterButton';

export default () => {
  const drive = useStoreState((state) => state.listings.drive);
  const body = useStoreState((state) => state.listings.body);
  const gearbox = useStoreState((state) => state.listings.gearbox);
  const engineType = useStoreState((state) => state.listings.engineType);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const onClear = () => {
    setData({ drive: null, body: null, gearbox: null, engineType: null });
  };

  const content = (
    <ul>
      <li>
        <Body />
      </li>
      <li>
        <Engine />
      </li>
      <li>
        <Gearbox />
      </li>
      <li>
        <Drive />
      </li>
    </ul>
  );

  return (
    <FilterButton
      name="Extra"
      content={content}
      filtered={drive || body || gearbox || engineType}
      onClear={onClear}
    />
  );
};
