import { createStore, thunk } from 'easy-peasy';
import core from './core-model';
import session from './session-model';
import listings from './listings-model';
import dealers from './dealers-model';
import services from './services-model';
import newcar from './newcar-model';
import garage from './garage-model';
import mybusiness from './my-business-model';

const storeModel = {
  listings,
  dealers,
  services,
  core,
  session,
  newcar,
  garage,
  mybusiness,

  initialise: thunk(async (actions, payload, { dispatch }) => {
    dispatch.core.loadData();
  }),
};

export default createStore(storeModel, {
  name: 'AutoZealandStore',
});
