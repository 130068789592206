import BaseModel from 'models';
import { buildImageUrl } from 'utility';

export default class BusinessModel extends BaseModel {
  constructor(...args) {
    super(...args);
    this.field = 'business';
    this.photosApiAbsoluteUrl = '/api/business-photos/';
  }

  get logo400() {
    if (!this.logo) return null;

    const edits = {
      resize: {
        width: 400,
        height: 400,
        fit: 'contain',
        background: {
          r: 255,
          g: 255,
          b: 255,
          alpha: 1,
        },
      },
      rotate: null,
    };
    return buildImageUrl(this.logo, edits);
  }

  get isActive() {
    return this.status === 'ACTIVE';
  }

  get isPending() {
    return this.status === 'PENDING';
  }

  get isDraft() {
    return this.status === 'DRAFT';
  }

  get absoluteUrl() {
    return `/b/${this.slug}`;
  }

  get myAbsoluteUrl() {
    return `/my/business/${this.slug}`;
  }

  get apiAbsoluteUrl() {
    return `/api/businesses/${this.slug}/`;
  }

  get myApiAbsoluteUrl() {
    return `/api/my/businesses/${this.slug}/`;
  }

  get isService() {
    return this.subtype === 'SERVICE';
  }

  get isDealer() {
    return this.subtype === 'DEALER';
  }

  isOwner(user) {
    return user && user.id && this.user && user.id === this.user.id;
  }

  get images() {
    if (!this.photos) return null;

    return this.photos.map((item) => {
      const edits400 = {
        resize: {
          width: 400,
          height: 300,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      const edits800 = {
        resize: {
          width: 800,
          height: 600,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      const edits1600 = {
        resize: {
          width: 1600,
          height: 1200,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      const height600 = {
        resize: {
          height: 600,
          fit: 'cover',
        },
        rotate: null,
      };
      return {
        ...item,
        photo400: buildImageUrl(item.photo, edits400),
        photo800: buildImageUrl(item.photo, edits800),
        photo1600: buildImageUrl(item.photo, edits1600),
        photoHeight600: buildImageUrl(item.photo, height600),
      };
    });
  }
}
