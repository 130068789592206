import React, { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Select from 'components/NativeSelect';
import { rangeRight } from 'lodash';
import styled from 'styled-components';
import FilterButton from './FilterButton';

const sliderFrom = 1900;
const sliderTo = new Date().getFullYear();
const yearRange = rangeRight(sliderFrom, sliderTo + 1);

const Content = styled.div`
  margin-bottom: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

export const YearLogic = () => {
  const fromRef = useRef();
  const toRef = useRef();

  const yearFrom = useStoreState((state) => state.listings.yearFrom);
  const yearTo = useStoreState((state) => state.listings.yearTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const optionsFrom = (
    <>
      <option value="">Any</option>
      {yearRange.map((d) => (
        <option key={d} value={d} disabled={yearTo && d > yearTo}>
          {d}
        </option>
      ))}
    </>
  );

  const optionsTo = (
    <>
      <option value="">Any</option>
      {yearRange.map((d) => (
        <option key={d} value={d} disabled={yearFrom && d < yearFrom}>
          {d}
        </option>
      ))}
    </>
  );

  return (
    <Content>
      <div>
        <label htmlFor={fromRef.current}>
          Year from
          <Select
            block
            ref={fromRef}
            value={yearFrom || ''}
            onChange={(e) => setData({ yearFrom: e.target.value || null })}
          >
            {optionsFrom}
          </Select>
        </label>
      </div>
      <div>
        <label htmlFor={toRef.current}>
          to
          <Select
            block
            ref={toRef}
            value={yearTo || ''}
            onChange={(e) => setData({ yearTo: e.target.value || null })}
          >
            {optionsTo}
          </Select>
        </label>
      </div>
    </Content>
  );
};

export default () => {
  const yearFrom = useStoreState((state) => state.listings.yearFrom);
  const yearTo = useStoreState((state) => state.listings.yearTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  let name = 'Year';
  if (yearFrom || yearTo) {
    if (!yearFrom) {
      name = `Up to year ${yearTo}`;
    } else if (!yearTo) {
      name = `From year ${yearFrom}`;
    } else if (yearFrom === yearTo) {
      name = `Year ${yearFrom}`;
    } else {
      name = `Years ${yearFrom} - ${yearTo}`;
    }
  }

  const onClear = () => setData({ yearFrom: null, yearTo: null });

  return (
    <FilterButton
      name={name}
      content={<YearLogic />}
      filtered={yearFrom || yearTo}
      onClear={onClear}
    />
  );
};
