import React from 'react';
import { Alert, Button } from 'components/az';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledUl = styled.ul`
  padding-left: 30px;
  margin-top: 10px;
`;

const RoundedAlert = styled(Alert)`
  border-radius: 20px;
`;

export default ({ caruser }) => {
  const setCarUserStatus = useStoreActions(
    (actions) => actions.session.setCarUserStatus
  );
  const { slug } = caruser;

  const draft = async () => {
    try {
      setCarUserStatus({ slug, status: 'draft' });
    } catch (error) {
      // pass
    }
  };

  const publish = async () => {
    try {
      setCarUserStatus({ slug, status: 'publish' });
      toast.success(
        'Your listing is published and available to the website visitors.'
      );
    } catch (error) {
      // pass
    }
  };
  const pause = async () => {
    try {
      setCarUserStatus({ slug, status: 'pause' });
      toast.warning(
        'Your listing is paused and no longer available to the website visitors.'
      );
    } catch (error) {
      // pass
    }
  };

  const canPublish = caruser.price && caruser.district;

  if (caruser.isNone)
    return (
      <RoundedAlert type="grey">
        <p>
          The time has come to sell the car? Click &apos;Sell&apos; to create a
          draft listing.
        </p>
        <Button icon="eject" size="small" viewtype="primary" onClick={draft}>
          Sell
        </Button>
      </RoundedAlert>
    );

  return (
    <>
      {caruser.isPaused && (
        <RoundedAlert type="yellow">
          <p>
            The listing is <strong>PAUSED</strong> and not publicly available.
          </p>
          <Button viewtype="yellow" size="small" onClick={publish}>
            Publish again
          </Button>
        </RoundedAlert>
      )}
      {caruser.isDraft && (
        <RoundedAlert type="yellow">
          <p>
            Your listing is in <strong>DRAFT</strong> status.{' '}
            <Link to={`${caruser.myAbsoluteUrl}/settings`}>
              Check all the details
            </Link>{' '}
            and publish when you are ready.
          </p>
          {canPublish ? (
            <Button viewtype="yellow" size="small" onClick={publish}>
              Publish
            </Button>
          ) : (
            <div>
              The following is required before publishing the listing:
              <StyledUl>
                {caruser.price * 1 === 0 && <li>Car price</li>}
                {!caruser.district && <li>Car location</li>}
              </StyledUl>
            </div>
          )}
        </RoundedAlert>
      )}
      {caruser.isPublished && (
        <RoundedAlert type="green">
          <p>
            Your listing is <strong>PUBLISHED</strong>. Click &apos;Pause&apos;
            if you want to temporarily remove it from the public access.
          </p>
          <Button size="small" onClick={pause}>
            Pause
          </Button>
        </RoundedAlert>
      )}
    </>
  );
};
