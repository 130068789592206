import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button, Input } from 'components/az';
import { get } from 'lodash';
import { useToggle } from 'react-use';
import { toast } from 'react-toastify';
import { api } from 'api';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'username' 'button';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'username button';
  }
`;
const UsernameDiv = styled.div`
  grid-area: username;
`;
const ButtonDiv = styled.div`
  grid-area: button;
`;

const Form = ({ currentUsername, setEditMode }) => {
  const setUser = useStoreActions((actions) => actions.session.setUser);

  const [username, setUsername] = useState(currentUsername);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patchData('/api/profile/', { username });
      setUser(response);
      setEditMode(false);
      toast.success('The username has been saved');
    } catch (e) {
      setLoading(false);
      setError(
        get(
          e,
          ['response', 'data', 'username'],
          ['An error occurred please try again']
        ).join(', ')
      );
    }
  };

  const Error = error && (
    <small className="text-danger">
      <br />
      {error}
    </small>
  );

  return (
    <FormDiv>
      <UsernameDiv>
        <Input
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onPressEnter={save}
        />
        <small className="text-secondary">
          https://autozealand.co.nz/@{username}
        </small>
        {Error}
      </UsernameDiv>
      <ButtonDiv>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </ButtonDiv>
    </FormDiv>
  );
};

export default () => {
  const [on, toggle] = useToggle();
  const user = useStoreState((state) => state.session.user);

  return (
    <Content>
      <div>
        <h3>Username</h3>
        {on ? (
          <Form currentUsername={user.username} setEditMode={toggle} />
        ) : (
          <>
            {user.username}
            <br />
            <small className="text-secondary">
              https://autozealand.co.nz/@{user.username}
            </small>
          </>
        )}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
