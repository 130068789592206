import React, { useState } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { api } from 'api';
import { Button } from 'components/az';
import Select from 'components/NativeSelect';
import { Field, Formik, Form } from 'formik';
import { ErrorMessage } from 'components/form';
import * as Yup from 'yup';
import { useStoreState, useStoreActions } from 'easy-peasy';
import RegionDistrict from 'components/RegionDistrict';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'region' 'district' 'buttons';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'region district buttons';
    width: 66.6%;
  }
`;

const RegionDiv = styled.div`
  grid-area: region;
`;

const DistrictDiv = styled.div`
  grid-area: district;
`;
const ButtonsDiv = styled.div`
  grid-area: buttons;
`;

const FormSection = ({ business, setEditMode }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );

  const [region, setRegion] = useState(business.region);

  const regions = useStoreState((state) => state.core.regions);
  const districts = useStoreState((state) =>
    state.core.regionDistricts(region)
  );

  const initialValues = {
    region: business.region || '',
    district: business.district || '',
  };

  const validationSchema = Yup.object({
    // region: Yup.string().required('Region is required'),
    // district: Yup.string().required('District is required'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await api.patchData(business.myApiAbsoluteUrl, values);
      setSubmitting(false);
      setMyBusiness(response);
      setEditMode(false);
      toast.success('The address has been saved');
    } catch (e) {
      // pass
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <FormDiv>
            <RegionDiv>
              <Field
                as={Select}
                id={region}
                name="region"
                onChange={(e) => {
                  setRegion(e.target.value);
                  setFieldValue('region', e.target.value);
                  setFieldValue('district', '', false);
                }}
              >
                <option value="">Select region</option>
                <option value="" disabled>
                  --
                </option>
                {regions.allIds.map((id) => (
                  <option key={id} value={id}>
                    {regions.byId[id].name}
                  </option>
                ))}
              </Field>
              <ErrorMessage name="region" />
            </RegionDiv>

            <DistrictDiv>
              <Field as={Select} name="district" disabled={!region}>
                <option value="">Select district</option>
                <option value="" disabled>
                  --
                </option>
                {region &&
                  districts &&
                  districts.allIds.map((id) => (
                    <option key={id} value={id}>
                      {districts.byId[id].name}
                    </option>
                  ))}
              </Field>
              <ErrorMessage name="district" />
            </DistrictDiv>

            <ButtonsDiv>
              <Button loading={isSubmitting} type="submit" viewtype="primary">
                Save
              </Button>
            </ButtonsDiv>
          </FormDiv>
        </Form>
      )}
    </Formik>
  );
};

export default ({ business }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Region and district</h3>
        {on && <FormSection business={business} setEditMode={toggle} />}
        {!on &&
          (business.region ? (
            <RegionDistrict obj={business} />
          ) : (
            <span className="text-secondary">n/a</span>
          ))}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
