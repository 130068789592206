import React, { useCallback } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useScrollToTopOnMount } from 'hooks';
import ListingDetailsForm from 'components/Listing/DetailsForm';

export default ({ caruser }) => {
  useScrollToTopOnMount();

  const setData = useStoreActions((state) => state.newcar.setData);
  const setCarUser = useStoreActions((state) => state.session.setCarUser);

  const callback = useCallback((updated) => {
    setCarUser({ ...caruser, ...updated });
    setData({
      step: 3,
      percent: 80,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ListingDetailsForm caruser={caruser} callback={callback} />;
};
