import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';

export default ({ canonical, children, ...props }) => {
  const { url } = useRouteMatch();
  return (
    <Helmet
      titleTemplate="%s - AutoZealand"
      defaultTitle="AutoZealand - your car journal"
      {...props}
    >
      <meta name="apple-itunes-app" content="app-id=1608837486"></meta>
      {children}
      {canonical && (
        <link rel="canonical" href={`${process.env.REACT_APP_URL}${url}`} />
      )}
    </Helmet>
  );
};
