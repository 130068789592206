import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import { useParams, Switch, Route } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Loading from 'components/Loading';
import Header from './Header';
import Settings from './Settings';
import Submenu from './Submenu';
import Requests from './Requests';
import Photos from './Photos';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'submenu'
    'content';
  grid-template-columns: 1fr;
`;

const HeaderBlock = styled.div`
  grid-area: header;
`;
const SubmenuBlock = styled.div`
  grid-area: submenu;
`;
const ContentBlock = styled.div`
  grid-area: content;
`;

export default () => {
  useScrollToTopOnMount();

  const { slug } = useParams();
  const business = useStoreState((state) =>
    state.session.myBusinessBySlug(slug)
  );
  const loadMyBusiness = useStoreActions(
    (action) => action.session.loadMyBusiness
  );

  useEffect(() => {
    loadMyBusiness(slug);
  }, [loadMyBusiness, slug]);

  if (!business) return <Loading />;

  return (
    <Container>
      <HeaderBlock>
        <Header business={business} />
      </HeaderBlock>
      <SubmenuBlock>
        <Submenu business={business} />
      </SubmenuBlock>
      <ContentBlock>
        <Switch>
          <Route path={`${business.myAbsoluteUrl}/photos`}>
            <Photos business={business} />
          </Route>
          <Route path={`${business.myAbsoluteUrl}/requests`}>
            <Requests business={business} />
          </Route>
          <Route exact path={`${business.myAbsoluteUrl}`}>
            <Settings business={business} />
          </Route>
        </Switch>
      </ContentBlock>
    </Container>
  );
};
