import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((state) => state.newcar.gearbox);
  const save = (gearbox) => setData({ gearbox, trim: null, percent: 45 });

  return (
    <CarModelData
      title="Gearbox"
      dataType="gearboxes"
      value={value}
      paramKeys={['year', 'body', 'generation', 'engine_type', 'drive']}
      save={save}
    />
  );
};
