import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

const Container = styled.div`
  height: 5px;
  background-color: ${(props) => props.theme.colors.red};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition-property: width;
  transition-duration: 0.3s;
`;

export default () => {
  const percent = useStoreState((state) => state.newcar.percent);

  return <Container style={{ width: `${percent}%` }} />;
};
