import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from 'components/Header';
import Sell from 'pages/Sell';
import Cars from 'pages/Cars';
import Home from 'pages/Home';
import Member from 'pages/Member';
import SignOut from 'pages/SignOut';
import Profile from 'pages/Profile';
import SignIn from 'pages/SignIn';
import Listing from 'pages/Listing';
import Business from 'pages/Business';
import MessagesThread from 'pages/Messages/Thread';
import Modal from 'components/Modal';
import { useStoreState } from 'easy-peasy';
import { useWider } from 'hooks';
import Dealers from 'pages/Dealers';
import Services from 'pages/Services';
import MobileHeader from 'components/Mobile/Header';
import StaticPage from 'components/StaticPage';
import Garage from 'pages/Garage';
import ListingPreview from 'components/Listing/Preview';
import NewBusiness from 'pages/MyBusiness/New';
import NewCar from 'pages/MyGarage/New';
import NewBusinessCar from 'pages/MyBusiness/View/NewCar';
import RequestView from 'pages/MyBusiness/View/Requests/View';
import ServiceView from 'pages/MyGarage/View/Services/View';

const Container = styled.div``;

const HeaderDiv = styled.div`
  z-index: 1000;
  position: sticky;
  top: 0;
`;

const StandartWrapper = styled.div`
  padding: 20px 0;
  min-height: calc(100vh - ${(props) => props.theme.header.height + 40}px);
  background: #333;
`;

const StandartDiv = styled.div`
  border-radius: 10px;
  background: #fff;
  max-width: 800px;
  width: 100vw;
  margin: 0 auto;
`;

const ContentDiv = styled.div``;

const PrivateRoute = ({ component: Component, isAuthed, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthed ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default () => {
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const isWider = useWider('tablet');

  const location = useLocation();
  const background = location.state && location.state.background;

  return (
    <Container>
      {isWider ? (
        <HeaderDiv>
          <Header />
        </HeaderDiv>
      ) : (
        <MobileHeader />
      )}

      <ContentDiv>
        <Switch location={background || location}>
          <Route path="/" exact>
            <Home />
          </Route>
          <PrivateRoute
            path={['/settings', '/my', '/saved', '/messages']}
            isAuthed={isAuthed}
          >
            <Profile />
          </PrivateRoute>
          <Route path="/@:username">
            <StandartWrapper>
              <StandartDiv>
                <Member />
              </StandartDiv>
            </StandartWrapper>
          </Route>
          <Route path="/a/:slug">
            <StandartWrapper>
              <StandartDiv>
                <Listing />
              </StandartDiv>
            </StandartWrapper>
          </Route>
          <Route path="/b/:slug">
            <Business />
          </Route>
          <Route path="/signin">
            <SignIn />
          </Route>
          <Route path="/signout">
            <SignOut />
          </Route>
          <Route path="/privacy">
            <StaticPage slug="privacy" />
          </Route>
          <Route path="/terms">
            <StaticPage slug="terms" />
          </Route>
          <Route path={['/:region/cars', '/cars']}>
            <Cars />
          </Route>
          <Route path={['/:region/dealers', '/dealers']}>
            <Dealers />
          </Route>
          <Route path={['/:region/services', '/services']}>
            <Services />
          </Route>

          <Route path="/sell">
            <Sell />
          </Route>
          <Route path="/garage">
            <Garage />
          </Route>
          <Route>
            <StandartWrapper>
              <StandartDiv>
                <div style={{ padding: 20, textAlign: 'center' }}>
                  <p className="text-secondary" style={{ fontSize: 40 }}>
                    404
                  </p>
                  <p>
                    Sorry, we couldn’t find that page. Most likely, it’s under
                    development.
                  </p>
                  <p>
                    If you have any questions, please contact us{' '}
                    <a href="mailto:hello@autozealand.co.nz">
                      hello@autozealand.co.nz
                    </a>
                  </p>
                </div>
              </StandartDiv>
            </StandartWrapper>
          </Route>
        </Switch>
      </ContentDiv>

      {background && (
        <>
          <Route path="/signin">
            <Modal width="600">
              <SignIn />
            </Modal>
          </Route>
          <Route path="/@:username">
            <Modal>
              <Member />
            </Modal>
          </Route>
          <Route path="/my/garage/add">
            <Modal>
              <NewCar />
            </Modal>
          </Route>
          <Route path="/my/business/add">
            <Modal width="600">
              <NewBusiness />
            </Modal>
          </Route>
          <Route path="/my/business/:slug/add">
            <Modal>
              <NewBusinessCar />
            </Modal>
          </Route>
          <Route path="/my/business/:slug/requests/:id">
            <Modal>
              <RequestView />
            </Modal>
          </Route>
          <Route path="/my/garage/:slug/services/:id">
            <Modal>
              <ServiceView />
            </Modal>
          </Route>
          <Route path="/a/:slug">
            <Modal fullsize={1}>
              <Listing background={background} />
            </Modal>
          </Route>
          <Route path="/messages/:id">
            <Modal>
              <MessagesThread background={background} />
            </Modal>
          </Route>
        </>
      )}

      <Route path="/my/garage/:slug">
        <ListingPreview />
      </Route>
    </Container>
  );
};
