import React, { useState } from 'react';
import { Button, Textarea } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { dumbErrorHandler } from 'utility';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
  }
`;

const Form = ({ caruser, toggle }) => {
  const limit = 2000;
  const setCarUser = useStoreActions((actions) => actions.session.setCarUser);

  const [value, setValue] = useState(caruser.content);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const { content } = await api.patchData(caruser.myApiAbsoluteUrl, {
        content: value,
      });
      setCarUser({ ...caruser, content });
      toggle(false);
      toast.success('The comment has been saved');
    } catch (e) {
      setLoading(false);
      setError(dumbErrorHandler(e, 'content'));
    }
  };

  return (
    <FormDiv>
      <div>
        <Textarea
          rows={5}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        {error && <small className="text-danger">{error}</small>}

        <small
          className={value.length > limit ? 'text-danger' : 'text-secondary'}
        >
          {limit - value.length}{' '}
          {value.length > limit && '(please make it shorter)'}
        </small>
      </div>
      <div>
        <Button
          viewtype="primary"
          disabled={value.length > limit}
          loading={loading}
          onClick={save}
        >
          Save
        </Button>
      </div>
    </FormDiv>
  );
};

export default ({ caruser }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Listing description</h3>
        {on && <Form caruser={caruser} toggle={toggle} />}

        {!on &&
          (caruser.content || <span className="text-secondary">n/a</span>)}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
