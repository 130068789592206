import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

const Content = styled(animated.div)`
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin: 0 -20px;

  @media ${(props) => props.theme.devices.tablet} {
    margin: 0;
  }
`;

export default React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Content ref={ref} {...props}>
      {children}
    </Content>
  );
});
