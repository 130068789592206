import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import InlineLoading from 'components/InlineLoading';
import ItemCount from 'components/ItemCount';
import { api } from 'api';
import HorizontalMenu, { MenuItem } from 'components/HorizontalMenu';

export default () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      const data = await api.getData('/api/my/listings/stats/');
      if (!didCancel) {
        setStats(data);
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <HorizontalMenu>
      <MenuItem>
        <NavLink exact to="/my/listings">
          Published
          <ItemCount number={stats.PUBLISHED} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/my/listings/drafts">
          Drafts
          <ItemCount number={stats.DRAFT} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/my/listings/paused">
          Paused
          <ItemCount number={stats.PAUSED} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to="/my/listings/archived">
          Archived
          <ItemCount number={stats.ARCHIVED} />
        </NavLink>
      </MenuItem>
      {loading && (
        <MenuItem>
          <InlineLoading />
        </MenuItem>
      )}
    </HorizontalMenu>
  );
};
