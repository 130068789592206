import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import { useParams, Switch, Route } from 'react-router-dom';
import { api } from 'api';
import Loading from 'components/Loading';
import { useStoreActions, useStoreState } from 'easy-peasy';
import WhiteContent from 'components/WhiteContent';
import Header from './Header';
import Submenu from './Submenu';
import Details from './Details';
import Settings from './Settings';
import Photos from './Photos';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'submenu'
    'content';
  grid-template-columns: 1fr;
`;

const HeaderBlock = styled.div`
  grid-area: header;
`;
const SubmenuBlock = styled.div`
  grid-area: submenu;
`;
const ContentBlock = styled.div`
  grid-area: content;
`;

export default () => {
  useScrollToTopOnMount();
  const { slug } = useParams();

  const listing = useStoreState((state) => state.session.listingBySlug(slug));
  const setListing = useStoreActions((actions) => actions.session.setListing);

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      const data = await api.getData(`/api/my/listings/${slug}/`);
      if (!didCancel) {
        setListing(data);
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [slug, setListing]);

  return (
    <Container>
      {listing ? (
        <>
          <HeaderBlock>
            <Header listing={listing} />
          </HeaderBlock>
          <SubmenuBlock>
            <Submenu listing={listing} />
          </SubmenuBlock>
          <ContentBlock>
            <WhiteContent>
              <Switch>
                <Route path={`${listing.myAbsoluteUrl}/details`}>
                  <Details listing={listing} />
                </Route>
                <Route path={`${listing.myAbsoluteUrl}/photos`}>
                  <Photos listing={listing} />
                </Route>
                <Route exact path={listing.myAbsoluteUrl}>
                  <Settings listing={listing} />
                </Route>
              </Switch>
            </WhiteContent>
          </ContentBlock>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};
