import React from 'react';
import styled from 'styled-components';
import { useWider } from 'hooks';
import Reset from './Reset';
import MakeBtn from './Make';
import PriceBtn from './Price';
import MileageBtn from './Mileage';
import YearBtn from './Year';
import ExtraBtn from './Extra';
import UnitedBtn from './UnitedBtn';
import Count from './Count';

const Container = styled.div`
  user-select: none;
  padding: 0 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 20px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
  grid-template-areas: 'menu count';
`;

const MenuDiv = styled.div`
  grid-area: menu;

  ul.filter > li {
    display: inline-block;
    list-style-type: none;
    margin-right: 10px;
    position: relative;
  }
`;

const CountDiv = styled.div`
  grid-area: count;
  /* justify-self: center; */
`;

export default () => {
  const isWider = useWider('tablet');

  return (
    <Container>
      <Wrapper>
        <MenuDiv>
          {isWider ? (
            <ul className="filter">
              <li>
                <MakeBtn />
              </li>
              <li>
                <PriceBtn />
              </li>
              <li>
                <MileageBtn />
              </li>
              <li>
                <YearBtn />
              </li>
              <li>
                <ExtraBtn />
              </li>
              <li>
                <Reset />
              </li>
            </ul>
          ) : (
            <ul className="filter">
              <li>
                <UnitedBtn />
              </li>
              <li>
                <Reset />
              </li>
            </ul>
          )}
        </MenuDiv>
        <CountDiv>
          <Count />
        </CountDiv>
      </Wrapper>
    </Container>
  );
};
