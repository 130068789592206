import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Location from 'pages/Business/Location';
import styled from 'styled-components';
import Photos from './Photos';
import Info from './Info';
import Title from './Title';
import Contact from './Contact';
import Owner from './Owner';

const LocationWrapper = styled.div`
  padding: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
  }

  h2 {
    font-size: 20px;
  }
`;

export default () => {
  const { slug } = useParams();
  const user = useStoreState((state) => state.session.user);
  const listing = useStoreState((state) => state.session.listingBySlug(slug));
  const loadListing = useStoreActions((action) => action.session.loadListing);

  useEffect(() => {
    loadListing(slug);
  }, [loadListing, slug]);

  if (!listing) return <Loading padding={20} />;

  return (
    <>
      <Title listing={listing} />
      <Photos listing={listing} />
      {listing.isOwner(user) ? (
        <Owner listing={listing} />
      ) : (
        <Contact listing={listing} />
      )}
      <Info listing={listing} />
      {listing.business && (
        <LocationWrapper>
          <Location business={listing.business} />
        </LocationWrapper>
      )}
    </>
  );
};
