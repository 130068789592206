import React from 'react';
import styled, { css } from 'styled-components';

const Content = styled.div`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  background: ${(props) =>
    props.white ? '#fff' : props.theme.colors.background};
  box-sizing: border-box;
  padding: 15px 20px;

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding}px;
    `};
`;

export default React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Content ref={ref} {...props}>
      {children}
    </Content>
  );
});
