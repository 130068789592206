import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FadeInDiv from 'components/anim/FadeInDiv';
import Generation from './Fields/Generation';
import Model from './Fields/Model';
import Make from './Fields/Make';
import { YearLogic } from './Year';
import FilterButton from './FilterButton';

export default () => {
  const make = useStoreState((state) => state.listings.make);
  const model = useStoreState((state) => state.listings.model);
  const generation = useStoreState((state) => state.listings.generation);
  const yearFrom = useStoreState((state) => state.listings.yearFrom);
  const yearTo = useStoreState((state) => state.listings.yearTo);

  const setData = useStoreActions((actions) => actions.listings.setData);

  const filtered = make || model || generation || yearFrom || yearTo;

  const content = (
    <ul>
      <li>
        <Make />
      </li>
      {make && (
        <li>
          <FadeInDiv>
            <Model />
          </FadeInDiv>
        </li>
      )}
      {model && (
        <li>
          <FadeInDiv>
            <Generation />
          </FadeInDiv>
        </li>
      )}
      <li>
        <YearLogic />
      </li>
    </ul>
  );

  const onClear = () => {
    setData({
      make: null,
      model: null,
      generation: null,
      yearFrom: null,
      yearTo: null,
    });
  };

  return (
    <FilterButton
      name={<FontAwesomeIcon icon="sliders-h" />}
      content={content}
      filtered={filtered}
      onClear={onClear}
    />
  );
};
