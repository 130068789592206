import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import Helmet from 'components/Helmet';
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import GreyBody from 'components/GreyBody';
import { useScrollToTopOnMount } from 'hooks';
import WhiteContent from 'components/WhiteContent';
import Location from './Location';
import Summary from './Summary';
import Backedby from './Backedby';
import Services from './Services';
import Request from './Request';
import Header from './Header';
import Reviews from './Reviews';
import Listings from './Listings';

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 30px auto;
  padding: 0;

  h2 {
    font-size: 22px;
  }

  @media ${(props) => props.theme.devices.tablet} {
  }
`;

const Content = styled.div`
  display: grid;
  gap: 30px;
  padding: 0 20px;
`;

const ServiceContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-areas: 'summary' 'services' 'reviews' 'request';

  @media ${(props) => props.theme.devices.tablet} {
    gap: 30px;
    grid-template-columns: 1fr 400px;
    grid-template-areas: 'summary request' 'services request' 'reviews request';
  }
`;

const DealerContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-areas: 'summary' 'listings';

  @media ${(props) => props.theme.devices.tablet} {
    gap: 30px;
    grid-template-columns: 1fr 400px;
    grid-template-areas: 'summary .' 'listings .';
  }
`;

const SummaryDiv = styled.div`
  grid-area: summary;
`;

const ServicesDiv = styled.div`
  grid-area: services;
`;

const ReviewsDiv = styled.div`
  grid-area: reviews;
`;

const ListingsDiv = styled.div`
  grid-area: listings;
`;

const RequestDiv = styled.div`
  grid-area: request;
`;

export default () => {
  useScrollToTopOnMount();

  const { slug } = useParams();

  const business = useStoreState((state) => state.session.businessBySlug(slug));
  const loadBusiness = useStoreActions((action) => action.session.loadBusiness);

  useEffect(() => {
    loadBusiness(slug);
  }, [loadBusiness, slug]);

  if (!business) return <Loading padding={20} />;

  return (
    <Wrapper>
      <GreyBody />
      <Helmet canonical>
        <title>{business.name}</title>
      </Helmet>

      <Content>
        <Header business={business} />
        {business.isService && (
          <ServiceContainer>
            <SummaryDiv>
              <Summary business={business} />
            </SummaryDiv>
            <ServicesDiv>
              <Services business={business} />
            </ServicesDiv>
            <ReviewsDiv>
              <Reviews business={business} />
            </ReviewsDiv>
            <RequestDiv>
              <Request business={business} />
            </RequestDiv>
          </ServiceContainer>
        )}
        {business.isDealer && (
          <DealerContainer>
            <SummaryDiv>
              <Summary business={business} />
            </SummaryDiv>
            <ListingsDiv>
              <Listings business={business} />
            </ListingsDiv>
          </DealerContainer>
        )}
        <WhiteContent>
          <Location business={business} />
        </WhiteContent>
        <Backedby business={business} />
      </Content>
    </Wrapper>
  );
};
