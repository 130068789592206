import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-use';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  const location = useLocation();
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const user = useStoreState((state) => state.session.user);

  if (!isAuthed) return null;

  return (
    <ul>
      <li>
        <NavLink
          to={{
            pathname: user.absoluteUrl,
            state: { background: location },
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'address-card']}
            flip="horizontal"
            fixedWidth
          />
          Profile
        </NavLink>
      </li>
      <li>
        <NavLink to="/my/garage">
          <FontAwesomeIcon icon={['fas', 'warehouse']} fixedWidth />
          My garage
          {user.cnt.cars > 0 && <span>{user.cnt.cars}</span>}
        </NavLink>
      </li>
      <li>
        <NavLink to="/saved">
          <FontAwesomeIcon icon={['fas', 'heart']} fixedWidth />
          Saved
          {user.cnt.saved > 0 && <span>{user.cnt.saved}</span>}
        </NavLink>
      </li>
      <li>
        <NavLink to="/messages">
          <FontAwesomeIcon icon={['fas', 'comments']} fixedWidth />
          Messages
          {user.cnt.messages > 0 && <span>{user.cnt.messages}</span>}
        </NavLink>
      </li>
      <li>
        <NavLink to="/settings">
          <FontAwesomeIcon icon={['fas', 'cogs']} fixedWidth />
          Settings
        </NavLink>
      </li>
      {user.is_business && (
        <>
          <li className="separator" />
          <li>
            <NavLink to="/my/business">
              <FontAwesomeIcon icon={['fas', 'kiwi-bird']} fixedWidth />
              My business
            </NavLink>
          </li>
        </>
      )}
      <li className="separator" />
      <li>
        <NavLink to="/signout">
          <FontAwesomeIcon icon={['fas', 'sign-out-alt']} fixedWidth />
          Sign out
        </NavLink>
      </li>
    </ul>
  );
};
