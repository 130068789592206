import React from 'react';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import ListingCard from 'components/Cards/Listing';
import Skeleton from 'components/Cards/Listing/Skeleton';
import styled from 'styled-components';

const NoResults = styled.div`
  padding: 20px;
  border: 1px solid #fafafa;
  border-radius: 4px;
  background: #fafafa;
`;

const Wrapper = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 20px;

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.hover};
  }
`;

const ListingWrapper = styled.div`
  padding: 0 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0;
  }
`;

export default () => {
  const listings = useStoreState((state) => state.listings.records);
  const regionObj = useStoreState((state) => state.session.regionObj);

  if (listings === null)
    return (
      <Wrapper>
        <Skeleton />
      </Wrapper>
    );

  if (!listings.length)
    return (
      <NoResults>
        <p>
          No results{' '}
          {regionObj && (
            <>
              for <strong>{regionObj.name}</strong>
            </>
          )}
        </p>
        Try adjust your search or <Link to="/cars">remove all filters</Link>
      </NoResults>
    );

  return listings.map((item) => (
    <ListingWrapper key={item.id}>
      <ListingCard listing={item} />
    </ListingWrapper>
  ));
};
