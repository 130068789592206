import React, { useState, useRef } from 'react';
import { Button } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { api } from 'api';
import { DayPickerSingleDateController } from 'react-dates';
import Popover from 'components/Popover';
import { useClickAway, useToggle } from 'react-use';
import classnames from 'classnames';
import moment from 'moment';
import { useWider } from 'hooks';
import Modal from 'components/Modal';
import { dumbErrorHandler } from 'utility';
import { toast } from 'react-toastify';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ModalContent = styled.div`
  padding: 20px 0;
  height: 350px;
`;

const ExpireButton = ({ caruser }) => {
  const [date, setDate] = useState(moment(caruser.expires_on));
  const [error, setError] = useState('');
  const [focused, setFocused] = useState(true);
  const ref = useRef(null);
  const [on, toggle] = useToggle();
  const isWider = useWider('tablet');
  const setCarUser = useStoreActions((actions) => actions.session.setCarUser);

  useClickAway(ref, () => {
    toggle(false);
  });

  const onDateChange = async (d) => {
    setError('');
    toggle(false);
    try {
      const response = await api.patchData(caruser.myApiAbsoluteUrl, {
        expires_on: d.format('YYYY-MM-DD'),
      });
      setDate(d);
      setCarUser({ ...caruser, expires_on: response.expires_on });
      toast.success('The "Expires on" date has been updated');
    } catch (e) {
      setError(dumbErrorHandler(e, 'expires_on'));
    }
  };

  const days = date ? date.endOf('day').diff(moment(), 'days', true) : 0;

  const button = (
    <Button onClick={toggle}>
      <time
        dateTime={date.format('YYYY-MM-DD')}
        className={classnames({
          'text-warning': days >= 0 && days <= 7,
          'text-error': days < 0,
        })}
      >
        {date.format('dddd, DD MMMM YYYY')}
      </time>
    </Button>
  );

  const errorText = error && (
    <p>
      <small className="text-danger">{error}</small>
    </p>
  );

  if (isWider)
    return (
      <ButtonWrapper ref={ref}>
        {button}
        {errorText}
        {on && (
          <Popover noPadding top={50} placement="bottomLeft">
            <DayPickerSingleDateController
              noBorder
              enableOutsideDays={false}
              isOutsideRange={(day) => moment().startOf('day').diff(day) > 0}
              hideKeyboardShortcutsPanel
              date={date}
              focused={focused}
              onDateChange={onDateChange}
              onFocusChange={setFocused}
              numberOfMonths={1}
              firstDayOfWeek={1}
            />
          </Popover>
        )}
      </ButtonWrapper>
    );

  return (
    <>
      {button}
      {errorText}
      {on && (
        <Modal close={toggle}>
          <ModalContent>
            <DayPickerSingleDateController
              noBorder
              enableOutsideDays={false}
              isOutsideRange={(day) => moment().startOf('day').diff(day) > 0}
              hideKeyboardShortcutsPanel
              date={date}
              focused={focused}
              onDateChange={onDateChange}
              onFocusChange={setFocused}
              firstDayOfWeek={1}
              numberOfMonths={12}
              orientation="verticalScrollable"
            />
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ({ caruser }) => {
  return (
    <>
      <h3>Listing expires on</h3>
      <ExpireButton caruser={caruser} />
    </>
  );
};
