import React from 'react';
import { Button, Alert } from 'components/az';
import { useToggle } from 'react-use';
import { api } from 'api';
import { useStoreActions } from 'easy-peasy';

export default ({ business }) => {
  const [on, toggle] = useToggle();
  const setMyBusiness = useStoreActions(
    (action) => action.session.setMyBusiness
  );

  const apply = async () => {
    toggle(true);
    const response = await api.postData(`${business.myApiAbsoluteUrl}apply/`);
    setMyBusiness(response);
  };
  if (business.status !== 'DRAFT') return null;

  return (
    <Alert type="yellow">
      <p>
        The business is in the <strong>DRAFT</strong> status.
        <br />
        Configure as much as you can and then click &apos;apply for
        moderation&apos;
      </p>
      <Button loading={on} onClick={apply} viewtype="warning" size="small">
        Apply for moderation
      </Button>
    </Alert>
  );
};
