import React, { useState } from 'react';
import { Button, Input } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { dumbErrorHandler } from 'utility';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
  }
`;

const Form = ({ business, setEditMode }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );

  const [value, setValue] = useState(business.website);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patchData(business.myApiAbsoluteUrl, {
        website: value,
      });
      setMyBusiness(response);
      setEditMode(false);
      toast.success('The website has been saved');
    } catch (e) {
      setLoading(false);
      setError(dumbErrorHandler(e, 'website'));
    }
  };

  return (
    <FormDiv>
      <div>
        <Input
          placeholder="Website"
          type="url"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={save}
        />
        {error && <small className="text-danger">{error}</small>}
      </div>
      <div>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </div>
    </FormDiv>
  );
};

export default ({ business }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Website</h3>
        {on && <Form business={business} setEditMode={toggle} />}
        {!on &&
          (business.website ? (
            <a
              href={business.website}
              rel="noopener noreferrer"
              target="_blank"
            >
              {business.website}
            </a>
          ) : (
            <span className="text-secondary">n/a</span>
          ))}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
