import React from 'react';
import { Alert, Button } from 'components/az';
import { api } from 'api';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

export default ({ listing }) => {
  const setListing = useStoreActions((actions) => actions.session.setListing);

  const publish = async () => {
    try {
      const response = await api.postData(
        `${listing.myApiAbsoluteUrl}publish/`
      );
      setListing(response);
      toast.success(
        'Your listing is published and available to the website visitors.'
      );
    } catch (error) {
      // pass
    }
  };
  const pause = async () => {
    try {
      const response = await api.postData(`${listing.myApiAbsoluteUrl}pause/`);
      setListing(response);
      toast.warning(
        'Your listing is paused and no longer available to the website visitors.'
      );
    } catch (error) {
      // pass
    }
  };
  return (
    <>
      {listing.isPaused && (
        <Alert type="yellow">
          <p>
            The listing is <strong>PAUSED</strong> and not publicly available.
          </p>
          <Button viewtype="yellow" size="small" onClick={publish}>
            Publish again
          </Button>
        </Alert>
      )}
      {listing.isDraft && (
        <Alert type="yellow">
          <p>
            Your listing is in <strong>DRAFT</strong> status. Check all the
            details and publish when you are ready.
          </p>
          <Button viewtype="yellow" size="small" onClick={publish}>
            Publish
          </Button>
        </Alert>
      )}
      {listing.isPublished && (
        <Alert type="green">
          <p>
            Your listing is <strong>PUBLISHED</strong>. Click &apos;Pause&apos;
            if you want to temporarily remove it from the public access.
          </p>
          <Button size="small" onClick={pause}>
            Pause
          </Button>
        </Alert>
      )}
      {listing.isArchived && (
        <Alert type="blue">
          The listing is <strong>ARCHIVED</strong>
        </Alert>
      )}
    </>
  );
};
