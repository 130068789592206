import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/az';
import Helmet from 'components/Helmet';
import { useStoreState } from 'easy-peasy';
import Spinner from 'components/Spinner';
import { useToggle } from 'react-use';
import Filter from './Filter';

const Container = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
`;

export default () => {
  const location = useLocation();
  const updating = useStoreState((state) => state.garage.updating);
  const [on, toggle] = useToggle();

  return (
    <>
      <Helmet canonical>
        <title>My garage</title>
      </Helmet>
      <Container>
        <h1>
          My garage
          {updating && (
            <span>
              <Spinner />
            </span>
          )}
        </h1>

        <Button onClick={toggle} icon="sliders-h">
          Filter
        </Button>

        <Button
          as={Link}
          to={{
            pathname: `/my/garage/add`,
            state: { background: location },
          }}
          icon="plus-circle"
        >
          Add a car
        </Button>
      </Container>
      {on && <Filter />}
    </>
  );
};
