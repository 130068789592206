import React from 'react';
import { useWider } from 'hooks';
import { useSpring, animated } from 'react-spring';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import ListingOwnerButtons from 'components/Listing/OwnerButtons';
import styled from 'styled-components';
import Desktop from './Desktop';
import Mobile from './Mobile';

const Wrapper = styled(animated.div)`
  background: #fff;
  border-radius: 20px;
  margin: 0 -20px 20px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.hover};
    /* transform: translateY(-1px); */
  }

  @media ${(props) => props.theme.devices.tablet} {
    margin: 0 0 20px;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const ExtraContent = styled.div`
  font-size: 14px;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.border.color};
`;

const Extra = ({ listing }) => {
  return (
    <ExtraContent>
      {listing.expires_on && (
        <p className="mb10">
          Expires on {moment(listing.expires_on).format('LL')} (
          {moment(listing.expires_on).endOf('day').fromNow()})
        </p>
      )}

      <ListingOwnerButtons listing={listing} />
    </ExtraContent>
  );
};

export default ({ listing, pure }) => {
  const user = useStoreState((state) => state.session.user);
  const isWider = useWider('tablet');

  const { opacity } = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  if (!listing) return null;

  return (
    <Wrapper
      style={{
        opacity,
      }}
    >
      <Content>
        {isWider ? <Desktop listing={listing} /> : <Mobile listing={listing} />}
      </Content>
      {listing.isOwner(user) && !pure && <Extra listing={listing} />}
    </Wrapper>
  );
};
