import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  svg {
    color: ${(props) => props.theme.colors.red};
    margin-right: 0.5em;
  }
`;

const CountSpan = styled.span`
  color: ${(props) => props.theme.colors.secondary};
`;

export default ({ obj }) => {
  if (obj.reviews_count === 0)
    return (
      <StyledSpan>
        <FontAwesomeIcon icon={['far', 'star']} />
        no reviews
      </StyledSpan>
    );

  return (
    <StyledSpan>
      <FontAwesomeIcon icon={['fas', 'star']} />
      {obj.rating} <CountSpan>({obj.reviews_count})</CountSpan>
    </StyledSpan>
  );
};
