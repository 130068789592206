import React, { useState, useEffect, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useStoreState } from 'easy-peasy';
import RadioBlock from 'components/RadioBlock';
import { pick } from 'lodash';
import { useSpring } from 'react-spring';
import { api } from 'api';

export default ({ title, save, dataType, value, paramKeys }) => {
  const ref = useRef();
  const model = useStoreState((store) => store.newcar.model);
  const params = useStoreState((store) => pick(store.newcar, paramKeys));

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const [, setY] = useSpring(() => ({ y: 0 }));
  useEffect(() => {
    setY({
      y: ref.current.offsetTop,
      reset: true,
      from: { y: window.scrollY },
      onFrame: (props) => window.scroll(0, props.y),
    });
  }, [setY]);

  useDeepCompareEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      const data = await api.getCarModelData({ model, dataType, params });

      if (!didCancel) {
        setItems(data);
        setLoading(false);
        if (data.length === 1) save(data[0].id);
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [model, params]);

  return (
    <div ref={ref} className="mb10">
      <RadioBlock
        title={title}
        value={value}
        items={items}
        loading={loading}
        save={save}
      />
    </div>
  );
};
