import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import { Button } from 'components/az';

const StepsDiv = styled.div`
  position: sticky;
  top: 30px;
`;

export default () => {
  const make = useStoreState((state) => state.newcar.make);
  const step = useStoreState((state) => state.newcar.step);
  const reset = useStoreActions((actions) => actions.newcar.reset);

  return (
    <StepsDiv>
      <p>Step {step}:</p>
      <p className="mb20">
        {step === 1 && 'Make. Model. Generation.'}
        {step === 2 && 'Price and description'}
        {step === 3 && 'Photos and publish'}
      </p>
      {make && (
        <Button block onClick={reset}>
          Start over
        </Button>
      )}
    </StepsDiv>
  );
};
