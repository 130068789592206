import React from 'react';
import styled, { css } from 'styled-components';

const Content = styled.div`
  line-height: 1;
  font-size: 12px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid ${(props) => props.theme.bgcolors.grey};
  background: #fff;
  color: ${(props) => props.theme.colors.secondary};
  box-sizing: border-box;
  padding: 5px 10px;
  margin-right: 5px;

  ${(props) =>
    props.color &&
    css`
      color: #fff;
      border: 1px solid ${props.theme.colors[props.color]};
      background: ${props.theme.colors[props.color]};
    `};
`;

export default React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Content ref={ref} {...props}>
      {children}
    </Content>
  );
});
