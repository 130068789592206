import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScrollToTopOnMount } from 'hooks';
import { Well } from 'components/az';
import { api } from 'api';
import BusinessModel from 'models/business';
import BusinessCard from 'components/Cards/Business';

const Container = styled.div``;

export default () => {
  const [businesses, setBusinesses] = useState(null);

  useScrollToTopOnMount();

  useEffect(() => {
    let didCancel = false;

    const fetch = async () => {
      const params = {
        saved: true,
        subtype: 'SERVICE',
      };
      const data = await api.getData('/api/businesses/', params);
      if (!didCancel) {
        setBusinesses(data.results.map((b) => new BusinessModel(b)));
      }
    };

    fetch();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Container>
      {businesses === null && <Loading />}

      {businesses && (
        <>
          {businesses.length === 0 && (
            <>
              <Well white className="mb20">
                <p>
                  To save services you like, click on{' '}
                  <FontAwesomeIcon icon={['far', 'heart']} /> icon.
                </p>
                <Link to="/services">Explore services</Link>
              </Well>
            </>
          )}

          {businesses.map((business) => (
            <BusinessCard key={business.slug} business={business} />
          ))}
        </>
      )}
    </Container>
  );
};
