import React from 'react';
import styled from 'styled-components';
import { useWider, useScrollToTopOnMount } from 'hooks';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import GreyBody from 'components/GreyBody';
import Map from 'components/Map';
import { useStoreState } from 'easy-peasy';
import WrappedPagination from 'components/WrappedPagination';
import Filters from './Filters';
import Header from './Header';
import Records from './Records';

const Container = styled.div`
  @media ${(props) => props.theme.devices.tablet} {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-template-areas: 'services map';
  }
`;

const ServicesBlock = styled.div`
  @media ${(props) => props.theme.devices.tablet} {
    grid-area: services;
  }
`;

const MapBlock = styled.div`
  grid-area: map;

  > div {
    top: ${(props) => props.theme.header.height}px;
    position: sticky;
    height: calc(100vh - ${(props) => props.theme.header.height}px);
    box-shadow: ${(props) => props.theme.shadow.medium};
  }
`;

const Services = () => {
  const isWider = useWider('tablet');
  useScrollToTopOnMount();
  const services = useStoreState((state) => state.services.records);

  return (
    <Container>
      <GreyBody />
      <ServicesBlock>
        <Header />
        <Filters />
        <Records />
        <WrappedPagination store="services" />
      </ServicesBlock>
      {isWider && (
        <MapBlock>
          <Map businesses={services} />
        </MapBlock>
      )}
    </Container>
  );
};

export default () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={[`${path}/:make`, path]}>
        <Services />
      </Route>
    </Switch>
  );
};
