import React from 'react';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  const service = useStoreState((state) => state.services.service);
  const regionUrlPrefix = useStoreState(
    (state) => state.session.regionUrlPrefix
  );

  if (service)
    return (
      <Link to={`${regionUrlPrefix}/services`}>
        <FontAwesomeIcon icon="times-circle" className="mr06em" />
        Clear
      </Link>
    );

  return null;
};
