import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ListingCard from 'components/Cards/Listing';
import Loading from 'components/Loading';
import { get } from 'lodash';
import { Well } from 'components/az';
import { api } from 'api';
import CarUserModel from 'models/caruser';

const Container = styled.div`
  margin: 0 -20px;
  padding: 0 20px;
`;

const matrix = {
  published: 'PUBLISHED',
  drafts: 'DRAFT',
  paused: 'PAUSED',
  archived: 'ARCHIVED',
};

export default () => {
  const [loading, setLoading] = useState(true);
  const [listings, setListings] = useState([]);

  const params = useParams();
  const status = get(matrix, [params.status], 'PUBLISHED');

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      const data = await api.getData('/api/my/listings/', { status });
      if (!didCancel) {
        setLoading(false);
        setListings(data.map((l) => new CarUserModel(l)));
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [status]);

  return (
    <Container>
      {loading && <Loading />}
      {!loading && (
        <>
          {listings.length > 0 ? (
            listings.map((listing) => (
              <ListingCard key={listing.slug} listing={listing} />
            ))
          ) : (
            <Well white className="mb20">
              No listings to display in this group.
            </Well>
          )}
        </>
      )}
    </Container>
  );
};
