import React, { useEffect } from 'react';
import styled from 'styled-components';
import NewCarForm from 'components/NewCarForm';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button } from 'components/az';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Helmet from 'components/Helmet';

const Container = styled.div`
  padding: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title reset'
    'form form';

  h1 {
    font-size: 25px;
    margin-bottom: 0;
  }
`;

const TitleBlock = styled.div`
  grid-area: title;
`;
const ResetBlock = styled.div`
  grid-area: reset;
`;
const FormBlock = styled.div`
  grid-area: form;
`;

const NewCar = () => {
  const title = useStoreState((state) => state.newcar.title('Add a car'));
  const slug = useStoreState((state) => state.newcar.slug);
  const colour = useStoreState((state) => state.newcar.colour);
  const make = useStoreState((state) => state.newcar.make);
  const reset = useStoreActions((action) => action.newcar.reset);
  const fetch = useStoreActions((action) => action.garage.fetch);
  const createCar = useStoreActions((actions) => actions.newcar.createCar);
  const doCnt = useStoreActions((action) => action.session.doCnt);

  const history = useHistory();

  useEffect(() => {
    return () => reset();
  }, [reset]);

  useEffect(() => {
    if (colour) createCar();
  }, [colour, createCar]);

  useEffect(() => {
    if (slug !== null) {
      history.push('/my/garage');
      doCnt({ field: 'cnt_cars', number: 1 });
      fetch();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [slug, history, fetch, doCnt]);

  return (
    <Container>
      <Helmet>
        <title>Add a car to my garage</title>
      </Helmet>

      <TitleBlock>
        <h1>{title}</h1>
      </TitleBlock>
      <ResetBlock>
        {make && (
          <Button onClick={reset} title="Redo">
            <FontAwesomeIcon icon="redo" />
          </Button>
        )}
      </ResetBlock>
      <FormBlock>
        <NewCarForm />
      </FormBlock>
    </Container>
  );
};

export default NewCar;
