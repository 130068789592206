import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import CarsListings from 'pages/Cars/Listings';

export default () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${url}/used`} />
      </Route>
      <Route
        path={[
          `${path}/used/:make/:model`,
          `${path}/used/:make`,
          `${path}/used`,
        ]}
      >
        <CarsListings />
      </Route>
    </Switch>
  );
};
