import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((store) => store.newcar.trim);
  const save = (trim) => setData({ trim, nznew: null, percent: 50 });

  return (
    <CarModelData
      title="Trim"
      dataType="trims"
      value={value}
      paramKeys={[
        'year',
        'body',
        'generation',
        'engine_type',
        'drive',
        'gearbox',
      ]}
      save={save}
    />
  );
};
