import React from 'react';
import styled from 'styled-components';

const StyledSpan = styled.span`
  display: inline-block;
  padding-left: 0.5em;
  color: ${(props) => props.theme.colors.secondary};
`;

export default ({ number }) => {
  if (!number) return null;

  return <StyledSpan>{number}</StyledSpan>;
};
