import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((state) => state.newcar.year);
  const save = (year) => setData({ year, body: null, percent: 20 });

  return (
    <CarModelData
      title="Production year"
      dataType="years"
      value={value}
      paramKeys={[]}
      save={save}
    />
  );
};
