import React, { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonLink } from 'components/az';
import { useToggle, useClickAway } from 'react-use';
import styled from 'styled-components';
import { ClearButton } from 'components/az/Button';
import Modal from 'components/Modal';

const Content = styled.div`
  padding: 20px;

  ul {
    padding: 0;
    column-count: 2;
    column-gap: 20px;

    li {
      /* display: inline-block; */
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0 0 20px 0;

      svg {
        margin-right: 10px;
      }
    }
  }
  footer {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
`;

const LocationButton = styled(Button)`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default () => {
  const regionObj = useStoreState((state) => state.session.regionObj);
  const regions = useStoreState((state) => state.core.regions);
  const setData = useStoreActions((actions) => actions.session.setData);

  const ref = useRef(null);
  const [on, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  const save = (region) => {
    if (regionObj && region === regionObj.slug) {
      setData({ region: null });
    } else {
      setData({ region });
    }
  };

  return (
    <>
      <LocationButton onClick={toggle} active={!!regionObj} shape="round">
        <FontAwesomeIcon icon="map-pin" /> {regionObj ? regionObj.name : 'All'}
      </LocationButton>
      {on && (
        <Modal close={toggle}>
          <Content>
            <ul>
              {regions.allIds.map((id) => (
                <li key={id}>
                  <ButtonLink
                    active={regionObj && regionObj.id === id}
                    onClick={() => save(regions.byId[id].slug)}
                  >
                    {regionObj && regionObj.id === id && (
                      <FontAwesomeIcon icon="map-pin" />
                    )}
                    {regions.byId[id].name}
                  </ButtonLink>
                </li>
              ))}
            </ul>
            <footer>
              {regionObj ? <ClearButton onClick={() => save(null)} /> : <div />}
              <Button viewtype="primary" onClick={toggle}>
                Save
              </Button>
            </footer>
          </Content>
        </Modal>
      )}
    </>
  );
};
