import React, { useEffect, useRef } from 'react';
import NewCarForm from 'components/NewCarForm';
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import EmailAuth from 'components/EmailAuth';
import { AppleButton } from 'components/az/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from 'api';
import { v4 as uuid } from 'uuid';
import FadeInDiv from 'components/anim/FadeInDiv';

const Separator = styled.div`
  border-top: 1px solid ${(props) => props.theme.border.color};
  margin: 40px 0;
`;

export default () => {
  const ref = useRef(String(uuid()));

  const colour = useStoreState((store) => store.newcar.colour);
  const slug = useStoreState((store) => store.newcar.slug);
  const createCar = useStoreActions((actions) => actions.newcar.createCar);
  const setData = useStoreActions((state) => state.newcar.setData);

  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const setSessionToken = useStoreActions(
    (actions) => actions.session.setToken
  );

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: 'nz.co.autozealand.service',
      scope: 'name email',
      redirectURI: 'https://autozealand.co.nz/apple',
      usePopup: true,
      state: ref.current,
    });
  }, []);

  useEffect(() => {
    if (colour && isAuthed) {
      createCar();
    }
  }, [isAuthed, colour, createCar]);

  useEffect(() => {
    if (slug && isAuthed) {
      setData({ step: 2 });
    }
  }, [slug, isAuthed, setData]);

  const appleClick = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      if (data.authorization.state === ref.current) {
        const response = await api.checkAppleAuth(data);
        setSessionToken(response.token);
      }
    } catch (e) {
      // pass
    }
  };

  return (
    <>
      {colour ? (
        <FadeInDiv>
          <p className="mb20">
            We need your email address to send you messages from potential
            buyers. We won&apos;t share it with anyone.
          </p>
          <EmailAuth type="auth" />

          <Separator />

          <p className="mb20">
            If you an Apple user there is a faster alternative
          </p>
          <AppleButton className="mb10" onClick={appleClick}>
            <FontAwesomeIcon icon={['fab', 'apple']} fixedWidth /> Continue with
            Apple
          </AppleButton>
        </FadeInDiv>
      ) : (
        <NewCarForm />
      )}
    </>
  );
};
