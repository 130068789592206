import React from 'react';
import Pagination from 'components/Pagination';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';

const Wrapper = styled.div`
  padding: 20px 0;
`;

export default ({ store }) => {
  const page = useStoreState((state) => state[store].page);
  const pageSize = useStoreState((state) => state[store].page_size);
  const count = useStoreState((state) => state[store].count);
  const storedLocation = useStoreState((state) => state[store].location);

  return (
    <Wrapper>
      <Pagination
        page={page * 1}
        pageSize={pageSize}
        count={count}
        storedLocation={storedLocation}
      />
    </Wrapper>
  );
};
