import React, { useState } from 'react';
import { Button, Input } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { dumbErrorHandler } from 'utility';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    width: 66.6%;
  }
`;

const Form = ({ caruser, toggle }) => {
  const setCarUser = useStoreActions((actions) => actions.session.setCarUser);

  const [plate, setPlate] = useState(caruser.plate);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patchData(caruser.myApiAbsoluteUrl, {
        plate: plate.toUpperCase(),
      });
      setCarUser({ ...caruser, plate: response.plate });
      toggle(false);
      toast.success('The number plate has been saved');
    } catch (e) {
      setLoading(false);
      setError(dumbErrorHandler(e, 'plate'));
    }
  };

  const Error = error && <small className="text-danger">{error}</small>;

  return (
    <FormDiv>
      <div>
        <Input
          placeholder="Number plate"
          value={plate}
          onChange={(e) => setPlate(e.target.value)}
          onPressEnter={save}
        />
        {Error}
      </div>
      <div>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </div>
    </FormDiv>
  );
};

export default ({ caruser }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>
          Number plate{' '}
          <a
            href="https://www.nzta.govt.nz/vehicles/licensing-rego/number-plates/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary"
          >
            <FontAwesomeIcon icon={['far', 'question-circle']} />
          </a>
        </h3>
        {on ? (
          <Form caruser={caruser} toggle={toggle} />
        ) : (
          caruser.plate || <span className="text-secondary">n/a</span>
        )}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
