import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ListingCard from 'components/Cards/Listing';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScrollToTopOnMount } from 'hooks';
import { Well } from 'components/az';
import { api } from 'api';
import CarUserModel from 'models/caruser';

const Container = styled.div``;

export default () => {
  const [listings, setListings] = useState(null);

  useScrollToTopOnMount();

  useEffect(() => {
    let didCancel = false;

    const fetch = async () => {
      const params = { saved: true };
      const data = await api.getData('/api/listings/', params);
      if (!didCancel) {
        setListings(data.results.map((l) => new CarUserModel(l)));
      }
    };

    fetch();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <Container>
      {listings === null && <Loading />}

      {listings && (
        <>
          {listings.length === 0 && (
            <>
              <Well white className="mb20">
                <p>
                  To save listings you like, click on{' '}
                  <FontAwesomeIcon icon={['far', 'heart']} /> icon.
                </p>
                <Link to="/cars">Explore listings</Link>
              </Well>
            </>
          )}

          {listings.map((listing) => (
            <ListingCard key={listing.slug} listing={listing} />
          ))}
        </>
      )}
    </Container>
  );
};
