import React from 'react';
import { toInteger, get } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

const imageHandlerEndpoint = process.env.REACT_APP_IMAGE_HANDLER_ENDPOINT;
const staticFilesEndpoint = process.env.REACT_APP_STATIC_FILES_ENDPOINT;
const bucket = process.env.REACT_APP_STATIC_FILES_BUCKET;

export const buildImageUrl = (url, edits) => {
  const iDict = {
    bucket,
    key: url.replace(staticFilesEndpoint, '').split("?")[0],
    edits,
  };
  return `${imageHandlerEndpoint}${btoa(JSON.stringify(iDict))}`;
};

export const currencyFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const priceFormatter = new Intl.NumberFormat('en-NZ', {
  style: 'currency',
  currency: 'NZD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const mileageFormatter = new Intl.NumberFormat('en-NZ', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

export const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const toIntegerOrNull = (value) => (value ? toInteger(value) : null);

export const nl2p = (text) =>
  text.split('\n').map((item, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <p key={key}>{item}</p>
  ));

export const carListingsUrl = (region, make, model) =>
  [
    region ? `/${region}` : '',
    '/cars/used',
    make ? `/${make}` : '',
    make && model ? `/${model}` : '',
  ].join('');

export const dealersUrl = (region, make) =>
  [region ? `/${region}` : '', '/dealers', make ? `/${make}` : ''].join('');

export const servicesUrl = (region) =>
  [region ? `/${region}` : '', '/services'].join('');

export const dumbErrorHandler = (e, name) => {
  const fieldErrors = get(e, ['response', 'data', name], []);
  const nonFieldErrors = get(e, ['response', 'data', 'non_field_errors'], []);
  const err = fieldErrors.concat(nonFieldErrors);
  return err ? err.join(', ') : 'An error occurred please try again';
};

export const defErrorToast = () => {
  toast.error(
    'An unexpected error has occurred. Please try again in a few moments'
  );
};

export const lllAndFromNow = (date) => {
  const mdate = moment(date);
  return `${mdate.format('lll')} (${mdate.fromNow()})`;
};
