import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding: 20px;

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  ul {
    list-style-type: none;
    font-size: 18px;

    li {
      display: block;
      border-bottom: 1px solid ${(props) => props.theme.border.color};
      svg {
        color: ${(props) => props.theme.colors.grey2};
      }

      a {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 1em;
        align-items: center;

        color: ${(props) => props.theme.colors.text};
        padding: 15px 0;

        span {
          color: ${(props) => props.theme.colors.grey};
          font-size: 14px;
          background: ${(props) => props.theme.colors.grey6};
          padding: 2px 10px;
          border-radius: 14px;
        }

        &.active {
          color: ${(props) => props.theme.colors.primary};
          svg {
            color: ${(props) => props.theme.colors.primary};
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
    li.separator {
      border: none;
    }
  }
`;

export default (props) => <Content {...props} />;
