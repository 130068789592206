import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0 30px;
`;

const ServiceContent = ({ request }) => {
  if (!request) return null;

  return (
    <Wrapper>
      {request.content || (
        <span className="text-secondary">No comments provided</span>
      )}
    </Wrapper>
  );
};

export default ServiceContent;
