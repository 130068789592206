import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import { useParams, Switch, Route } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Loading from 'components/Loading';
import { get } from 'lodash';
import Header from './Header';
import Submenu from './Submenu';
import Photos from './Photos';
import Dashboard from './Dashboard';
import Settings from './Settings';
import Services from './Services';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
`;

const HeaderBlock = styled.div``;
const SubmenuBlock = styled.div``;
const ContentBlock = styled.div``;

export default () => {
  useScrollToTopOnMount();

  const { slug } = useParams();
  const caruser = useStoreState((state) => state.session.carUserBySlug(slug));
  const loadCarUser = useStoreActions((action) => action.session.loadCarUser);
  const loadListing = useStoreActions((action) => action.session.loadListing);

  const listingSlug = get(caruser, ['listing', 'slug']);

  useEffect(() => {
    loadCarUser(slug);
  }, [loadCarUser, slug]);

  useEffect(() => {
    if (listingSlug) {
      loadListing(listingSlug);
    }
  }, [loadListing, listingSlug]);

  if (!caruser) return <Loading padding={20} />;

  const url = caruser.myAbsoluteUrl;

  return (
    <Container>
      <HeaderBlock>
        <Header caruser={caruser} />
      </HeaderBlock>
      <SubmenuBlock>
        <Submenu caruser={caruser} />
      </SubmenuBlock>
      <ContentBlock>
        <Switch>
          <Route path={`${url}/services`}>
            <Services caruser={caruser} />
          </Route>
          <Route path={`${url}/photos`}>
            <Photos caruser={caruser} />
          </Route>
          <Route path={`${url}/settings`}>
            <Settings caruser={caruser} />
          </Route>
          <Route exact path={url}>
            <Dashboard caruser={caruser} />
          </Route>
        </Switch>
      </ContentBlock>
    </Container>
  );
};
