import React from 'react';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button } from 'components/az';
import { useWider } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from './Form';
import Title from './Title';

const Container = styled.div`
  background: #fff;
  padding: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
    border-radius: 20px;
  }
`;

const StepsDiv = styled.div`
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.secondary};
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
  min-height: 30px;
`;

const TitleDiv = styled.div`
  margin-bottom: 20px;
`;

const FormDiv = styled.div``;

const Steps = () => {
  const make = useStoreState((state) => state.newcar.make);
  const step = useStoreState((state) => state.newcar.step);
  const reset = useStoreActions((actions) => actions.newcar.reset);

  return (
    <>
      <div>
        <span>Step {step}/3:</span>{' '}
        {step === 1 && <span>Make. Model. Generation.</span>}
        {step === 2 && <span>Price and description</span>}
        {step === 3 && <span>Photos</span>}
      </div>
      <div>
        {make && (
          <Button size="small" onClick={reset} title="Redo">
            <FontAwesomeIcon icon="redo" />
          </Button>
        )}
      </div>
    </>
  );
};

export default () => {
  const isWider = useWider('tablet');
  return (
    <Container>
      {!isWider && (
        <StepsDiv>
          <Steps />
        </StepsDiv>
      )}
      <TitleDiv>
        <Title />
      </TitleDiv>
      <FormDiv>
        <Form />
      </FormDiv>
    </Container>
  );
};
