import React from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import { Button } from 'components/az';
import { useHistory } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import Photos from 'pages/MyGarage/View/Photos';
import { toast } from 'react-toastify';

const Container = styled.div``;

const PhotosDiv = styled.div`
  margin-bottom: 30px;
`;

const ButtonsDiv = styled.div`
  text-align: right;
`;

export default ({ caruser }) => {
  useScrollToTopOnMount();
  const history = useHistory();
  const { slug } = caruser;

  const setCarUserStatus = useStoreActions(
    (actions) => actions.session.setCarUserStatus
  );

  const onClickDraft = () => {
    history.push(caruser.myAbsoluteUrl);
    toast.success('Your listing is saved.');
    setCarUserStatus({ slug, status: 'draft' });
  };

  const onClickPublish = () => {
    history.push(caruser.myAbsoluteUrl);
    toast.success(
      'Your listing is published and available to the website visitors'
    );
    setCarUserStatus({ slug, status: 'publish' });
  };

  return (
    <Container>
      <h3>Upload photos</h3>

      <PhotosDiv>
        <Photos sellmode caruser={caruser} />
      </PhotosDiv>

      <ButtonsDiv>
        <Button className="mr10" onClick={onClickDraft}>
          Save draft
        </Button>
        <Button viewtype="primary" onClick={onClickPublish}>
          Publish my listing
        </Button>
      </ButtonsDiv>
    </Container>
  );
};
