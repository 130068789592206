import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ThreadBody from 'pages/Messages/Thread/Body';
import Loading from 'components/Loading';
import ThreadFooter from 'pages/Messages/Thread/Footer';

const Wrapper = styled.div``;

const ServiceMessages = ({ request }) => {
  const thread = useStoreState((state) =>
    state.session.threadById(request.thread)
  );
  const loadThread = useStoreActions((action) => action.session.loadThread);

  useEffect(() => {
    request.thread && loadThread(request.thread);
  }, [request.thread, loadThread]);

  if (!request.thread) return null;
  if (!thread) return <Loading padding={30} />;

  return (
    <Wrapper>
      <ThreadBody thread={thread} />
      <ThreadFooter thread={thread} />
    </Wrapper>
  );
};

export default ServiceMessages;
