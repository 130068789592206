/* eslint-disable no-param-reassign */
import { action, computed, thunk } from 'easy-peasy';
import { get, keys, pick, forEach, isUndefined } from 'lodash';
import qs from 'qs';
import { dealersUrl } from 'utility';
import { api } from 'api';
import BusinessModel from 'models/business';

const matrix = {
  region: 'region_slug',
  make: 'makes_slug',
  page: 'page',
  page_size: 'page_size',
};

const defSearchParams = {};

const defParams = {
  make: null,
  page: 1,
  page_size: 20,

  ...defSearchParams,
};

export default {
  ...defParams,
  records: [],
  updating: false,
  location: null,
  count: 0,

  region: computed([(_, storeState) => storeState.session.region], (x) => x),
  makeObj: computed(
    [(state, storeState) => storeState.core.makeBySlug(state.make)],
    (x) => x
  ),

  apiParamsObj: computed(
    [(state) => state, (_, storeState) => storeState.session.region],
    (state, region) => {
      const toReturn = {
        subtype: 'DEALER',
      };
      forEach(matrix, (value, key) => {
        toReturn[value] = key === 'region' ? region : state[key];
      });
      return toReturn;
    }
  ),
  searchParamsObj: computed((state) => pick(state, keys(defSearchParams))),

  // use it when you need to set anything
  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = isUndefined(value) ? null : value;
    });
  }),

  // use it when other search (!) parameters should be reset
  setParamsData: action((state, payload) => {
    forEach(defParams, (value, key) => {
      state[key] = get(payload, [key], value);
    });
  }),

  buildLocation: action((state, _) => {
    const { region, make } = state;
    const pathname = dealersUrl(region, make);
    const searchParamsObj = pick(state, keys(defSearchParams));
    const search = qs.stringify(searchParamsObj, {
      skipNulls: true,
      addQueryPrefix: true,
    });
    state.location = { pathname, search, state: { noParse: true } };
  }),

  fetch: thunk(async (actions, _, { getState, getStoreActions }) => {
    actions.setData({ updating: true });
    actions.buildLocation();
    const state = getState();
    const data = await api.getData('/api/businesses/', state.apiParamsObj);

    const { setBusiness } = getStoreActions().session;
    if (data.results) {
      data.results.forEach((item) => setBusiness(new BusinessModel(item)));
    }

    actions.setData({
      records: data.results
        ? data.results.map((d) => new BusinessModel(d))
        : [],
      count: data.count,
      updating: false,
    });
  }),
};
