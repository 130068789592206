import React from 'react';
import Helmet from 'components/Helmet';
import WhiteContent from 'components/WhiteContent';
import SettingItem from 'components/SettingItem';
import Avatar from './Avatar';
import Email from './Email';
import Name from './Name';
import Username from './Username';
import BusinessMenu from './BusinessMenu';

export default () => (
  <>
    <Helmet canonical>
      <title>Settings</title>
    </Helmet>

    <h1 className="mb20">Settings</h1>

    <WhiteContent>
      <SettingItem>
        <Avatar />
      </SettingItem>
      <SettingItem>
        <Email />
      </SettingItem>
      <SettingItem>
        <Name />
      </SettingItem>
      <SettingItem>
        <Username />
      </SettingItem>
      <SettingItem>
        <BusinessMenu />
      </SettingItem>
    </WhiteContent>
  </>
);
