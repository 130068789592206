import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

const Container = styled.div``;

export default () => {
  const { id } = useParams();
  const user = useStoreState((state) => state.session.user);
  const thread = useStoreState((state) => state.session.threadById(id));
  const loadThread = useStoreActions((action) => action.session.loadThread);

  useEffect(() => {
    if (user) loadThread(id);
  }, [user, loadThread, id]);

  if (!thread) return <Loading padding={20} />;

  return (
    <Container>
      <Header thread={thread} />
      <Body thread={thread} />
      <Footer thread={thread} />
    </Container>
  );
};
