import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Textarea, Input, Alert } from 'components/az';
import Select from 'components/NativeSelect';
import { Field, Formik, Form } from 'formik';
import { ErrorMessage } from 'components/form';
import * as Yup from 'yup';
import { useStoreState } from 'easy-peasy';
import FadeInDiv from 'components/anim/FadeInDiv';
import { api } from 'api';

const Container = styled.div`
  label {
    display: inline-block;
    margin-bottom: 10px;
  }
`;

const FormDiv = styled.div`
  display: grid;
  gap: 30px;
  align-items: start;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'price'
    'mileage'
    'region'
    'district'
    'content'
    'buttons';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'price mileage'
      'region district'
      'content content'
      'buttons buttons';
  }
`;

const PriceDiv = styled.div`
  grid-area: price;
`;

const MileageDiv = styled.div`
  grid-area: mileage;
`;

const ContentDiv = styled.div`
  grid-area: content;
`;

const RegionDiv = styled.div`
  grid-area: region;
  select {
    width: 100%;
  }
`;

const DistrictDiv = styled.div`
  grid-area: district;
  select {
    width: 100%;
  }
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto;
`;

const ListingDetailsForm = ({ caruser, callback }) => {
  const [region, setRegion] = useState(caruser.region);

  const regions = useStoreState((state) => state.core.regions);
  const districts = useStoreState((state) =>
    state.core.regionDistricts(region)
  );

  const initialValues = {
    price: caruser.price * 1 || '',
    mileage: caruser.mileage || '',
    content: caruser.content,
    region: caruser.region || '',
    district: caruser.district || '',
  };

  const validationSchema = Yup.object({
    price: Yup.number()
      .typeError('The car price must be a number')
      .positive('What is the price?')
      .required('What is the price?'),
    mileage: Yup.number()
      .typeError('The car mileage must be a number')
      .positive('What is the current mileage?')
      .required('What is the current mileage?'),
    region: Yup.string().required('Your buyers need to know the region'),
    district: Yup.string().required('Your buyers need to know the district'),
    content: Yup.string()
      .max(
        10000,
        'Your comment is too long. Please use 10000 characters or less'
      )
      .required('Tell us about your car'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const response = await api.patchData(
      `/api/my/cars/${caruser.slug}/`,
      values
    );
    callback && callback(response);
  };

  if (caruser.isArchived)
    return <Alert>The listing is archived and can not be edited</Alert>;

  return (
    <Container>
      <FadeInDiv>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <FormDiv>
                <PriceDiv>
                  <label htmlFor="price">Price, $</label>
                  <Field
                    name="price"
                    placeholder="Price"
                    as={Input}
                    step={1}
                    autoComplete="off"
                    type="number"
                    min={0}
                  />
                  <ErrorMessage name="price" />
                </PriceDiv>

                <MileageDiv>
                  <label htmlFor="mileage">Mileage, km</label>
                  <Field
                    name="mileage"
                    placeholder="Mileage"
                    as={Input}
                    step={1}
                    autoComplete="off"
                    type="number"
                    min={0}
                  />
                  <ErrorMessage name="mileage" />
                </MileageDiv>

                <RegionDiv>
                  <label htmlFor="region">Car location. Region</label>
                  <Field
                    as={Select}
                    name="region"
                    onChange={(e) => {
                      setRegion(e.target.value);
                      setFieldValue('region', e.target.value);
                      setFieldValue('district', '', false);
                    }}
                  >
                    <option value="">Choose region</option>
                    <option value="" disabled>
                      --
                    </option>
                    {regions.allIds.map((id) => (
                      <option key={id} value={id}>
                        {regions.byId[id].name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="region" />
                </RegionDiv>

                <DistrictDiv>
                  <label htmlFor="district">District</label>
                  <Field as={Select} name="district" disabled={!region}>
                    <option value="">Choose district</option>
                    <option value="" disabled>
                      --
                    </option>
                    {region &&
                      districts &&
                      districts.allIds.map((id) => (
                        <option key={id} value={id}>
                          {districts.byId[id].name}
                        </option>
                      ))}
                  </Field>
                  <ErrorMessage name="district" />
                </DistrictDiv>

                <ContentDiv>
                  <label htmlFor="content">Describe your car</label>
                  <Field name="content" as={Textarea} rows={7} />
                  <ErrorMessage name="content" />
                </ContentDiv>

                <ButtonsDiv>
                  <div>
                    <Button
                      loading={isSubmitting}
                      type="submit"
                      viewtype="primary"
                    >
                      Save
                    </Button>
                  </div>
                </ButtonsDiv>
              </FormDiv>
            </Form>
          )}
        </Formik>
      </FadeInDiv>
    </Container>
  );
};

export default ListingDetailsForm;
