import { find } from 'lodash';
import UserModel from './user';

export default class ThreadModel {
  constructor(...obj) {
    Object.assign(this, ...obj);
  }

  getParticipant(user) {
    if (!user) return null;
    return find(this.participants, (p) => p.user.id === user.id);
  }

  getOpponent(user) {
    if (!user) return null;

    const participant = find(this.participants, (p) => p.user.id !== user.id);

    if (!participant) return null;

    return new UserModel(participant.user);
  }
}
