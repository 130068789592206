import React from 'react';
import SettingItem from 'components/SettingItem';
import Avatar from './Avatar';
import Email from './Email';
import Name from './Name';
import Slug from './Slug';
import Phone from './Phone';
import Website from './Website';
import Delete from './Delete';
import Visibility from './Visibility';
import Address from './Address';
import Slogan from './Slogan';
import FullAddress from './FullAddress';
import Services from './Services';
import WhiteContent from 'components/WhiteContent';

export default ({ business }) => (
  <WhiteContent>
    <SettingItem>
      <Avatar business={business} />
    </SettingItem>
    <SettingItem>
      <Visibility business={business} />
    </SettingItem>
    <SettingItem>
      <Name business={business} />
    </SettingItem>
    <SettingItem>
      <Slug business={business} />
    </SettingItem>
    <SettingItem>
      <Slogan business={business} />
    </SettingItem>
    <SettingItem>
      <Address business={business} />
    </SettingItem>
    <SettingItem>
      <FullAddress business={business} />
    </SettingItem>
    <SettingItem>
      <Email business={business} />
    </SettingItem>
    <SettingItem>
      <Phone business={business} />
    </SettingItem>
    <SettingItem>
      <Website business={business} />
    </SettingItem>
    {business.isService && (
      <SettingItem>
        <Services business={business} />
      </SettingItem>
    )}
    <SettingItem>
      <Delete business={business} />
    </SettingItem>
  </WhiteContent>
);
