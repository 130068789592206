import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/az';
import Helmet from 'components/Helmet';
import { useStoreState } from 'easy-peasy';
import Spinner from 'components/Spinner';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas: 'title new';
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export default () => {
  const location = useLocation();
  const updating = useStoreState((state) => state.mybusiness.updating);

  return (
    <>
      <Helmet canonical>
        <title>My business</title>
      </Helmet>
      <Container>
        <h1>
          My business
          {updating && (
            <span>
              <Spinner />
            </span>
          )}
        </h1>

        <Button
          as={Link}
          to={{
            pathname: `/my/business/add`,
            state: { background: location },
          }}
        >
          <FontAwesomeIcon
            icon="plus-circle"
            className="text-danger"
            fixedWidth
          />{' '}
          Add
        </Button>
      </Container>
    </>
  );
};
