import React, { useState } from 'react';
import { Button } from 'components/az';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from 'styles/config';
import { api } from 'api';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defErrorToast } from 'utility';

const WatchButton = styled(Button)`
  &:hover {
    color: ${colors.error};
  }
`;

const UnWatchButton = styled(Button)`
  svg {
    color: ${colors.error};
  }

  &:hover {
    svg {
      color: ${colors.error};
      filter: brightness(90%);
    }
  }
`;

export default ({ obj }) => {
  const [status, setStatus] = useState(!!obj.is_watching);
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const doCnt = useStoreActions((action) => action.session.doCnt);
  const location = useLocation();
  const history = useHistory();

  const unauthed = () => {
    history.push({
      pathname: `/signin`,
      state: { background: location },
    });
  };

  const watch = async () => {
    try {
      if (!isAuthed) {
        unauthed();
      } else {
        setStatus(true);
        toast(
          <>
            <strong>{obj.name}</strong>
            <br />
            was added to your Saved list
          </>
        );
        await api.postData(`${obj.apiAbsoluteUrl}watch/`);
        doCnt({ field: 'cnt_saved', number: 1 });
      }
    } catch (error) {
      defErrorToast();
    }
  };

  const unwatch = async () => {
    try {
      if (!isAuthed) {
        unauthed();
      } else {
        setStatus(false);
        toast(
          <>
            <strong>{obj.name}</strong>
            <br />
            was removed from your Saved list
          </>
        );
        await api.deleteData(`${obj.apiAbsoluteUrl}watch/`);
        doCnt({ field: 'cnt_saved', number: -1 });
      }
    } catch (error) {
      defErrorToast();
    }
  };

  if (status)
    return (
      <UnWatchButton onClick={unwatch}>
        <FontAwesomeIcon icon={['fas', 'heart']} fixedWidth /> Saved
      </UnWatchButton>
    );

  return (
    <WatchButton onClick={watch}>
      <FontAwesomeIcon icon={['far', 'heart']} fixedWidth /> Save
    </WatchButton>
  );
};
