import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  background: #fff;
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  outline: none;
  line-height: 1.42;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: ${(props) => props.theme.colors.grey2};
  }

  &:-webkit-autofill {
    font-size: 16px;
  }
  /* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
} */

  &:hover,
  &:focus,
  &:active {
    &:not([disabled]) {
      border-color: ${(props) => props.theme.colors.grey4};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.colors.secondary};
    background: ${(props) => props.theme.colors.grey6};
    cursor: not-allowed;
  }
`;

export default React.forwardRef(({ onPressEnter, ...props }, ref) => {
  const onKeyDown = (e) => {
    if (e.keyCode === 13 && onPressEnter) {
      onPressEnter(e);
    }
  };

  return <StyledInput ref={ref} onKeyDown={onKeyDown} {...props} />;
});
