import React from 'react';
import { get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import WhiteContent from 'components/WhiteContent';
import Card from './Card';

const CardWrapper = styled.div`
  position: relative;
  background: white;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.hover};
  }
`;

const ContentDiv = styled.div`
  font-size: 15px;
`;

const RequestCard = ({ caruser, request }) => {
  const location = useLocation();

  const to = {
    pathname: `/my/garage/${caruser.slug}/services/${request.id}`,
    state: { background: location },
  };

  return (
    <CardWrapper>
      <Link to={to} className="cover" />
      <Card request={request} />
      <ContentDiv>
        {request.content || (
          <span className="text-secondary">No comments provided</span>
        )}
      </ContentDiv>
    </CardWrapper>
  );
};

export default ({ caruser }) => {
  const requests = get(caruser, ['requests'], []);
  const requestById = useStoreState((state) => state.session.requestById);

  if (requests.length === 0)
    return (
      <WhiteContent>
        You have not applied for a service yet.{' '}
        <Link to="/services">Search and apply for a service</Link>
      </WhiteContent>
    );

  return requests.map((item) => (
    <RequestCard
      key={item.id}
      caruser={caruser}
      request={requestById(item.id)}
    />
  ));
};
