import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import Helmet from 'components/Helmet';

const Container = styled.div`
  h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
`;

export default () => {
  const make = useStoreState((state) => state.newcar.make);
  const ttl = useStoreState((state) => state.newcar.title('Sell a car'));

  const title = make ? ttl : 'Sell a car';

  return (
    <>
      <Helmet>
        <title>{make ? `Sell ${title}` : title}</title>
      </Helmet>

      <Container>
        <h1>{title}</h1>
      </Container>
    </>
  );
};
