import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import Loading from 'components/Loading';
import BusinessCard from 'components/Cards/Business';
import { useUrl } from './hooks';

const Wrapper = styled.div`
  margin: 0 0 20px;
  padding: 0 20px;

  @media ${(props) => props.theme.devices.tablet} {
    margin: 0 20px 20px;
    padding: 0;
  }
`;

const EmptyWrapper = styled.div`
  margin: 0 20px;

  @media ${(props) => props.theme.devices.tablet} {
    margin: 0 40px;
  }
`;

export default () => {
  useUrl();
  const regionObj = useStoreState((state) => state.session.regionObj);
  const makeObj = useStoreState((state) => state.dealers.makeObj);
  const records = useStoreState((state) => state.dealers.records);

  if (records === null)
    return (
      <EmptyWrapper>
        <Loading />
      </EmptyWrapper>
    );

  if (records.length === 0)
    return (
      <EmptyWrapper>
        <p>
          So far, we have no {makeObj && <strong>{makeObj.name}</strong>}{' '}
          dealers to display
          {regionObj && (
            <>
              {' '}
              in <strong>{regionObj.name}</strong>
            </>
          )}
          .
        </p>
      </EmptyWrapper>
    );

  return (
    <Wrapper>
      {records.map((item) => (
        <BusinessCard key={item.id} business={item} />
      ))}
    </Wrapper>
  );
};
