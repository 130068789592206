import React from 'react';
import styled from 'styled-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = styled.div`
  text-align: center;
  line-height: 1;

  ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 20px;

    li {
      display: inline-block;
      margin: 0 10px;

      a {
        color: ${(props) => props.theme.colors.secondary};
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background: #fff;
        border-radius: 50%;

        &:hover {
          text-decoration: none;
          color: ${(props) => props.theme.colors.text};
        }
      }
    }
    li.current {
      a {
        border: 1px solid ${(props) => props.theme.colors.primary};
        background: ${(props) => props.theme.colors.primary};
        color: #fff;
      }
    }
    li.chevron {
      a {
        /* border: 1px solid ${(props) => props.theme.colors.primary}; */
        border-radius: 50%;
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
    color: ${(props) => props.theme.colors.secondary};
  }
`;

export default ({ page, pageSize, count, storedLocation }) => {
  const totalPages = Math.ceil(count / pageSize);
  const from = (page - 1) * pageSize + 1;
  const to = page * pageSize > count ? count : page * pageSize;

  const pageNums = Array(totalPages)
    .fill()
    .map((_, i) => i + 1);

  const { url } = useRouteMatch();
  const searchParamsObj = qs.parse(get(storedLocation, 'search', ''), {
    ignoreQueryPrefix: true,
  });

  const scrollTop = () =>
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    });

  const toObj = (i) => {
    return {
      pathname: url,
      search: qs.stringify(
        { ...searchParamsObj, page: i },
        {
          skipNulls: true,
          addQueryPrefix: true,
        }
      ),
    };
  };

  if (!count) return null;

  return (
    <Content>
      {totalPages !== 1 && (
        <ul>
          {page > 1 && (
            <li className="chevron">
              <Link to={toObj(page - 1)} onClick={scrollTop}>
                <FontAwesomeIcon icon="chevron-left" />
              </Link>
            </li>
          )}
          {pageNums.map((i) => (
            <li key={i} className={i === page ? 'current' : ''}>
              <Link to={toObj(i)} onClick={scrollTop}>
                {i}
              </Link>
            </li>
          ))}
          {page < totalPages && (
            <li className="chevron">
              <Link to={toObj(page + 1)} onClick={scrollTop}>
                <FontAwesomeIcon icon="chevron-right" />
              </Link>
            </li>
          )}
        </ul>
      )}
      <p>
        Showing {from} – {to} out of {count}
      </p>
    </Content>
  );
};
