import React, { useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';
import FilterButton from 'components/FilterButton';

const Service = () => {
  const value = useStoreState((state) => state.services.service);
  const items = useStoreState((state) => state.core.services);
  const setData = useStoreActions((actions) => actions.services.setData);

  const onChange = useCallback((service) => setData({ service }), [setData]);

  return (
    <DefSelect
      placeholder="Service type"
      value={value}
      items={items}
      onChange={onChange}
    />
  );
};

export default () => {
  const serviceObj = useStoreState((state) => state.services.serviceObj);
  const setData = useStoreActions((actions) => actions.services.setData);

  const onClear = () => {
    setData({ service: null });
  };

  return (
    <FilterButton
      name={serviceObj ? serviceObj.name : 'Service type'}
      storeName="services"
      itemName="service"
      content={<Service />}
      filtered={serviceObj !== null}
      onClear={onClear}
    />
  );
};
