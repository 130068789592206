import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import RadioBlock from 'components/RadioBlock';
import { concat, get } from 'lodash';

export default () => {
  const user = useStoreState((state) => state.session.user);
  const business = useStoreState((state) => state.newcar.business);
  const setData = useStoreActions((action) => action.newcar.setData);

  const dealers = get(user, ['dealers'], []);

  const items = concat(
    [{ id: 'personal', name: 'Personal' }],
    dealers.map((x) => {
      return { id: x.id, name: x.name };
    })
  );

  useEffect(() => {
    if (dealers.length === 0) setData({ business: 'personal' });
  }, [dealers, setData]);

  const save = (value) => {
    setData({ business: value });
  };

  return (
    <RadioBlock
      title="Personal or business?"
      value={business}
      items={items}
      loading={false}
      save={save}
    />
  );
};
