import React from 'react';
import styled from 'styled-components';
import Helmet from 'components/Helmet';
import { Link } from 'react-router-dom';

const Container = styled.div`
  a {
    color: ${(props) => props.theme.colors.text};
  }
`;

export default ({ business }) => {
  return (
    <>
      <Helmet canonical>
        <title>{business.name}</title>
      </Helmet>
      <Container>
        <Link to="/my/business">My businesses</Link>
        <h1>{business.name}</h1>
      </Container>
    </>
  );
};
