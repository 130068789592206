import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import { useWider, useScrollToTopOnMount } from 'hooks';
import GreyBody from 'components/GreyBody';
import Menu from './Menu';
import Preview from './Preview';
import Content from './Content';
import Progress from './Progress';

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'content';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 200px 1fr 400px;
    grid-template-areas: 'menu content preview';
  }
`;

const ContentDiv = styled.div`
  grid-area: content;
`;

const ProgressDiv = styled.div`
  height: 5px;
  position: sticky;
  top: 0;
  z-index: 20;

  @media ${(props) => props.theme.devices.tablet} {
    height: 20px;
  }
`;

const MenuDiv = styled.div`
  grid-area: menu;
  padding: 30px;
`;

const PreviewDiv = styled.div`
  grid-area: preview;
`;

export default () => {
  useScrollToTopOnMount();

  const reset = useStoreActions((action) => action.newcar.reset);
  const isWider = useWider('tablet');

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <>
      <GreyBody />
      <ProgressDiv>
        <Progress />
      </ProgressDiv>
      <Wrapper>
        <Container>
          <ContentDiv>
            <Content />
          </ContentDiv>
          {isWider && (
            <>
              <MenuDiv>
                <Menu />
              </MenuDiv>
              <PreviewDiv>
                <Preview />
              </PreviewDiv>
            </>
          )}
        </Container>
      </Wrapper>
    </>
  );
};
