import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { currencyFormatter } from 'utility';
import Price from './Fields/Price';
import FilterButton from './FilterButton';

export default () => {
  const priceFrom = useStoreState((state) => state.listings.priceFrom);
  const priceTo = useStoreState((state) => state.listings.priceTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const content = (
    <ul>
      <li>
        <Price />
      </li>
    </ul>
  );

  let name = 'Price';
  if (priceFrom || priceTo) {
    if (!priceFrom) {
      name = `Up to ${currencyFormatter.format(priceTo)}`;
    } else if (!priceTo) {
      name = `From ${currencyFormatter.format(priceFrom)}`;
    } else if (priceFrom === priceTo) {
      name = `For ${currencyFormatter.format(priceFrom)}`;
    } else {
      name = `${currencyFormatter.format(
        priceFrom
      )} - ${currencyFormatter.format(priceTo)}`;
    }
  }

  const onClear = () => {
    setData({ priceFrom: null, priceTo: null });
  };

  return (
    <FilterButton
      name={name}
      content={content}
      filtered={priceFrom || priceTo}
      onClear={onClear}
    />
  );
};
