import React, { useState } from 'react';
import Upload from 'components/Upload';
import { useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/az';
import { api } from 'api';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;

  span {
    outline: none;
  }

  img {
    width: 120px;
    border-radius: 10px;
  }
`;

export default ({ business }) => {
  const [loading, setLoading] = useState(false);
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );
  const [progress, setProgress] = useState('');

  const uploadProps = {
    name: 'logo',
    accept: '.jpg, .jpeg, .png',
    multiple: false,
    onStart(file) {
      setProgress('');
      setLoading(true);
    },
    onSuccess(response, file) {
      setLoading(false);
      setMyBusiness(response);
    },
    onError(err) {
      console.log('onError', err);
    },
    onProgress({ percent }, file) {
      setProgress(`${percent}%`);
      // console.log('onProgress', `${percent}%`, file.name);
    },
    customRequest({ file, filename, onError, onProgress, onSuccess }) {
      const formData = new FormData();

      formData.append(filename, file);

      api.axios
        .patch(business.myApiAbsoluteUrl, formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            );
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(onError);

      return {
        abort() {
          console.log('upload progress is aborted.');
        },
      };
    },
  };

  const deleteLogo = async () => {
    try {
      const response = await api.patchData(business.myApiAbsoluteUrl, {
        logo: null,
      });
      setMyBusiness(response);
    } catch (e) {
      // Pass
    }
  };

  return (
    <Content>
      <div>
        <h3>Logo</h3>

        {business.logo ? (
          <img src={business.logo400} alt="Logo" />
        ) : (
          <Upload {...uploadProps}>
            {loading ? (
              <>
                <FontAwesomeIcon icon="circle-notch" spin /> Uploading{' '}
                {progress}
              </>
            ) : (
              <Button>Upload</Button>
            )}
          </Upload>
        )}
      </div>
      <div>{business.logo && <Button onClick={deleteLogo}>Delete</Button>}</div>
    </Content>
  );
};
