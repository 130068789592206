import React from 'react';
import { useStoreState } from 'easy-peasy';
import ProfileMenu from 'components/ProfileMenu';
import { useToggle } from 'react-use';
import ModalNav from 'components/Mobile/ModalNav';
import Modal from 'components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/az';

export default () => {
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const user = useStoreState((state) => state.session.user);
  const [on, toggle] = useToggle();

  if (!isAuthed) return null;

  return (
    <>
      {user.avatar ? (
        <input
          type="image"
          src={user.avatarUrl}
          alt={user.name}
          onClick={toggle}
        />
      ) : (
        <Button shape="round" onClick={toggle}>
          <FontAwesomeIcon icon={['far', 'user']} />
        </Button>
      )}
      {on && (
        <Modal close={toggle}>
          <ModalNav onClick={toggle}>
            <ProfileMenu icons />
          </ModalNav>
        </Modal>
      )}
    </>
  );
};
