import React from 'react';
import styled, { css } from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { lllAndFromNow, nl2p } from 'utility';
import { get } from 'lodash';
import { Transition } from 'react-spring/renderprops';
import { animated } from 'react-spring';

const Content = styled.div`
  padding: 30px;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
`;

const Message = styled(animated.div)`
  border-radius: 20px;
  padding: 15px 20px;
  background: #fff;
  max-width: 80%;
  align-self: flex-start;
  font-size: 15px;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.green &&
    css`
      align-self: flex-end;
      background: ${props.theme.bgcolors.green};
    `}
`;

const Time = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondary};
  text-align: right;
`;

const ThreadBody = ({ thread }) => {
  const user = useStoreState((store) => store.session.user);
  const messages = get(thread, ['messages'], []);

  return (
    <Content>
      {messages.length === 0 && 'No messages...'}
      <Transition
        items={messages}
        keys={(item) => item.id}
        trail={100}
        from={{ opacity: 0, transform: 'translate3d(0,-20px,0)' }}
        enter={{ opacity: 1, transform: 'translate3d(0,0,0)' }}
        leave={{ opacity: 0, transform: 'translate3d(0,-20px,0)' }}
      >
        {(message) => (props) => (
          <Message style={props} green={user && user.id === message.user.id}>
            {nl2p(message.message)}
            <Time>{lllAndFromNow(message.created_at)}</Time>
          </Message>
        )}
      </Transition>
    </Content>
  );
};

export default ThreadBody;
