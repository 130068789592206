import React, { useState, useEffect } from 'react';
import { colors } from 'styles/config';
import { createGlobalStyle } from 'styled-components';
import { v4 as uuid } from 'uuid';

const ExtraStyles = createGlobalStyle`
    .landmark {
    width: 300px;
    padding: 7px 0 0 0;
    background: rgba(247, 247, 247, 0.9);
    border-radius: 5px;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
    font-family: Helvetica, Arial, sans-serif;
    -webkit-transform-origin: 0 10px;
    transform-origin: 0 10px;
    overflow-wrap: break-word;

    h1 {
      margin: 0;
      padding: 5px 15px;
      background: ${colors.red};
      color: rgba(255, 255, 255, 0.9);
      font-size: 16px;
      /* font-weight: 300; */
    }
    section {
      padding: 0 15px 5px;
      font-size: 14px;

      p {
        margin: 5px 0;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 7px;
      left: -16px;
      width: 0;
      height: 0;
      margin-bottom: -16px;
      border-right: 16px solid ${colors.red};
      border-top: 16px solid rgba(0, 0, 0, 0);
      border-bottom: 16px solid rgba(0, 0, 0, 0);
    }
  }
  
  /* @-webkit-keyframes scale-and-fadein {
    0% {
      -webkit-transform: scale(0.2);
      opacity: 0;
    }

    100% {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  } */
  
  @keyframes scale-and-fadein {
    0% {
      transform: scale(0.2);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const CALLOUT_OFFSET = new DOMPoint(-180, -104);

const calloutForLandmarkAnnotation = (annotation) => {
  const div = document.createElement('div');
  div.className = 'landmark';

  const title = div.appendChild(document.createElement('h1'));
  title.textContent = annotation.landmark.name;

  const section = div.appendChild(document.createElement('section'));

  const phone = section.appendChild(document.createElement('p'));
  phone.className = 'phone';
  phone.textContent = annotation.landmark.phone;

  const elink = section.appendChild(document.createElement('p'));
  const email = elink.appendChild(document.createElement('a'));
  email.href = `mailto:${annotation.landmark.email}`;
  email.textContent = annotation.landmark.email;

  const link = section.appendChild(document.createElement('p'));
  link.className = 'homepage';
  const website = link.appendChild(document.createElement('a'));
  website.href = annotation.landmark.website;
  website.textContent = annotation.landmark.website;
  website.target = '_blank';
  website.rel = 'noopener noreferrer';

  return div;
};

const landmarkAnnotationCallout = {
  calloutElementForAnnotation: (annotation) =>
    calloutForLandmarkAnnotation(annotation),

  calloutAnchorOffsetForAnnotation: (annotation, element) => CALLOUT_OFFSET,

  calloutAppearanceAnimationForAnnotation: (annotation) =>
    '.4s cubic-bezier(0.4, 0, 0, 1.5) 0s 1 normal scale-and-fadein',
};

const { mapkit } = window;

export default ({ businesses }) => {
  const [map, setMap] = useState(null);
  const [mapid] = useState(uuid());

  useEffect(() => {
    const center = new mapkit.Coordinate(-40.0906, 174.886);
    const newMap = new mapkit.Map(mapid, {
      center,
      colorScheme: mapkit.Map.ColorSchemes.Light,
      mapType: mapkit.Map.MapTypes.MutedStandart,
      // showsUserLocation: true,
      // showsUserLocationControl: true,
    });
    newMap.cameraDistance = 1000000;
    newMap.center = center;
    setMap(newMap);
    return () => {
      newMap.destroy();
    };
  }, [setMap, mapid]);

  useEffect(() => {
    const annotations = [];
    if (businesses) {
      businesses.forEach((item) => {
        if (item.location) {
          const coordinate = new mapkit.Coordinate(
            item.location.coordinates[0],
            item.location.coordinates[1]
          );
          const annotation = new mapkit.MarkerAnnotation(coordinate, {
            callout: landmarkAnnotationCallout,
            color: colors.red,
            // title: item.name,
          });
          annotation.landmark = item;
          annotations.push(annotation);
        }
      });
    }

    if (map) {
      map.annotations = [];
      if (annotations.length > 0) map.showItems(annotations);
    }
  }, [map, businesses]);

  return (
    <>
      <ExtraStyles />
      <div id={mapid} />
    </>
  );
};
