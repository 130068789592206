import React from 'react';
import styled from 'styled-components';
import UserModel from 'models/user';
import { useLocation, Link } from 'react-router-dom';
import WhiteContent from 'components/WhiteContent';

const Content = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'avatar name button';
`;

const AvatarDiv = styled.div`
  grid-area: avatar;
  img {
    display: block;
    height: 60px;
    width: 60px;
    border-radius: 60px;
  }
`;

const NameDiv = styled.div`
  grid-area: name;
  h3 {
    margin-bottom: 0;
  }
  a {
    color: ${(props) => props.theme.colors.text};
  }
`;

const ButtonDiv = styled.div`
  grid-area: button;
  align-self: center;
`;

export default ({ business }) => {
  const location = useLocation();
  const user = new UserModel(business.user);

  const to = {
    pathname: user.absoluteUrl,
    state: { background: location },
  };

  if (!business.user) return null;

  return (
    <WhiteContent>
      <Content>
        <AvatarDiv>
          <Link to={to}>
            <img src={user.avatarUrl} alt={user.name} />
          </Link>
        </AvatarDiv>
        <NameDiv>
          <h3>
            <Link to={to}>Backed by {user.name}</Link>
          </h3>
          <small className="text-secondary">
            AutoZealand member since {user.member_since}
          </small>
        </NameDiv>
        <ButtonDiv />
      </Content>
    </WhiteContent>
  );
};
