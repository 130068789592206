import React from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { api } from 'api';
import Switch from 'components/Switch';
import DraftAlert from 'components/Cards/Business/DraftAlert';
import PendingAlert from 'components/Cards/Business/PendingAlert';
import { toast } from 'react-toastify';

const Wrapper = styled.div``;
const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export default ({ business }) => {
  const [on, toggle] = useToggle(business.is_visible);

  const save = () => {
    toggle();
    on
      ? toast.warning(
          'Your business is no longer visible to the website visitors'
        )
      : toast.success('Now your business is available to the website visitors');
    api.patchData(business.myApiAbsoluteUrl, { is_visible: !on });
  };

  return (
    <Wrapper>
      <h3>Visibility</h3>
      {business.isDraft && <DraftAlert business={business} />}
      {business.isPending && <PendingAlert />}
      {business.isActive && (
        <Content>
          <Switch size="small" checked={on} onChange={save} />
          <div>Display my business on AutoZealand</div>
        </Content>
      )}
    </Wrapper>
  );
};
