import React from 'react';
import styled from 'styled-components';
import { Button, Textarea } from 'components/az';
import { useToggle } from 'react-use';
import { useStoreActions, useStoreState } from 'easy-peasy';
import EmailAuth from 'components/EmailAuth';
import { Field, Formik, Form } from 'formik';
import { ErrorMessage, FormItem } from 'components/form';
import * as Yup from 'yup';
import UserModel from 'models/user';
import { useLocation, Link } from 'react-router-dom';
import { get } from 'lodash';
import { api } from 'api';
import { defErrorToast } from 'utility';
import { toast } from 'react-toastify';
import Messages from './Messages';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.grey5};
  border-bottom: 1px solid ${(props) => props.theme.border.color};
`;

const Content = styled.div`
  padding: 20px;
  display: grid;
  align-items: center;
  gap: 15px;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'avatar name button';
  @media ${(props) => props.theme.devices.tablet} {
    padding: 20px 30px;
  }
`;

const BusinessAvatarDiv = styled.div`
  grid-area: avatar;
  img {
    height: 60px;
    width: 60px;
    border-radius: 10px;
    display: block;
  }
`;

const UserAvatarDiv = styled.div`
  grid-area: avatar;
  img {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    display: block;
  }
`;

const NameDiv = styled.div`
  grid-area: name;
`;

const ButtonDiv = styled.div`
  grid-area: button;
  align-self: center;
`;

const MessageDiv = styled.div`
  border-top: 1px solid ${(props) => props.theme.border.color};
  padding: 20px 30px;
  background: #fff;
`;

const NotAuthed = () => (
  <MessageDiv>
    <p>
      We respect your privacy and the privacy of our sellers and do not publish
      direct contact details. You might send a message to the seller through our
      message centre.
    </p>
    <p>
      To start, please confirm your email address (we will not share it with the
      seller - we will use it to send you the reply).
    </p>
    <EmailAuth currentEmail={null} type="auth" />
  </MessageDiv>
);

const MessageBlock = ({ listing }) => {
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const setListing = useStoreActions((actions) => actions.session.setListing);
  const [loading, setLoading] = useToggle();

  const sendMessage = async (values) => {
    try {
      setLoading(true);
      const response = await api.postData(
        `/api/listings/${listing.slug}/messages/`,
        values
      );
      toast.success(
        "We've sent your message to the seller and will notify you of his reply."
      );
      setListing(response);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  if (listing.thread) return null;
  if (!isAuthed) return <NotAuthed />;

  return (
    <MessageDiv>
      <Formik
        initialValues={{ message: '' }}
        validationSchema={Yup.object({
          message: Yup.string()
            .max(
              2000,
              'Your message is too long. Please use 2000 characters or less'
            )
            .required('You have not typed anything'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          sendMessage(values);
        }}
      >
        <Form>
          <FormItem>
            <Field
              name="message"
              as={Textarea}
              rows={4}
              placeholder="Write a message..."
            />
            <ErrorMessage name="message" />
          </FormItem>
          <div style={{ textAlign: 'right' }}>
            <Button
              viewtype="red"
              loading={loading}
              type="submit"
              icon={['far', 'paper-plane']}
            >
              Send
            </Button>
          </div>
        </Form>
      </Formik>
    </MessageDiv>
  );
};

export default ({ listing }) => {
  const location = useLocation();
  const [on, toggle] = useToggle();

  const rawUser = get(listing, ['user']);

  if (!rawUser) return null;

  const user = new UserModel(rawUser);

  const to = {
    pathname: user.absoluteUrl,
    state: { background: location },
  };

  return (
    <>
      <Wrapper>
        <Content>
          {listing.business ? (
            <BusinessAvatarDiv>
              {listing.businessLogo400 && (
                <Link to={`/b/${listing.business.slug}`}>
                  <img
                    src={listing.businessLogo400}
                    alt={listing.business.name}
                  />
                </Link>
              )}
            </BusinessAvatarDiv>
          ) : (
            <UserAvatarDiv>
              <Link to={to}>
                <img src={user.avatarUrl} alt={user.name} />
              </Link>
            </UserAvatarDiv>
          )}
          <NameDiv>
            {listing.business ? (
              <Link to={`/b/${listing.business.slug}`}>
                {listing.business.name}
              </Link>
            ) : (
              <>
                <Link to={to}>{user.name}</Link>
                <br />
                <small className="text-secondary">Private seller</small>
              </>
            )}
          </NameDiv>
          <ButtonDiv>
            {!listing.thread && <Button onClick={toggle}>Contact</Button>}
          </ButtonDiv>
        </Content>
        {on && <MessageBlock listing={listing} />}
        <Messages listing={listing} />
      </Wrapper>
    </>
  );
};
