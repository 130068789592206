import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/config';
import { get } from 'lodash';

const StyledButton = styled.button`
  font-size: ${(props) => props.fontSize};
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  outline: none;
  white-space: nowrap;
  background: transparent;
  color: ${(props) => props.color};

  &:hover {
    color: ${(props) => props.hoverColor};
  }

  &:disabled {
    color: #eee;
    cursor: not-allowed;
  }
`;

const sizes = {
  default: '16px',
  large: '20px',
};

export default (props) => {
  const {
    active,
    hoverType,
    disabled,
    size,
    onClick,
    children,
    className,
  } = props;
  const hoverColor = get(colors, [hoverType], colors.primary);
  const color = active ? colors.primary : colors.text;
  const fontSize = get(sizes, [size], sizes.default);

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      hoverColor={hoverColor}
      color={color}
      fontSize={fontSize}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};
