import React from 'react';
import styled from 'styled-components';

const SSkeletonPulse = styled.div`
  display: inline-block;
  width: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

const SSkeletonLine = styled(SSkeletonPulse)`
  /* width: 5.5em; */
  border-radius: 4px;
  min-height: ${props => (props.fullHeight ? '100%' : '20px')};

  &::before {
    content: ' ';
  }
`;

export default ({ fullHeight }) => <SSkeletonLine fullHeight={fullHeight} />;
