import React, { useEffect } from 'react';
import { get, filter } from 'lodash';
import Select from 'components/NativeSelect';

const getOptions = (data) =>
  data.map((r) => (
    <option key={r.id} value={get(r, ['slug'], r.id)}>
      {r.name_extra || r.name}
    </option>
  ));

export default ({ popular, value, items, onChange }) => {
  const ids = get(items, ['allIds'], []);
  const records = ids.map((id) => get(items, ['byId', id]));
  // const loading = get(items, ["loading"], false);

  useEffect(() => {
    if (ids.length === 1) onChange(ids[0]);
  }, [ids, onChange]);

  const params = {
    // placeholder,
    onChange: (e) => onChange(e.target.value),
    block: true,
    value: value || '',
    disabled: items === null,
    // loading,
    // showSearch: true,
    // allowClear: true,
    // filterOption: true,

    // dropdownMatchSelectWidth: false

    // optionFilterProp: 'children'
  };

  if (popular) {
    return (
      <Select {...params}>
        <option value="">Any</option>
        <optgroup key="popular" label="Popular">
          {getOptions(filter(records, ['is_popular', true]))}
        </optgroup>
        <optgroup key="other" label="Other">
          {getOptions(filter(records, ['is_popular', false]))}
        </optgroup>
      </Select>
    );
  }

  return (
    <Select {...params}>
      <option value="">Any</option>
      {getOptions(records)}
    </Select>
  );
};
