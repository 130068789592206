import React, { useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Helmet from 'components/Helmet';
import { api } from 'api';
import ThreadCard from 'components/Cards/Thread';

export default () => {
  const [loading, setLoading] = useState(true);
  const [threads, setThreads] = useState([]);
  const setThread = useStoreActions((action) => action.session.setThread);
  const threadById = useStoreState((state) => state.session.threadById);

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      const data = await api.getData('/api/threads/');
      if (!didCancel) {
        setThreads(data.results);
        data.results.forEach((t) => setThread(t));
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [setThread]);

  return (
    <>
      <Helmet canonical>
        <title>Messages</title>
      </Helmet>

      <h1 className="mb20">Messages</h1>

      {loading && <Loading />}
      {!loading && (
        <>
          {threads.length > 0 ? (
            threads.map((thread) => (
              <ThreadCard key={thread.id} thread={threadById(thread.id)} />
            ))
          ) : (
            <>You have no messages yet.</>
          )}
        </>
      )}
    </>
  );
};
