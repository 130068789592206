import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { adjustHue } from 'polished';

const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PulseDiv = styled.div`
  display: inline;
  animation: ${pulse} 0.5s infinite alternate;
`;

const shapes = {
  circle: '40px',
  round: '30px',
};

const StyledButton = styled.button`
  font-size: 16px;
  line-height: 1.42;
  padding: 10px 15px;
  margin: 0;
  border: 1px solid;
  box-sizing: border-box;
  text-align: center;
  border-color: ${(props) =>
    get(props.theme.colors, props.viewtype, props.theme.border.color)};
  background: ${(props) => get(props.theme.colors, props.viewtype, '#fff')};
  border-radius: ${(props) =>
    get(shapes, props.shape, props.theme.border.radius)};
  color: ${(props) => (props.viewtype ? '#fff' : props.theme.colors.text)};
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: box-shadow 0.2s ease-in-out;

  ${(props) =>
    props.viewtype &&
    css`
      background: linear-gradient(
        135deg,
        ${get(props.theme.colors, props.viewtype, '#fff')} 20%,
        ${adjustHue(-15, get(props.theme.colors, props.viewtype, '#fff'))} 100%
      );
      border: none;
    `};

  span {
    svg {
      margin-right: 0.5em;
      color: ${(props) => (props.viewtype ? '#fff' : props.theme.colors.red)};
    }
  }

  ${(props) =>
    props.active &&
    css`
      border-color: ${props.theme.colors.primary};
      color: ${props.theme.colors.primary};
    `};

  ${(props) =>
    props.size === 'small' &&
    css`
      padding: 6px 12px;
      font-size: 14px;
    `};

  ${(props) =>
    props.size === 'tiny' &&
    css`
      padding: 3px 6px;
      font-size: 12px;
      border-radius: 5px;
    `};

  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `};

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `};

  &:hover {
    text-decoration: none;
  }

  &:hover:not([disabled]) {
    box-shadow: ${(props) => props.theme.shadow.hover};
    /* transform: translateY(-1px); */
  }

  &:disabled {
    border: none;
    color: ${(props) => props.theme.colors.secondary};
    background: ${(props) => props.theme.colors.grey5};
    cursor: not-allowed;
    svg {
      color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

const Button = React.forwardRef(
  ({ loading, icon, children, ...props }, ref) => (
    <StyledButton ref={ref} {...props}>
      {loading && (
        <span>
          <FontAwesomeIcon icon="circle-notch" spin />
        </span>
      )}
      {icon && !loading && (
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
      )}
      {children}
    </StyledButton>
  )
);

export default Button;

export const ClearButton = (props) => (
  <Button icon="times-circle" {...props}>
    Clear
  </Button>
);

export const AppleButton = styled(Button)`
  color: white;
  background-color: black;
  border-color: black;
`;

export const InitUserButton = () => (
  <Button shape="round">
    <PulseDiv>
      <FontAwesomeIcon icon={['far', 'user']} />
    </PulseDiv>
  </Button>
);
