import React, { useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';

export default () => {
  const value = useStoreState((state) => state.listings.make);
  const items = useStoreState((state) => state.core.makes);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const onChange = useCallback(
    (make) => setData({ make, model: null, generation: null }),
    [setData]
  );

  return (
    <label>
      Make
      <DefSelect
        popular
        placeholder="Make"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
};
