import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import DotSeparator from 'components/DotSeparator';
import { get } from 'lodash';
import BusinessModel from 'models/business';
import { lllAndFromNow } from 'utility';
import ServiceTags from 'components/Service/Tags';

const Container = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 80px 1fr;
  grid-template-areas: 'logo details';

  a {
    position: relative;
  }
`;

const ServiceAndTime = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
`;

const LogoDiv = styled.div`
  grid-area: logo;

  img {
    display: block;
    width: 100%;
    border-radius: 10px;
  }

  div {
    height: 80px;
    border: 1px solid ${(props) => props.theme.border.color};
    border-radius: 10px;
    line-height: 80px;
    text-align: center;
    font-size: 40px;

    color: ${(props) => props.theme.border.color};
  }
`;

const DetailsDiv = styled.div`
  grid-area: details;
`;

const TagsDiv = styled.div`
  margin-top: 5px;
`;

export default ({ request }) => {
  const categories = useStoreState((state) => state.core.services);

  if (!request.business) return null;

  const business = new BusinessModel(request.business);

  return (
    <Container>
      <LogoDiv>
        {request.business.logo && (
          <Link to={`/b/${request.business.slug}`}>
            <img src={business.logo400} alt={business.name} />
          </Link>
        )}
      </LogoDiv>
      <DetailsDiv>
        <Link to={`/b/${request.business.slug}`}>{request.business.name}</Link>
        <ServiceAndTime>
          {get(categories, ['byId', request.service, 'name'], 'Other service')}
          <DotSeparator />
          {lllAndFromNow(request.created_at)}
        </ServiceAndTime>
        <TagsDiv>
          <ServiceTags request={request} />
        </TagsDiv>
      </DetailsDiv>
    </Container>
  );
};
