import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { useLocation, Link } from 'react-router-dom';
import { useUrl } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from 'components/az/Tag';

const Wrapper = styled.div``;

const CardWrapper = styled.div`
  position: relative;
  background: white;
  border-radius: 20px;
  padding: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 100px 1fr;
  grid-template-areas: 'logo body';
  box-shadow: ${(props) => props.theme.shadow.hover};
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${(props) => props.theme.shadow.hover2};
  }
`;

const LogoDiv = styled.div`
  grid-area: logo;

  img {
    display: block;
    width: 100%;
    border-radius: 10px;
  }

  div {
    height: 80px;
    border: 1px solid ${(props) => props.theme.border.color};
    border-radius: 10px;
    line-height: 80px;
    text-align: center;
    font-size: 40px;

    color: ${(props) => props.theme.border.color};
  }
`;

const BodyDiv = styled.div`
  grid-area: body;
`;

const MyBusinessCard = ({ business }) => {
  return (
    <CardWrapper>
      <Link to={`/my/business/${business.slug}`} className="cover" />
      <LogoDiv>
        {business.logo ? (
          <img src={business.logo400} alt={business.name} />
        ) : (
          <div>
            <FontAwesomeIcon icon="camera" />
          </div>
        )}
      </LogoDiv>
      <BodyDiv>
        <h3>{business.name}</h3>
        {business.isDealer && (
          <Tag>
            <FontAwesomeIcon icon="kiwi-bird" />
          </Tag>
        )}
        {business.isService && (
          <Tag>
            <FontAwesomeIcon icon="oil-can" />
          </Tag>
        )}
        {business.status === 'DRAFT' && <Tag color="yellow">Draft</Tag>}
        {business.status === 'PENDING' && (
          <Tag color="yellow">Pending verification</Tag>
        )}
        {business.status === 'ACTIVE' && <Tag color="green">Active</Tag>}
        {!business.is_visible && <Tag color="red">Invisible</Tag>}
      </BodyDiv>
    </CardWrapper>
  );
};

export default () => {
  useUrl('mybusiness');
  const records = useStoreState((state) => state.mybusiness.records);
  const updating = useStoreState((state) => state.mybusiness.updating);
  const location = useLocation();

  return (
    <Wrapper>
      {updating && !records && <Loading />}
      {records &&
        records.map((item) => <MyBusinessCard key={item.id} business={item} />)}
      {!updating && records !== null && records.length === 0 && (
        <p>
          If you are a dealer or provide auto service &mdash;{' '}
          <Link
            to={{
              pathname: `/my/business/add`,
              state: { background: location },
            }}
          >
            add your business
          </Link>
        </p>
      )}
    </Wrapper>
  );
};
