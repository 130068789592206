import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Select from 'components/NativeSelect';

export default () => {
  const value = useStoreState((state) => state.garage.personal);
  const setData = useStoreActions((actions) => actions.garage.setData);
  const onChange = (personal) => setData({ personal });

  return (
    <div>
      <label>Personal</label>
      <Select value={value} onChange={(e) => onChange(e.target.value)}>
        <option value="">Any</option>
        <option value="true">Personal</option>
        <option value="false">Business</option>
      </Select>
    </div>
  );
};
