import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export default React.forwardRef(({ children, ...props }, ref) => {
  return (
    <Content ref={ref} {...props}>
      {children}
    </Content>
  );
});
