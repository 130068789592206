import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import RegionDistrict from 'components/RegionDistrict';
import moment from 'moment';
import DotSeparator from 'components/DotSeparator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Content = styled.div`
  padding: 20px;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'stats'
    'info'
    'description';

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 40%;
    color: ${(props) => props.theme.colors.secondary};
    margin-bottom: 5px;
  }

  dd {
    width: 60%;
    margin-bottom: 5px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'stats stats'
      'info description';
  }
`;

const StatsDiv = styled.div`
  grid-area: stats;
  color: ${(props) => props.theme.colors.secondary};
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
`;

const InfoDiv = styled.div`
  grid-area: info;
`;

const DescDiv = styled.div`
  grid-area: description;
`;

export default ({ listing }) => {
  const car = get(listing, ['car']);

  return (
    <Content>
      <StatsDiv>
        <FontAwesomeIcon icon={['far', 'eye']} className="text-red mr05em" />
        {listing.cnt_views}
        <DotSeparator />
        {moment(listing.published_at).format('LL')} ({listing.publishedFromNow})
        <DotSeparator />
        <RegionDistrict obj={listing} />
      </StatsDiv>
      <InfoDiv>
        <dl>
          <dt>Price</dt>
          <dd>{listing.formattedPrice}</dd>

          <dt>Year</dt>
          <dd>{get(car, 'year')}</dd>

          <dt>Mileage</dt>
          <dd>{listing.formattedMileage}</dd>

          <dt>Origin</dt>
          <dd>{get(car, ['nznew']) ? 'NZ new' : 'Imported'}</dd>

          <dt>Make</dt>
          <dd>{get(car, ['make', 'name'])}</dd>

          <dt>Model</dt>
          <dd>{get(car, ['model', 'name'])}</dd>

          <dt>Generation</dt>
          <dd>{get(car, ['generation', 'name'])}</dd>

          <dt>Colour</dt>
          <dd>{get(car, ['colour', 'name'])}</dd>

          <dt>Body</dt>
          <dd>{get(car, ['body', 'name'])}</dd>

          <dt>Engine</dt>
          <dd>{get(car, ['trim', 'trim'])}</dd>

          <dt>Engine type</dt>
          <dd>{get(car, ['engine_type', 'name'])}</dd>

          <dt>Drive</dt>
          <dd>{get(car, ['drive', 'name'])}</dd>

          <dt>Transmission</dt>
          <dd>{get(car, ['gearbox', 'name'])}</dd>
        </dl>
      </InfoDiv>
      <DescDiv>
        {listing.content ? (
          listing.formattedContent
        ) : (
          <span className="text-secondary">
            The seller has not put any description yet.
          </span>
        )}
      </DescDiv>
    </Content>
  );
};
