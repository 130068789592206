import React, { useState } from 'react';
import { Button, Input, ButtonLink } from 'components/az';
import { useStoreActions } from 'easy-peasy';
import { validateEmail } from 'utility';
import { get } from 'lodash';
import styled from 'styled-components';
import { api } from 'api';

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'field' 'button';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'field button';
  }
`;
const FieldDiv = styled.div`
  grid-area: field;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ButtonDiv = styled.div`
  grid-area: button;
`;

export default ({ currentEmail, type, callback }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(currentEmail || '');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const setSessionToken = useStoreActions(
    (actions) => actions.session.setToken
  );
  const loadProfile = useStoreActions((actions) => actions.session.loadProfile);

  const stepBack = () => {
    setStep(1);
    setError(null);
  };

  async function sendEmail() {
    if (!validateEmail(email)) {
      setError('Please provide a valid email address');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      type === 'auth'
        ? await api.sendAuthEmail(email)
        : await api.sendVerifyEmail(email);

      setStep(2);
    } catch (e) {
      setError(
        get(
          e,
          ['response', 'data', 'non_field_errors'],
          ['An error occurred please try again']
        ).join(', ')
      );
    }
    setLoading(false);
  }

  async function checkToken() {
    setError(null);
    try {
      const response =
        type === 'auth'
          ? await api.checkAuthToken(token)
          : await api.checkVerifyToken(token);

      if (type === 'auth') {
        setSessionToken(response.token);
      }
      await loadProfile();
      callback();
    } catch (e) {
      setError(
        e.response
          ? e.response.data.token
          : 'An error occurred please refresh the page and try again'
      );
    }
    setLoading(false);
  }

  const Error = error && <small className="text-danger">{error}</small>;

  return (
    <>
      {step === 1 ? (
        <FormDiv>
          <FieldDiv>
            <Input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={sendEmail}
            />
            {Error}
          </FieldDiv>
          <ButtonDiv>
            <Button viewtype="primary" loading={loading} onClick={sendEmail}>
              Check
            </Button>
          </ButtonDiv>
        </FormDiv>
      ) : (
        <>
          <p>
            A verification token has been sent to <strong>{email}</strong>.
            <br />
            Please enter it below:
          </p>
          <FormDiv>
            <FieldDiv>
              <Input
                placeholder="Token"
                autoComplete="off"
                type="number"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                onPressEnter={checkToken}
              />
              {Error}
            </FieldDiv>
            <ButtonDiv>
              <Button viewtype="primary" loading={loading} onClick={checkToken}>
                Verify
              </Button>
            </ButtonDiv>
          </FormDiv>
          <ButtonLink active onClick={stepBack}>
            Noticed a typo in email?
          </ButtonLink>
        </>
      )}
    </>
  );
};
