import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import SearchInColumns from 'components/SearchInColumns';

export default () => {
  const items = useStoreState((state) => state.core.makes);
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const save = (make) => setData({ make, percent: 10 });

  return (
    <div>
      <h3>What make is your car?</h3>
      <SearchInColumns placeholder="Make" items={items} save={save} />
    </div>
  );
};
