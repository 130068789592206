import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import logo from 'assets/img/newlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ItemCount from 'components/ItemCount';
import { InitUserButton } from 'components/az/Button';
import UserMenu from './UserMenu';
import Location from './Location';
import { useInterval } from 'react-use';

const Container = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  user-select: none;
  box-shadow: ${(props) => props.theme.shadow.medium};
  /* box-shadow: ${(props) => props.theme.shadow.hover}; */
  line-height: 1;

  a {
    color: ${(props) => props.theme.colors.text};

    &.active {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 30px;
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-rows: ${(props) => props.theme.header.height}px;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'logo menu user';
`;

const LogoDiv = styled.div`
  grid-area: logo;
  justify-self: center;
  
  a {
    color: #000 !important;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
  }

  img {
    max-height: 40px;
  }
`;

const MenuDiv = styled.nav`
  grid-area: menu;
  justify-self: start;
  display: flex;
  align-items: center;
`;

const MenuItem = styled.div`
  padding-left: 30px;
  white-space: nowrap;
`;

const MenuItemIcon = styled(MenuItem)`
  svg {
    color: ${(props) => props.theme.colors.secondary};
  }
  .active {
    svg {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const UserDiv = styled.div`
  grid-area: user;
  justify-self: end;

  display: flex;
  align-items: center;
`;

export default () => {
  const location = useLocation();

  const initialised = useStoreState((state) => state.session.initialised);
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const user = useStoreState((state) => state.session.user);
  const regionUrlPrefix = useStoreState(
    (state) => state.session.regionUrlPrefix
  );

  const loadProfile = useStoreActions((actions) => actions.session.loadProfile);

  useInterval(() => {
    isAuthed && loadProfile();
  }, 60000);

  return (
    <Container>
      <Wrapper>
        <LogoDiv>
          <NavLink to="/">
            <img src={logo} alt="AutoZealand" /> <span>AutoZealand</span>
          </NavLink>
        </LogoDiv>

        <MenuDiv>
          <MenuItem>
            <Location />
          </MenuItem>
          <MenuItem>
            <NavLink to={`${regionUrlPrefix}/cars/used`}>Auto market</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={`${regionUrlPrefix}/dealers`}>Dealers</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={`${regionUrlPrefix}/services`}>Services</NavLink>
          </MenuItem>
        </MenuDiv>

        <UserDiv>
          {!isAuthed && (
            <>
              <MenuItem>
                <NavLink to="/garage">My garage</NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to="/sell">Sell a car</NavLink>
              </MenuItem>
            </>
          )}
          {isAuthed ? (
            <>
              <MenuItemIcon>
                <NavLink to="/my/garage">
                  <FontAwesomeIcon icon={['fas', 'warehouse']} />
                  {user && <ItemCount number={user.cnt.cars} />}
                </NavLink>
              </MenuItemIcon>
              <MenuItemIcon className="pl20">
                <NavLink to="/saved" title="Saved">
                  <FontAwesomeIcon icon={['fas', 'heart']} />
                  {user && <ItemCount number={user.cnt.saved} />}
                </NavLink>
              </MenuItemIcon>
              <MenuItemIcon className="pl20">
                <NavLink to="/messages" title="Messages">
                  <FontAwesomeIcon icon={['fas', 'comments']} />
                  {user && <ItemCount number={user.cnt.messages} />}
                </NavLink>
              </MenuItemIcon>
              <MenuItem>
                <UserMenu />
              </MenuItem>
            </>
          ) : (
            <MenuItem>
              {initialised ? (
                <NavLink
                  to={{
                    pathname: `/signin`,
                    state: { background: location },
                  }}
                >
                  Sign in
                </NavLink>
              ) : (
                <InitUserButton />
              )}
            </MenuItem>
          )}
        </UserDiv>
      </Wrapper>
    </Container>
  );
};
