import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Textarea } from 'components/az';
import { useToggle } from 'react-use';
import { api } from 'api';
import { useStoreActions } from 'easy-peasy';
import { defErrorToast } from 'utility';

const Wrapper = styled.div`
  padding: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  background: #fff;
`;

const ButtonDiv = styled.div`
  text-align: right;
`;

const ThreadFooter = ({ thread }) => {
  const setThread = useStoreActions((action) => action.session.setThread);

  const [message, setMessage] = useState('');
  const [sending, toggle] = useToggle();
  const send = async () => {
    toggle(true);
    try {
      const response = await api.postData(
        `/api/threads/${thread.id}/messages/`,
        { message }
      );
      setThread(response);
      setMessage('');
    } catch (error) {
      defErrorToast();
    }
    toggle(false);
  };

  const disabled = message.length === 0;

  return (
    <Wrapper>
      <div>
        <Textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={3}
          placeholder="Write a message..."
        />
      </div>
      <ButtonDiv>
        <Button
          viewtype={disabled ? undefined : 'primary'}
          disabled={disabled}
          loading={sending}
          onClick={send}
          icon={['far', 'paper-plane']}
        >
          Send
        </Button>
      </ButtonDiv>
    </Wrapper>
  );
};

export default ThreadFooter;
