import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDiv = styled.div`
  position: relative;
  line-height: 1;

  div {
    outline: none;
  }

  .navbtn {
    display: none;
  }
  &:hover {
    .navbtn {
      display: block;
    }
  }

  .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;

    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  /* Dots */
  .slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    bottom: 20px;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 2px;
    padding: 0;
    text-align: center;
    vertical-align: top;
  }
  .slick-dots li button {
    display: block;
    width: 10px;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    opacity: 0.75;
  }
  .slick-dots li.slick-active button {
    width: 15px;
    background: #fff;
    opacity: 1;
  }
  .slick-dots li.slick-active button:hover,
  .slick-dots li.slick-active button:focus {
    opacity: 1;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  font-size: ${(props) => (props.small ? '15px' : '20px')};
  color: #eee;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: ${(props) => (props.small ? '30px' : '40px')};
  height: ${(props) => (props.small ? '30px' : '40px')};
  cursor: pointer;
  outline: none;
  /* pointer-events: auto; */
  transform: translate(0, -50%);

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const NextButton = styled(StyledButton)`
  top: 50%;
  right: ${(props) => (props.small ? '10px' : '20px')};
`;
const PrevButton = styled(StyledButton)`
  top: 50%;
  left: ${(props) => (props.small ? '10px' : '20px')};
`;

export default React.forwardRef((props, ref) => {
  const { children, buttons, small, ...otherProps } = props;

  const settings = {
    arrows: false,
    dots: true,
    // infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...otherProps,
  };

  // const sliderRef = useRef();

  const next = () => {
    ref.current.slickNext();
  };
  const previous = () => {
    ref.current.slickPrev();
  };

  return (
    <StyledDiv>
      <Slider ref={ref} {...settings}>
        {children}
      </Slider>
      {buttons && (
        <>
          <PrevButton className="navbtn" onClick={previous} small={small}>
            <FontAwesomeIcon icon="chevron-left" />
          </PrevButton>
          <NextButton className="navbtn" onClick={next} small={small}>
            <FontAwesomeIcon icon="chevron-right" />
          </NextButton>
        </>
      )}
    </StyledDiv>
  );
});
