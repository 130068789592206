import React, { useState } from 'react';
import { Button } from 'components/az';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { toast } from 'react-toastify';
import Switch from 'components/Switch';
import { without, concat, get } from 'lodash';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  column-count: 1;

  @media ${(props) => props.theme.devices.tablet} {
    column-count: 2;
  }
`;

const Option = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-bottom: 20px;
`;

const Form = ({ business, toggle }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );
  const categories = useStoreState((state) => state.core.services);
  const [selected, setSelected] = useState(business.services);

  const save = async (id) => {
    const newSelected = selected.includes(id)
      ? without(selected, id)
      : concat(selected, id);
    setSelected(newSelected);
    try {
      const response = await api.patchData(business.myApiAbsoluteUrl, {
        services: newSelected,
      });
      setMyBusiness(response);
    } catch (e) {
      toast.error('Something went wrong while saving services');
    }
  };

  return (
    <FormDiv>
      {categories.list.map((item) => (
        <Option key={item.id}>
          <Switch
            size="small"
            checked={selected.includes(item.id)}
            onChange={() => save(item.id)}
          />
          <div>{item.name}</div>
        </Option>
      ))}
    </FormDiv>
  );
};

export default ({ business }) => {
  const [on, toggle] = useToggle();
  const categories = useStoreState((state) => state.core.services);

  const names = business.services.map((id) =>
    get(categories, ['byId', id, 'name'])
  );

  return (
    <Content>
      <div>
        <h3>Services</h3>
        {on ? <Form business={business} toggle={toggle} /> : names.join(' · ')}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Close' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
