import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonLink } from 'components/az';
import { useHistory } from 'react-router-dom';
import { useLockBodyScroll, useWider } from 'hooks';
import { useSpring, animated } from 'react-spring';

const CloseButton = styled(ButtonLink)`
  width: 60px;
  height: 60px;
  position: fixed;
  line-height: 60px;
  border-radius: 60px;
  top: 0;
  right: 0;
  font-size: 25px;
  color: ${(props) => props.theme.colors.secondary};
  pointer-events: auto;
  z-index: 2010;
  text-align: center;

  &:hover {
    color: white;
    background: rgba(0, 0, 0, 0.05);
  }

  @media ${(props) => props.theme.devices.tablet} {
    width: 80px;
    height: 80px;
    font-size: 30px;
    line-height: 80px;
    border-radius: 0;
  }
`;

const Overlay = styled(animated.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1990;
  position: fixed;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: saturate(200%) blur(5px);
  /* transition: all 0.5s ease-in; */
  animation: 0.2s ease-out 0s 1 normal fadein;
`;

const DivTC = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1995;
  position: fixed;
`;

const Content = styled(animated.div)`
  position: absolute;
  top: 60px;
  left: 50%;
  padding: 0;
  box-sizing: border-box;
  width: calc(100% - 20px);
  margin-left: calc((-100vw + 20px) / 2);
  border-radius: 20px;
  z-index: 2000;
  background: white;
  pointer-events: auto;

  ${(props) =>
    !!props.fullsize &&
    css`
      top: 0;
      left: 0;
      right: 0;
      width: auto;
      margin: 0;
      border-radius: 0;
    `};

  @media ${(props) => props.theme.devices.tablet} {
    top: 40px;
    margin-bottom: 40px;
    margin-left: -400px;
    width: 800px;

    ${(props) =>
      props.width &&
      css`
        width: ${props.width}px;
        margin-left: -${props.width / 2}px;
      `};

    ${(props) =>
      !!props.fullsize &&
      css`
        border-radius: 20px;
        left: 50%;
        right: auto;
      `};
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

const modalRoot = document.getElementById('modal-root');

export default ({ close, children, ...props }) => {
  useLockBodyScroll();
  const [el] = useState(document.createElement('div'));

  const history = useHistory();

  const back = (e) => {
    e && e.stopPropagation();
    history.goBack();
  };

  const goBack = close || back;

  const onEsc = (event) => {
    if (event.keyCode === 27) goBack();
  };

  useEffect(() => {
    document.addEventListener('keydown', onEsc, false);
    modalRoot.appendChild(el);

    return () => {
      document.removeEventListener('keydown', onEsc, false);
      modalRoot.removeChild(el);
    };
  });

  const isWider = useWider('tablet');

  const { opacity, transform } = useSpring({
    from: {
      opacity: 0,
      transform: props.fullsize
        ? 'translate3d(300px,0,0)'
        : 'translate3d(0,-50px,0)',
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0,0,0)',
    },
  });

  const style = isWider ? {} : { transform, opacity };

  const modal = (
    <Overlay>
      <DivTC onClick={goBack} />

      <CloseButton onClick={goBack}>
        <FontAwesomeIcon icon="times-circle" />
      </CloseButton>

      <Content style={style} {...props}>
        {children}
      </Content>
    </Overlay>
  );

  return ReactDOM.createPortal(modal, el);
};
