import React from 'react';
import FadeInDiv from 'components/anim/FadeInDiv';
import styled from 'styled-components';
import Make from './Make';
import Personal from './Personal';

const Content = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto 1fr;
  padding: 20px 0;
`;

export default () => {
  return (
    <FadeInDiv>
      <Content>
        <Make />
        <Personal />
      </Content>
    </FadeInDiv>
  );
};
