import React from 'react';
import styled from 'styled-components';
import { lllAndFromNow } from 'utility';
import { capitalize, get, times } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DotSeparator from 'components/DotSeparator';
import moment from 'moment';

const Content = styled.div`
  padding: 0 30px;
  h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const StatusHistoryItems = styled.div`
  display: grid;
  gap: 5px 20px;
  grid-template-columns: auto 1fr;
`;

const DateAndTime = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 10px;
`;

const ServiceStatusSections = ({ request }) => {
  if (!request || !request.caruser) return null;

  const statusHistory = get(request, ['status_history'], []);

  return (
    <>
      {statusHistory.length > 0 && (
        <Content>
          <h3>Status history</h3>
          <StatusHistoryItems>
            {statusHistory.map((item) => (
              <React.Fragment key={item.id}>
                <div>{capitalize(item.status)}</div>
                <div className="text-secondary">
                  {moment(item.created_at).format('LLLL')}
                </div>
              </React.Fragment>
            ))}
          </StatusHistoryItems>
        </Content>
      )}

      {request.status === 'ARCHIVED' && request.rating !== 0 && (
        <Content>
          <h3>Review</h3>
          <DateAndTime>
            <span className="text-red">
              {times(request.rating, () => (
                <FontAwesomeIcon icon={['fas', 'star']} />
              ))}
            </span>
            <DotSeparator />
            {lllAndFromNow(request.reviewed_at)}
          </DateAndTime>
          {request.review}
        </Content>
      )}
    </>
  );
};

export default ServiceStatusSections;
