import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  .tooltiptext {
    font-size: 14px;
    visibility: hidden;
    width: 100px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    text-align: center;
    padding: 10px 5px;
    border-radius: 5px;
    bottom: 120%;
    left: 50%;
    margin-left: -55px;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    z-index: 1;

    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
    }
  }

  &:hover .tooltiptext {
    opacity: 1;
    visibility: visible;
  }
`;

export default ({ title, children, ...props }) => {
  return (
    <Wrapper {...props}>
      {children}
      <span className="tooltiptext">{title}</span>
    </Wrapper>
  );
};
