import React from 'react';
import { Button } from 'components/az';
import { get } from 'lodash';
import styled from 'styled-components';
import { defErrorToast, priceFormatter } from 'utility';
import { api } from 'api';
import { useToggle } from 'react-use';

const Wrapper = styled.div`
  padding: 30px;
  background: ${(props) => props.theme.colors.grey6};
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
`;

const RecordRow = styled.div`
  display: grid;
  gap: 10px;
  grid-template-areas: 'desc qty price total';
  grid-template-columns: 3fr 1fr 1fr 1fr;
`;

const HeaderRecordRow = styled(RecordRow)`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 14px;
`;

const DescDiv = styled.div`
  grid-area: desc;
`;

const QtyDiv = styled.div`
  grid-area: qty;
  justify-self: end;
  input {
    text-align: right;
  }
`;

const PriceDiv = styled.div`
  grid-area: price;
  justify-self: end;
  input {
    text-align: right;
  }
`;

const TotalDiv = styled.div`
  grid-area: total;
  justify-self: end;
`;

const Record = ({ record, nodel }) => {
  const [deleted, setDeleted] = useToggle();
  const [loading, setLoading] = useToggle();
  const del = async () => {
    try {
      setLoading(true);
      await api.deleteData(`/api/service-request-details/${record.id}`);
      setDeleted(true);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  if (deleted) return null;

  return (
    <RecordRow>
      <DescDiv>
        {!nodel && (
          <Button
            size="tiny"
            loading={loading}
            viewtype="red"
            className="mr10"
            onClick={del}
          >
            Delete
          </Button>
        )}
        {record.description}
      </DescDiv>
      <QtyDiv>{record.quantity}</QtyDiv>
      <PriceDiv>{priceFormatter.format(record.unit_price)}</PriceDiv>
      <TotalDiv>
        {priceFormatter.format(1 * record.quantity * record.unit_price)}
      </TotalDiv>
    </RecordRow>
  );
};

const ServiceDetails = ({ request, nodel }) => {
  const details = get(request, ['details'], []);
  return (
    <Wrapper>
      <HeaderRecordRow>
        <DescDiv>Description of goods / services</DescDiv>
        <QtyDiv>Quantity</QtyDiv>
        <PriceDiv>Unit price</PriceDiv>
        <TotalDiv>Total</TotalDiv>
      </HeaderRecordRow>
      {details.length === 0 && <span>No records...</span>}
      {request.details.map((record) => (
        <Record key={record.id} record={record} nodel={nodel} />
      ))}
    </Wrapper>
  );
};

export default ServiceDetails;
