import React from 'react';
import { useStoreState } from 'easy-peasy';
import { SkeletonLine } from 'components/az';
import Make from './Make';
import Model from './Model';
import Year from './Year';
import Body from './Body';
import Generation from './Generation';
import EngineType from './EngineType';
import Drive from './Drive';
import Gearbox from './Gearbox';
import Trim from './Trim';
import Nznew from './Nznew';
import Colour from './Colour';
import Business from './Business';

const NznewBlock = () => {
  const nznew = useStoreState((state) => state.newcar.nznew);
  return (
    <>
      <Nznew />
      {nznew !== null && <Colour />}
    </>
  );
};

const TrimBlock = () => {
  const trim = useStoreState((state) => state.newcar.trim);
  return (
    <>
      <Trim />
      {trim && <NznewBlock />}
    </>
  );
};

const GearboxBlock = () => {
  const gearbox = useStoreState((state) => state.newcar.gearbox);
  return (
    <>
      <Gearbox />
      {gearbox && <TrimBlock />}
    </>
  );
};

const DriveBlock = () => {
  const drive = useStoreState((state) => state.newcar.drive);
  return (
    <>
      <Drive />
      {drive && <GearboxBlock />}
    </>
  );
};

const EngineTypeBlock = () => {
  const et = useStoreState((state) => state.newcar.engine_type);
  return (
    <>
      <EngineType />
      {et && <DriveBlock />}
    </>
  );
};

const GenerationBlock = () => {
  const generation = useStoreState((state) => state.newcar.generation);
  return (
    <>
      <Generation />
      {generation && <EngineTypeBlock />}
    </>
  );
};

const BodyBlock = () => {
  const body = useStoreState((state) => state.newcar.body);
  return (
    <>
      <Body />
      {body && <GenerationBlock />}
    </>
  );
};

const YearBlock = () => {
  const year = useStoreState((state) => state.newcar.year);
  return (
    <>
      <Year />
      {year && <BodyBlock />}
    </>
  );
};

export default () => {
  const initialised = useStoreState((state) => state.session.initialised);
  const business = useStoreState((state) => state.newcar.business);
  const make = useStoreState((state) => state.newcar.make);
  const model = useStoreState((state) => state.newcar.model);

  if (!initialised) return <SkeletonLine />;
  if (business === null) return <Business />;
  if (make === null) return <Make />;
  if (model === null) return <Model />;

  return <YearBlock />;
};
