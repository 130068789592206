import React from 'react';
import styled from 'styled-components';
import Type from './Type';
import Reset from './Reset';

const Content = styled.div`
  padding: 0 20px;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 40px;
  }

  display: grid;
  gap: 10px 15px;
  align-items: center;
  grid-template-columns: auto auto 1fr;
`;

export default () => {
  return (
    <Content>
      <Type />
      <Reset />
      <div />
    </Content>
  );
};
