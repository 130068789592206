import React from 'react';
import styled from 'styled-components';
import WhiteContent from 'components/WhiteContent';
import DateBlock from './DateBlock';
import Info from './Info';
import Listing from './Listing';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'since'
    'info'
    'buttons'
    'listing';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 2fr 3fr;
    grid-template-areas:
      'since info'
      'buttons info'
      'buttons listing';
  }
`;

const SmallWhiteContent = styled(WhiteContent)`
  /* padding: 20px;
  border-radius: 10px; */
`;

const SinceDiv = styled(SmallWhiteContent)`
  grid-area: since;
  span {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 14px;
  }
`;

const Rego = styled.div`
  margin-top: 20px;
  width: 140px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.1em;
  font-size: 20px;
  border: 3px solid #333;
  border-radius: 5px;
  font-family: sans-serif;
`;

const InfoDiv = styled(SmallWhiteContent)`
  grid-area: info;
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;
`;

const ListingDiv = styled.div`
  grid-area: listing;
`;

export default ({ caruser }) => {
  return (
    <Container>
      <SinceDiv>
        <span>In your garage since</span>
        <br />
        {caruser.inGarageSince} ({caruser.inGarageFromNow})
        {caruser.plate && <Rego>{caruser.plate}</Rego>}
      </SinceDiv>

      <InfoDiv>
        <Info caruser={caruser} />
      </InfoDiv>

      <ButtonsDiv>
        <WhiteContent>
          <div className="mb20">
            <DateBlock
              slug={caruser.slug}
              initDate={caruser.wofMoment}
              field="wof"
            />
          </div>
          <div className="mb20">
            <DateBlock
              slug={caruser.slug}
              initDate={caruser.regoMoment}
              field="rego"
            />
          </div>
          <DateBlock
            slug={caruser.slug}
            initDate={caruser.servicedueMoment}
            field="servicedue"
          />
        </WhiteContent>
      </ButtonsDiv>

      <ListingDiv>
        <Listing caruser={caruser} />
      </ListingDiv>
    </Container>
  );
};
