/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

const Wrapper = styled.div`
  display: inline-block;

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;

    &:checked + label span {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 30px;
    border-radius: 30px;
    position: relative;
    transition: background-color 0.2s;
    background: grey;

    ${(props) =>
      props.checked &&
      css`
        background: ${props.theme.colors.green};
      `}

    ${(props) =>
      props.size === 'small' &&
      css`
        height: 24px;
        border-radius: 24px;
        width: 40px;
      `}

    span {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      width: 26px;
      height: 26px;
      border-radius: 26px;
      transition: 0.2s;
      background: #fff;
      box-shadow: ${(props) => props.theme.shadow.light};

      ${(props) =>
        props.size === 'small' &&
        css`
          width: 20px;
          height: 20px;
          border-radius: 20px;
        `}
    }

    &:active span {
      width: 30px;

      ${(props) =>
        props.size === 'small' &&
        css`
          width: 24px;
        `}
    }
  }
`;

export default ({ id, checked, onChange, ...props }) => {
  const [_id] = useState(id || uuid());

  return (
    <Wrapper {...props} checked={checked}>
      <input id={_id} checked={checked} onChange={onChange} type="checkbox" />
      <label htmlFor={_id}>
        <span />
      </label>
    </Wrapper>
  );
};
