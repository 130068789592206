/* eslint-disable no-param-reassign */
import { action, computed, thunk } from 'easy-peasy';
import { get, keys, pick, forEach, isUndefined } from 'lodash';
import qs from 'qs';
import CarUserModel from 'models/caruser';
import { api } from '../api';

const matrix = {
  make: 'make_slug',
  personal: 'personal',
  page: 'page',
  page_size: 'page_size',
};

const defSearchParams = {
  make: null,
  personal: null,
  page: 1,
};

const defParams = {
  page_size: 10,

  ...defSearchParams,
};

export default {
  ...defParams,
  records: [],
  updating: false,
  location: null,
  count: 0,

  makeObj: computed(
    [(state, storeState) => storeState.core.makeBySlug(state.make)],
    (x) => x
  ),

  apiParamsObj: computed(
    [(state) => state, (_, storeState) => storeState.session.region],
    (state, region) => {
      const toReturn = {};
      forEach(matrix, (value, key) => {
        toReturn[value] = key === 'region' ? region : state[key];
      });
      return toReturn;
    }
  ),
  searchParamsObj: computed((state) => pick(state, keys(defSearchParams))),

  // use it when you need to set anything
  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = isUndefined(value) ? null : value;
    });
  }),

  // use it when other search (!) parameters should be reset
  setParamsData: action((state, payload) => {
    forEach(defParams, (value, key) => {
      state[key] = get(payload, [key], value);
    });
  }),

  buildLocation: action((state, _) => {
    // const { make } = state;
    const pathname = '/my/garage';
    const searchParamsObj = pick(state, keys(defSearchParams));
    const search = qs.stringify(searchParamsObj, {
      skipNulls: true,
      addQueryPrefix: true,
    });
    state.location = { pathname, search, state: { noParse: true } };
  }),

  fetch: thunk(async (actions, _, { getState }) => {
    actions.setData({ updating: true });
    actions.buildLocation();
    const state = getState();
    const data = await api.getData('/api/my/cars/', state.apiParamsObj);

    actions.setData({
      records: data.results ? data.results.map((c) => new CarUserModel(c)) : [],
      count: data.count,
      updating: false,
    });
  }),
};
