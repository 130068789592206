/* eslint-disable no-param-reassign */
import { action, thunk, computed } from 'easy-peasy';
import { forEach, pick, keys, get } from 'lodash';
import { api } from '../api';

const defCarParams = {
  make: null,
  model: null,
  generation: null,
  year: null,
  body: null,
  engine_type: null,
  drive: null,
  gearbox: null,
  trim: null,
  nznew: null,
  colour: null,
};

const defParams = {
  step: 1,
  percent: 5,
  business: null,
  slug: null,
  ...defCarParams,
};

export default {
  ...defParams,

  models: computed(
    [(state) => state.make, (_, storeState) => storeState.core.makeModels],
    (id, items) => get(items, [id], null)
  ),
  generations: computed(
    [
      (state) => state.model,
      (_, storeState) => storeState.core.modelGenerations,
    ],
    (id, items) => get(items, [id], null)
  ),
  makeObj: computed(
    [(state) => state.make, (_, storeState) => storeState.core.makes],
    (id, items) => get(items, ['byId', id], null)
  ),
  modelObj: computed(
    [(state) => state.model, (state) => state.models],
    (id, items) => get(items, ['byId', id], null)
  ),
  generationObj: computed(
    [(state) => state.generation, (state) => state.generations],
    (id, items) => get(items, ['byId', id], null)
  ),

  title: computed((state) => (deftitle) => {
    if (!state.makeObj) return deftitle;

    let title = state.makeObj.name;

    if (state.modelObj) {
      title = `${title} ${state.modelObj.name}`;

      if (state.generationObj) {
        if (state.generationObj.name !== state.modelObj.name) {
          title = `${title} ${state.generationObj.name}`;
        }
      }

      if (state.year) title = `${title}, ${state.year}`;
    }
    return title;
  }),

  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = value;
    });
  }),

  reset: action((state, _) => {
    forEach(defParams, (value, key) => {
      state[key] = value;
    });
  }),

  createCar: thunk(async (actions, payload, { getState, getStoreActions }) => {
    const state = getState();
    let data = pick(state, keys(defCarParams));
    if (state.business && state.business !== 'personal') {
      data = { ...data, business: state.business };
    }
    if (payload) {
      data = { ...data, ...payload };
    }
    const caruser = await api.postData('/api/my/cars/new/', data);
    getStoreActions().session.setCarUser(caruser);
    actions.setData({ slug: caruser.slug });
  }),
};
