import React from 'react';
import { NavLink } from 'react-router-dom';
import HorizontalMenu, { MenuItem } from 'components/HorizontalMenu';

export default ({ business }) => {
  return (
    <HorizontalMenu>
      <MenuItem>
        <NavLink exact to={business.myAbsoluteUrl}>
          Settings
        </NavLink>
      </MenuItem>
      {business.isService && (
        <MenuItem>
          <NavLink to={`${business.myAbsoluteUrl}/requests`}>Requests</NavLink>
        </MenuItem>
      )}
      <MenuItem>
        <NavLink to={`${business.myAbsoluteUrl}/photos`}>Photos</NavLink>
      </MenuItem>
    </HorizontalMenu>
  );
};
