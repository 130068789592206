import React from 'react';
import { NavLink } from 'react-router-dom';
import HorizontalMenu, { MenuItem } from 'components/HorizontalMenu';
import { useStoreState } from 'easy-peasy';
import ItemCount from 'components/ItemCount';

export default () => {
  const user = useStoreState((state) => state.session.user);

  if (!user) return null;

  return (
    <HorizontalMenu>
      <MenuItem>
        <NavLink exact to="/saved/listings">
          Listings
          <ItemCount number={user.cnt.saved} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink exact to="/saved/dealers">
          Dealers
          <ItemCount number={user.cnt.saved_dealers} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink exact to="/saved/services">
          Services
          <ItemCount number={user.cnt.saved_services} />
        </NavLink>
      </MenuItem>
    </HorizontalMenu>
  );
};
