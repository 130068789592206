import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Carousel } from 'components/az';

const PhotosDiv = styled.div`
  grid-area: photos;
  border-bottom: 1px solid ${(props) => props.theme.border.color};

  img {
    max-width: 100%;
  }

  .navdiv {
    padding: 10px 0 10px 10px;
    border-radius: 4px;
    img {
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .slick-current {
    .navdiv {
      img {
        box-shadow: 0 0 0 2px ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export default ({ listing }) => {
  const mainRef = useRef();
  const menuRef = useRef();
  const { shortTitle, images } = listing;
  if (!images.length) return null;

  useEffect(() => {
    if (mainRef) {
      mainRef.current.innerSlider.list.setAttribute('tabindex', 0);
      mainRef.current.innerSlider.list.focus();
    }
  }, []);

  return (
    <PhotosDiv>
      <Carousel
        ref={mainRef}
        buttons={images.length > 1}
        easing="easeInOutCubic"
        beforeChange={(oldIndex, newIndex) => {
          menuRef && menuRef.current.slickGoTo(newIndex);
        }}
      >
        {images.map((item) => (
          <div key={item.id}>
            <figure>
              <img src={item.photo1600} alt={shortTitle} />
            </figure>
          </div>
        ))}
      </Carousel>
      <Carousel
        ref={menuRef}
        dots={false}
        infinite={false}
        easing="easeInOutCubic"
        focusOnSelect
        centerMode
        swipeToSlide
        variableWidth
        slidesToShow={1}
        beforeChange={(oldIndex, newIndex) => {
          mainRef && mainRef.current.slickGoTo(newIndex);
        }}
      >
        {images.map((item) => (
          <div key={item.id} style={{ width: 100 }}>
            <div className="navdiv">
              <figure>
                <img src={item.photo400} alt={shortTitle} />
              </figure>
            </div>
          </div>
        ))}
      </Carousel>
    </PhotosDiv>
  );
};
