import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const Content = styled(animated.div)`
  border-radius: 20px;
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 -20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
    margin: 0;
  }
`;

export default React.forwardRef(({ children, ...props }, ref) => {
  const style = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });
  return (
    <Content style={style} ref={ref} {...props}>
      {children}
    </Content>
  );
});
