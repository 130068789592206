import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/config';

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const Content = styled.sup`
  height: 20px;
  min-width: 20px;
  font-size: 14px;
  color: #fff;
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: 0;
  background: ${colors.error};
  transform-origin: 100% 0%;
  transform: translate(50%, -50%);
  line-height: 20px;
  text-align: center;
  box-shadow: 0 0 0 1px #fff;
`;

export default ({ count, children }) => {
  return (
    <Wrapper>
      {children}
      {count > 0 && <Content>{count}</Content>}
    </Wrapper>
  );
};
