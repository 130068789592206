import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ul {
    list-style-type: none;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 5px 5px;
      a {
        display: block;
        padding: 5px 10px;
        border-radius: 10px;
        color: ${(props) => props.theme.colors.text};

        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.grey5};
          color: ${(props) => props.theme.colors.red};
          text-decoration: none;
        }
      }
    }
  }
`;

export const MenuItem = ({ children }) => <li>{children}</li>;

export default ({ children }) => (
  <Container>
    <ul>{children}</ul>
  </Container>
);
