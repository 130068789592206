import React from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { api } from 'api';
import Switch from 'components/Switch';
import { toast } from 'react-toastify';
import { useLocation, Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';

const Wrapper = styled.div``;
const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export default ({ caruser }) => {
  const [on, toggle] = useToggle(caruser.is_public);

  const user = useStoreState((state) => state.session.user);
  const location = useLocation();

  const save = () => {
    toggle();
    on
      ? toast.warning('The car is no longer visible to the website visitors')
      : toast.success('Now the car is available on your profile page');
    api.patchData(caruser.myApiAbsoluteUrl, { is_public: !on });
  };

  return (
    <Wrapper>
      <h3>Visibility</h3>

      <Content>
        <Switch size="small" checked={on} onChange={save} />
        <div>
          Show the car on{' '}
          <Link
            to={{
              pathname: user.absoluteUrl,
              state: { background: location },
            }}
          >
            my profile page
          </Link>
        </div>
      </Content>
    </Wrapper>
  );
};
