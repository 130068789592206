import React from 'react';
import { useScrollToTopOnMount } from 'hooks';
import Footer from 'components/Footer';
import Helmet from 'components/Helmet';
import Slogan from './Slogan';

export default () => {
  useScrollToTopOnMount();

  return (
    <>
      <Helmet canonical>
        <meta
          name="description"
          content="The time for a better auto market has come. Transparency is the power!"
        />
      </Helmet>
      <Slogan />
      <Footer />
    </>
  );
};
