import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { get, truncate } from 'lodash';
import CarUserModel from 'models/caruser';
import { lllAndFromNow } from 'utility';
import DotSeparator from 'components/DotSeparator';

const Wrapper = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  background: #fff;
  display: grid;
  gap: 20px 15px;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'avatar content' 'message message';
  margin-bottom: 20px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.hover};
  }

  a {
    position: relative;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const AvatarDiv = styled.div`
  grid-area: avatar;

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: block;
  }
`;

const TotalAndTime = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
`;

const ContentDiv = styled.div`
  grid-area: content;
`;

const MessageDiv = styled.div`
  grid-area: message;
  font-size: 15px;
`;

const Unseen = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: ${(props) => props.theme.colors.blue};
`;

const ThreadCard = ({ thread }) => {
  const user = useStoreState((state) => state.session.user);
  const location = useLocation();

  const lastUserId = get(thread, ['message', 'user', 'id']);
  const truncatedMessage = truncate(get(thread, ['message', 'message'], ''), {
    length: 100,
  });

  const listing = new CarUserModel(thread.caruser);
  const opponent = thread.getOpponent(user);
  const myParticipant = thread.getParticipant(user);

  if (!opponent || !myParticipant) return null;

  const state = { background: location };

  const opponentTo = {
    pathname: opponent.absoluteUrl,
    state,
  };
  const to = {
    pathname: `/messages/${thread.id}`,
    state,
  };

  const unseen =
    !myParticipant.last_seen_message ||
    get(myParticipant, ['last_seen_message', 'id']) !==
      get(thread, ['message', 'id']);

  return (
    <Wrapper>
      {unseen && <Unseen />}
      <Link to={to} className="cover" />
      <AvatarDiv>
        <Link to={opponentTo}>
          <img src={opponent.avatarUrl} alt={opponent.name} />
        </Link>
      </AvatarDiv>
      <ContentDiv>
        <Link to={opponentTo}>{opponent.name}</Link>
        {thread.caruser && (
          <>
            <span className="text-secondary"> about </span>
            <Link
              to={{
                pathname: listing.absoluteUrl,
                state,
              }}
            >
              {listing.fullTitle}
            </Link>
          </>
        )}
        <TotalAndTime>
          <span>
            {thread.total_messages} message{thread.total_messages > 1 && 's'}
          </span>
          <DotSeparator />
          <time>{lllAndFromNow(thread.updated_at)}</time>
        </TotalAndTime>
      </ContentDiv>
      <MessageDiv>
        <span className="text-secondary">
          {user.id === lastUserId && 'You: '}
        </span>
        {truncatedMessage}
      </MessageDiv>
    </Wrapper>
  );
};

export default ThreadCard;
