import axios from 'axios';
import { get } from 'lodash';

export const baseURL = process.env.REACT_APP_API_BASE_URL;

// const API = axios.create({
//   baseURL: baseURL,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

// const token = localStorage.getItem('token');
// if (token !== null) {
//   API.defaults.headers.common['Authorization'] = `Token ${token}`;
// }

// export default API;

const URLS = {
  base: process.env.REACT_APP_API_BASE_URL,
};

export class API {
  token = null;

  constructor(urls = URLS) {
    this.urls = urls;
    this.axios = axios.create({
      baseURL: urls.base,
    });
    this.axios.defaults.headers['Content-Type'] = 'application/json';
    this.axios.interceptors.request.use(
      (req) => {
        if (this.token) {
          req.headers.Authorization = `Token ${this.token}`;
        }
        return req;
      },
      (e) => Promise.reject(e)
    );
  }

  getData = async (url, params) => {
    const resp = await this.axios.request({
      url,
      params,
    });
    return resp.data;
  };

  postData = async (url, data) => {
    const resp = await this.axios.request({
      url,
      data,
      method: 'post',
    });
    return resp.data;
  };

  deleteData = async (url) => {
    const resp = await this.axios.request({
      url,
      method: 'delete',
    });
    return resp.data;
  };

  patchData = (url, data) => {
    return this.axios
      .request({
        url,
        data,
        method: 'patch',
      })
      .then((resp) => resp.data);
  };

  updateToken = (token) => {
    this.token = token;
  };

  sendAuthEmail = (email) => {
    return this.axios
      .request({
        url: '/api/auth/email/',
        method: 'post',
        data: { email },
      })
      .then((resp) => resp.data);
  };

  sendVerifyEmail = (email) => {
    return this.axios
      .request({
        url: '/api/verify/email/',
        method: 'post',
        data: { email },
      })
      .then((resp) => resp.data);
  };

  checkAuthToken = (token) => {
    return this.axios
      .request({
        url: '/api/auth/callback/',
        method: 'post',
        data: { token },
      })
      .then((resp) => resp.data);
  };

  checkVerifyToken = (token) => {
    return this.axios
      .request({
        url: '/api/verify/callback/',
        method: 'post',
        data: { token },
      })
      .then((resp) => resp.data);
  };

  checkAppleAuth = (data) => {
    return this.axios
      .request({
        url: '/api/apple/auth/callback/',
        method: 'post',
        data: {
          ...data.authorization,
          first_name: get(data, ['user', 'name', 'firstName']),
          last_name: get(data, ['user', 'name', 'lastName']),
          email: get(data, ['user', 'email']),
        },
      })
      .then((resp) => resp.data);
  };

  getCarModelData = ({ model, dataType, params }) => {
    return this.axios
      .request({
        url: `/api/vehicles/models/${model}/${dataType}/`,
        params,
      })
      .then((resp) => resp.data);
  };
}

export const api = new API();
