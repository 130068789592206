import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { useLocation, Link } from 'react-router-dom';
import { useUrl } from 'hooks';
import FadeInDiv from 'components/anim/FadeInDiv';
import CarUserCard from 'components/Cards/CarUser';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export default () => {
  useUrl('garage');
  const records = useStoreState((state) => state.garage.records);
  const updating = useStoreState((state) => state.garage.updating);
  const location = useLocation();

  return (
    <Wrapper>
      {updating && !records && <Loading />}

      {records &&
        records.map((item) => (
          <FadeInDiv key={item.id}>
            <CarUserCard caruser={item} />
          </FadeInDiv>
        ))}

      {!updating && records !== null && records.length === 0 && (
        <p>
          Your garage is empty.{' '}
          <Link
            to={{
              pathname: `/my/garage/add`,
              state: { background: location },
            }}
          >
            Add a car
          </Link>{' '}
          now
        </p>
      )}
    </Wrapper>
  );
};
