import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import ListingCard from 'components/Cards/Listing';
import { useWider } from 'hooks';

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.grey4};
  padding: 50px 30px 30px;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const ListingPreview = () => {
  const { slug } = useParams();
  const listing = useStoreState((state) => state.session.carUserBySlug(slug));
  const isWider = useWider('tablet');

  if (!listing || !listing.isListing || !isWider) return null;

  return (
    <Wrapper>
      <Container>
        <ListingCard pure listing={listing} className="mb0" />
      </Container>
    </Wrapper>
  );
};

export default ListingPreview;
