import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ThreadBody from 'pages/Messages/Thread/Body';
import Loading from 'components/Loading';
import ThreadFooter from 'pages/Messages/Thread/Footer';

const Wrapper = styled.div``;

export default ({ listing }) => {
  const thread = useStoreState((state) =>
    state.session.threadById(listing.thread)
  );
  const loadThread = useStoreActions((action) => action.session.loadThread);

  useEffect(() => {
    listing.thread && loadThread(listing.thread);
  }, [listing.thread, loadThread]);

  if (!listing.thread) return null;
  if (!thread) return <Loading padding={30} />;

  return (
    <Wrapper>
      <ThreadBody thread={thread} />
      <ThreadFooter thread={thread} />
    </Wrapper>
  );
};
