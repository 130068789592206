import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { colors } from 'styles/config';
import { carListingsUrl } from 'utility';
import ItemCount from 'components/ItemCount';

const Content = styled.div`
  padding: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0;
  }

  ul {
    padding: 0;
    column-count: 3;
    column-gap: 20px;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0 0 10px 0;

      a {
        color: ${colors.text};
        text-decoration: none;

        &:hover {
          color: ${colors.primary};
        }
      }
    }
  }
`;

export default () => {
  const regions = useStoreState((state) => state.core.regions);
  const storedLocation = useStoreState((state) => state.listings.location);
  const regionStats = useStoreState((state) => state.listings.regionStats);
  const { make, model } = useStoreState((state) => state.listings);

  const search = get(storedLocation, ['search']);

  return (
    <Content>
      <ul>
        {regions.allIds.map((id) => (
          <li key={id}>
            <Link
              to={{
                pathname: carListingsUrl(regions.byId[id].slug, make, model),
                search,
              }}
            >
              {regions.byId[id].name}{' '}
              {regions.byId[id].slug in regionStats && (
                <ItemCount number={regionStats[regions.byId[id].slug]} />
              )}
            </Link>
          </li>
        ))}
      </ul>
    </Content>
  );
};
