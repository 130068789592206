import React, { useCallback } from 'react';
import { useStoreActions } from 'easy-peasy';
import PhotosForm from 'components/PhotosForm';

export default ({ listing }) => {
  const setListing = useStoreActions((actions) => actions.session.setListing);

  const callback = useCallback((photos) => {
    setListing({ ...listing, photos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PhotosForm
      id={listing.id}
      field={listing.field}
      url={listing.photosApiAbsoluteUrl}
      photos={listing.photos}
      callback={callback}
    />
  );
};
