import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((store) => store.newcar.engine_type);
  const save = (id) => setData({ engine_type: id, drive: null, percent: 35 });

  return (
    <CarModelData
      title="Engine type"
      dataType="engine_types"
      value={value}
      paramKeys={['year', 'body', 'generation']}
      save={save}
    />
  );
};
