import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { mileageFormatter } from 'utility';
import Mileage from './Fields/Mileage';
import FilterButton from './FilterButton';

export default () => {
  const mileageFrom = useStoreState((state) => state.listings.mileageFrom);
  const mileageTo = useStoreState((state) => state.listings.mileageTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const content = (
    <ul>
      <li>
        <Mileage />
      </li>
    </ul>
  );

  let name = 'Mileage';
  if (mileageFrom || mileageTo) {
    if (!mileageFrom) {
      name = `Up to ${mileageFormatter.format(mileageTo)} km`;
    } else if (!mileageTo) {
      name = `From ${mileageFormatter.format(mileageFrom)} km`;
    } else if (mileageFrom === mileageTo) {
      name = `Exact ${mileageFormatter.format(mileageFrom)} km`;
    } else {
      name = `${mileageFormatter.format(
        mileageFrom
      )} - ${mileageFormatter.format(mileageTo)} km`;
    }
  }

  const onClear = () => {
    setData({ mileageFrom: null, mileageTo: null });
  };

  return (
    <FilterButton
      name={name}
      content={content}
      filtered={mileageFrom || mileageTo}
      onClear={onClear}
    />
  );
};
