import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { adjustHue } from 'polished';

const Content = styled.div`
  font-size: 15px;
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.bgcolors.grey};
  background: ${(props) => props.theme.bgcolors.grey};
  box-sizing: border-box;
  width: 100%;
  padding: 20px;

  ${(props) =>
    props.type &&
    css`
      background: linear-gradient(
        135deg,
        ${get(props.theme.bgcolors, props.type, '#fff')} 20%,
        ${adjustHue(-15, get(props.theme.bgcolors, props.type, '#fff'))} 100%
      );
      border: none;
    `}
`;

const CloseButton = styled.button`
  font-size: ${(props) => props.fontSize};
  padding: 0;
  margin: 0;
  font-size: 20px;
  color: #fff;
  border: none;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  white-space: nowrap;
  background: transparent;
  float: right;
`;

export default React.forwardRef(({ children, closable, ...props }, ref) => {
  const [closed, setClosed] = useState(false);

  if (closed) return null;

  return (
    <Content ref={ref} {...props}>
      {closable && (
        <CloseButton onClick={() => setClosed(true)}>
          <FontAwesomeIcon icon="times-circle" />
        </CloseButton>
      )}
      {children}
    </Content>
  );
});
