import { useState, useEffect, useLayoutEffect } from 'react';
import {
  createBreakpoint,
  useWindowSize,
  useEffectOnce,
  useDeepCompareEffect,
} from 'react-use';
import { breakpoints } from 'styles/config';

import qs from 'qs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, useLocation } from 'react-router-dom';
import { unset, get } from 'lodash';

// export function useDebounce(value, delay) {
//   // State and setters for debounced value
//   const [debouncedValue, setDebouncedValue] = useState(value);

//   useEffect(
//     () => {
//       // Update debounced value after delay
//       const handler = setTimeout(() => {
//         setDebouncedValue(value);
//       }, delay);

//       // Cancel the timeout if value changes (also on delay change or unmount)
//       // This is how we prevent debounced value from updating if value is changed ...
//       // .. within the delay period. Timeout gets cleared and restarted.
//       return () => {
//         clearTimeout(handler);
//       };
//     },
//     [value, delay] // Only re-call effect if value or delay changes
//   );

//   return debouncedValue;
// }

// export function useInterval(callback, delay) {
//   const savedCallback = useRef();

//   // Remember the latest callback.
//   useEffect(() => {
//     savedCallback.current = callback;
//   }, [callback]);

//   // Set up the interval.
//   useEffect(() => {
//     function tick() {
//       savedCallback.current();
//     }
//     if (delay !== null) {
//       let id = setInterval(tick, delay);
//       return () => clearInterval(id);
//     }
//   }, [delay]);
// }

export function useFi(name, errors) {
  const [fi, setFi] = useState({});

  useEffect(() => {
    if (name in errors) {
      setFi({
        validateStatus: 'error',
        help: errors[name],
      });
    } else {
      setFi({});
    }
  }, [name, errors]);

  return fi;
}

export const useBreakpoint = createBreakpoint(breakpoints);

export const useWider = (device) => {
  const size = get(breakpoints, [device], 0);
  const { width } = useWindowSize();
  return size <= width;
};

export const useLockBodyScroll = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
};

export const useScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
};

export const useUrl = (store) => {
  const fetch = useStoreActions((actions) => actions[store].fetch);
  const apiParamsObj = useStoreState((state) => state[store].apiParamsObj);
  const setData = useStoreActions((actions) => actions[store].setData);
  const setParamsData = useStoreActions(
    (actions) => actions[store].setParamsData
  );

  const history = useHistory();
  const location = useLocation();

  useEffectOnce(() => {
    unset(history.location, ['state', 'noParse']);

    return () => {
      setData({ location: null });
    };
  });

  useEffect(() => {
    const noParse = get(location, ['state', 'noParse']);
    if (!noParse) {
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      setParamsData(params);
    }
  }, [location, setParamsData]);

  const storedLocation = useStoreState((state) => state[store].location);

  useDeepCompareEffect(() => {
    if (storedLocation) history.push(storedLocation);
  }, [storedLocation]);

  useDeepCompareEffect(() => {
    fetch();
  }, [apiParamsObj, fetch]);
};
