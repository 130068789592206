import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((state) => state.newcar.drive);
  const save = (drive) => setData({ drive, gearbox: null, percent: 40 });

  return (
    <CarModelData
      title="Drive"
      dataType="drives"
      value={value}
      paramKeys={['year', 'body', 'generation', 'engine_type']}
      save={save}
    />
  );
};
