import React from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import { api } from 'api';
import Switch from 'components/Switch';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from 'easy-peasy';

const Wrapper = styled.div``;
const Content = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export default () => {
  const user = useStoreState((state) => state.session.user);
  const [on, toggle] = useToggle(user.is_business);

  const setUser = useStoreActions((actions) => actions.session.setUser);

  const save = async () => {
    try {
      toggle();
      on
        ? toast.warning('Business menu items were removed')
        : toast.success('Business menu items were placed back');
      const response = await api.patchData('/api/profile/', {
        is_business: !on,
      });
      setUser(response);
    } catch (error) {
      toast.error('Something went wrong. Please try again later');
    }
  };

  return (
    <Wrapper>
      <h3>Business menu</h3>

      <Content>
        <Switch size="small" checked={on} onChange={save} />
        <div>
          Display &quot;My business&quot; menu item in the account menu.
        </div>
      </Content>
    </Wrapper>
  );
};
