import React from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import Header from './Header';
import Submenu from './Submenu';
import Records from './Records';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'submenu'
    'records';
  grid-template-columns: 1fr;
`;

const HeaderBlock = styled.div`
  grid-area: header;
`;
const SubmenuBlock = styled.div`
  grid-area: submenu;
`;
const RecordsBlock = styled.div`
  grid-area: records;
`;

export default () => {
  useScrollToTopOnMount();

  return (
    <Container>
      <HeaderBlock>
        <Header />
      </HeaderBlock>

      <SubmenuBlock>
        <Submenu />
      </SubmenuBlock>

      <RecordsBlock>
        <Records />
      </RecordsBlock>
    </Container>
  );
};
