import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import Helmet from 'components/Helmet';
import Spinner from 'components/Spinner';

const Wrapper = styled.div`
  padding: 20px;

  h1 {
    margin: 0;
  }

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px 0 20px 40px;
  }
`;

export default () => {
  const count = useStoreState((state) => state.dealers.count);
  const updating = useStoreState((state) => state.dealers.updating);
  const makeObj = useStoreState((state) => state.dealers.makeObj);
  const regionObj = useStoreState((state) => state.session.regionObj);

  let title = makeObj ? `${makeObj.name} dealers` : 'Dealers';
  if (regionObj) title += ` in ${regionObj.name}`;
  const subtitle = `${count} business${count !== 1 ? 'es' : ''}`;

  return (
    <>
      <Helmet canonical>
        <title>{title}</title>
      </Helmet>
      <Wrapper>
        {subtitle}
        <h1 className="mb0">
          {title}
          {updating && (
            <span>
              <Spinner />
            </span>
          )}
        </h1>
      </Wrapper>
    </>
  );
};
