import React from 'react';
import styled, { css } from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { get } from 'lodash';
import Select from 'components/NativeSelect';
import { Textarea, Button } from 'components/az';
import { Link, useLocation } from 'react-router-dom';
import { Field, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { api } from 'api';
import { ErrorMessage } from 'components/form';
import { useToggle } from 'react-use';
import { defErrorToast } from 'utility';
import { adjustHue } from 'polished';

const Card = styled.div`
  position: sticky;
  top: ${(props) => props.theme.header.height + 30}px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.yellow} 0%,
    ${(props) => adjustHue(-15, props.theme.colors.yellow)} 100%
  );
  color: white;
  border-radius: 20px;
  padding: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
  }

  ${(props) =>
    props.type === 'green' &&
    css`
      background: linear-gradient(
        135deg,
        ${props.theme.colors.green} 0%,
        ${adjustHue(-15, props.theme.colors.green)} 100%
      );
    `}

  h3 {
    margin: 0;
  }
`;

const FormDiv = styled.div`
  margin-bottom: 20px;
`;

export default ({ business }) => {
  const categories = useStoreState((state) => state.core.services);
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const user = useStoreState((state) => state.session.user);
  const [applied, setApplied] = useToggle();

  const garage = get(user, ['garage'], []);

  const initialValues = {
    caruser: get(garage, ['0', 'id']),
    service: '',
    content: '',
  };

  const validationSchema = Yup.object({
    caruser: Yup.string().required('Please select a car'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const data = { ...values, business: business.id };
    try {
      await api.postData(`/api/car-services/`, data);
      setApplied(true);
    } catch (error) {
      defErrorToast();
    }
  };

  const location = useLocation();

  if (!business.user) return null;

  if (!isAuthed) {
    return (
      <Card type="yellow">
        <h3 className="mb20">Apply for a service</h3>
        Please{' '}
        <Link
          to={{
            pathname: `/signin`,
            state: { background: location },
          }}
        >
          sign in
        </Link>{' '}
        to apply for a service
      </Card>
    );
  }

  if (garage.length === 0) {
    return (
      <Card type="yellow">
        <h3 className="mb20">Apply for a service</h3>
        Please{' '}
        <Link
          to={{
            pathname: `/my/garage/add`,
            state: { background: location },
          }}
        >
          add a car to your garage
        </Link>{' '}
        first to apply for a service
      </Card>
    );
  }

  return (
    <Card type={applied ? 'green' : 'yellow'}>
      <h3 className="mb20">Apply for a service</h3>

      {applied && (
        <>
          Your request was sent. <strong>{business.name}</strong> will contact
          you soon.
        </>
      )}

      {!applied && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              <FormDiv>
                <Field as={Select} block name="caruser">
                  {garage.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                  {garage.length === 0 && (
                    <option>No cars in your garage</option>
                  )}
                </Field>
                <ErrorMessage name="caruser" />
              </FormDiv>
              <FormDiv>
                <Field as={Select} block name="service">
                  <option value="">Select service type</option>
                  <option disabled>--</option>
                  {business.services.map((id) => (
                    <option key={id} value={id}>
                      {get(categories, ['byId', id, 'name'], '')}
                    </option>
                  ))}
                  <option value="">Other</option>
                </Field>
                <ErrorMessage name="service" />
              </FormDiv>
              <FormDiv>
                <Field
                  as={Textarea}
                  name="content"
                  rows={5}
                  placeholder="Your comments here..."
                />
                <ErrorMessage name="content" />
              </FormDiv>
              <Button
                block
                loading={isSubmitting}
                type="submit"
                viewtype={isAuthed ? 'red' : undefined}
              >
                Send request
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};
