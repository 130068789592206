import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { ApiContext } from 'context/ApiContext';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { ToastContainer, Slide } from 'react-toastify';
import GlobalStyle from 'styles/index';
import ToastifyStyle from 'styles/toastify';
import { theme } from 'styles/config';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import 'moment/min/locales';
import { api } from './api';
import Layout from './Layout';
import './iconLibrary';

const AuthCheck = ({ children }) => {
  const initialise = useStoreActions((actions) => actions.initialise);
  const loadProfile = useStoreActions((actions) => actions.session.loadProfile);
  const setData = useStoreActions((actions) => actions.session.setData);

  useEffect(() => {
    const fetch = async () => {
      const token = localStorage.getItem('token');
      if (token !== null) {
        api.updateToken(token);
        await loadProfile();
      }
      setData({ initialised: true });
    };
    fetch();
  }, [loadProfile, setData]);

  useEffect(() => {
    const fetch = async () => {
      initialise();
    };
    fetch();
  }, [initialise]);

  return children;
};

export default function App() {
  moment.locale('en-nz');

  useEffect(() => {
    const tokenID = process.env.REACT_APP_MAPKIT_TOKEN;

    window.mapkit.init({
      authorizationCallback: (done) => {
        done(tokenID);
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={api}>
        <AuthCheck>
          <GlobalStyle />
          <Layout />
          <ToastifyStyle />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            transition={Slide}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthCheck>
      </ApiContext.Provider>
    </ThemeProvider>
  );
}
