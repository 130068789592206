import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Listings from './Listings';
import Dealers from './Dealers';
import Services from './Services';

const Container = styled.div``;

export default () => {
  return (
    <Container>
      <Switch>
        <Route path="/saved/listings">
          <Listings />
        </Route>
        <Route path="/saved/dealers">
          <Dealers />
        </Route>
        <Route path="/saved/services">
          <Services />
        </Route>
        <Route path="/saved">
          <Redirect to="/saved/listings" />
        </Route>
      </Switch>
    </Container>
  );
};
