import React from 'react';
import { get } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import DotSeparator from 'components/DotSeparator';
import CarUserModel from 'models/caruser';
import { lllAndFromNow } from 'utility';
import ServiceTags from 'components/Service/Tags';

const Container = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 60px 1fr;
  grid-template-areas: 'avatar details';

  a {
    position: relative;
  }
`;

const ServiceAndTime = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
`;

const AvatarDiv = styled.div`
  grid-area: avatar;

  img {
    display: block;
    width: 100%;
    border-radius: 50%;
  }
`;

const DetailsDiv = styled.div`
  grid-area: details;
`;

const TagsDiv = styled.div`
  margin-top: 5px;
`;

export default ({ request }) => {
  const categories = useStoreState((state) => state.core.services);

  const location = useLocation();

  if (!request.caruser) return null;

  const caruser = new CarUserModel(request.caruser);
  const user = caruser.getUser;

  const userTo = {
    pathname: user.absoluteUrl,
    state: { background: location },
  };

  return (
    <Container>
      <AvatarDiv>
        {user.avatar && (
          <Link to={userTo}>
            <img src={user.avatarUrl} alt={user.name} />
          </Link>
        )}
      </AvatarDiv>
      <DetailsDiv>
        <Link to={userTo}>{user.name}</Link>
        <DotSeparator />
        {caruser.fullTitle}
        {caruser.plate && (
          <>
            <DotSeparator />
            {caruser.plate}
          </>
        )}

        <ServiceAndTime>
          {get(categories, ['byId', request.service, 'name'], 'Other service')}
          <DotSeparator />
          {lllAndFromNow(request.updated_at)}
        </ServiceAndTime>
        <TagsDiv>
          <ServiceTags request={request} />
        </TagsDiv>
      </DetailsDiv>
    </Container>
  );
};
