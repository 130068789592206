import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Button } from 'components/az';
import { useToggle } from 'react-use';
import Modal from 'components/Modal';
import { api } from 'api';
import { useHistory } from 'react-router-dom';

const ModalContentBlock = styled.div`
  padding: 30px 20px;
`;

const ModalContent = ({ listing }) => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useToggle(false);
  const disabled = value.toLowerCase() !== 'delete' || loading;
  const history = useHistory();

  const performDelete = async () => {
    if (disabled) return;
    setLoading(true);
    await api.deleteData(listing.myApiAbsoluteUrl);
    setLoading(false);
    history.push('/my/listings');
  };

  return (
    <ModalContentBlock>
      <h3>Confirmation required</h3>
      <p>
        Are you sure you want to delete <strong>{listing.fullTitle}</strong>?
      </p>
      <p className="mb20">
        You won&apos;t be able to undo this delete operation.
      </p>
      <p>Type &apos;delete&apos; if you want to proceed</p>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={{ width: 150, display: 'inline-block' }}
        onPressEnter={performDelete}
      />{' '}
      <Button
        disabled={disabled}
        loading={loading}
        viewtype={disabled ? 'default' : 'primary'}
        onClick={performDelete}
      >
        Confirm
      </Button>
    </ModalContentBlock>
  );
};

const Content = styled.div``;

export default ({ listing }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <h3>Delete listing</h3>

      <Button onClick={toggle} viewtype="primary" size="small">
        Delete listing
      </Button>
      <p className="text-secondary mt10">
        <small>
          Permanently delete the listing and all the associated content.
        </small>
      </p>

      {on && (
        <Modal width="600" close={toggle}>
          <ModalContent listing={listing} close={toggle} />
        </Modal>
      )}
    </Content>
  );
};
