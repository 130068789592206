import { trim, get as _get } from 'lodash';
import BaseModel from '.';

export default class CarModel extends BaseModel {
  get shortTitle() {
    const m = _get(this, ['model', 'name'], '');
    const g = _get(this, ['generation', 'name'], '');

    let title = `${_get(this, ['make', 'name'], '')} ${m}`;
    if (m !== g) title = `${title} ${g}`;

    return trim(title);
  }

  get title() {
    const y = _get(this, ['year'], '');
    if (y) return `${this.shortTitle}, ${y}`;
    return this.shortTitle;
  }
}
