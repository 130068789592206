import { createGlobalStyle } from 'styled-components';
import { range } from 'lodash';
import { colors } from './config';

const spaceAmounts = range(0, 55, 5);
const sides = ['top', 'bottom', 'left', 'right'];

const mpStyle = spaceAmounts
  .map(
    (space) => `${sides
      .map(
        (side) => `.m${side[0]}${space}{margin-${side}:${space}px !important;}
          .p${side[0]}${space}{padding-${side}:${space}px !important;}`
      )
      .join('')}

  .my${space} {
    margin-top: ${space}px;
    margin-bottom: ${space}px;
  }

  .mx${space} {
    margin-left: ${space}px;
    margin-right: ${space}px;
  }

  .py${space} {
    padding-top: ${space}px;
    padding-bottom: ${space}px;
  }

  .px${space} {
    padding-left: ${space}px;
    padding-right: ${space}px;
  }`
  )
  .join('');

export default createGlobalStyle`
  html {
    text-size-adjust: 100%;
  }  

  body {
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 1.42;
    background-color: #fff;
    /* min-height: 100vh; */
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: ${colors.text};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  ul, ol, li, dl, dt, dd, h1, h2, h3, h4, h5, h6, hgroup, p, blockquote, figure, form, fieldset, input, legend, pre, abbr, button {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-weight: 500;

    a {
      color: ${colors.text};
      text-decoration: none;
    }
  }

  h1 {
    font-size: 36px;
    margin-bottom: 20px;

    span {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  h2 {
    font-size: 28px;
    margin-bottom: 15px;

    span {
      font-size: 20px;
      margin-left: 10px;
    }
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0.5em;
  }

  a {
    color: ${colors.primary};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  a.cover {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }

  /* a {
    svg.fa-chevron-right {
      margin-left: 5px;
      font-size: 75%;
    }
  } */



  textarea, input[type="text"], input[type="email"], input[type="password"] {
    -webkit-appearance: none;
  }

  .text-green,
  .text-success {
    color: ${colors.success};
  }

  .text-warning {
    color: ${colors.orange};
  }

  .text-red,
  .text-danger,
  .text-error {
    color: ${colors.error};
  }

  .text-gray,
  .text-secondary {
    color: ${colors.secondary};
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }


  .mr05em {
    margin-right: 0.5em;
  }

  .mr06em {
    margin-right: 0.6em;
  }

  .DayPicker_weekHeader__verticalScrollable {
    box-sizing: border-box;
  }

  ${mpStyle}
`;
