import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';

export default () => {
  const value = useStoreState((state) => state.listings.gearbox);
  const items = useStoreState((state) => state.core.gearboxes);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const onChange = (gearbox) => setData({ gearbox });

  return (
    <label>
      Transmission
      <DefSelect
        placeholder="Transmission"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
};
