import React from 'react';
import styled from 'styled-components';
import Helmet from 'components/Helmet';

const Container = styled.div``;

export default () => (
  <Container>
    <Helmet canonical>
      <title>Saved</title>
    </Helmet>

    <h1>Saved</h1>
  </Container>
);
