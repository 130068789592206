import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((store) => store.newcar.generation);
  const save = (generation) =>
    setData({ generation, engine_type: null, percent: 30 });

  return (
    <CarModelData
      title="Generation"
      dataType="generations"
      value={value}
      paramKeys={['year', 'body']}
      save={save}
    />
  );
};
