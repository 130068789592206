import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, ButtonLink } from 'components/az';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuid } from 'uuid';
import Loading from 'components/Loading';
import { AppleButton } from 'components/az/Button';
import { api } from 'api';
import Email from './Email';

const Wrapper = styled.div`
  padding: 20px;

  h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
  }
`;

const Content = styled.div`
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
`;

const Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export default () => {
  const user = useStoreState((state) => state.session.user);
  const setSessionToken = useStoreActions(
    (actions) => actions.session.setToken
  );
  const [choice, setChoice] = useState(null);
  const [error, setError] = useState();
  const [pop, setPop] = useState(false);
  const ref = useRef(String(uuid()));

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: 'nz.co.autozealand.service',
      scope: 'name email',
      redirectURI: 'https://autozealand.co.nz/apple',
      usePopup: true,
      state: ref.current,
    });
  }, []);

  const appleClick = async () => {
    setPop(true);
    try {
      setError(null);
      const data = await window.AppleID.auth.signIn();
      if (data.authorization.state === ref.current) {
        setChoice('apple');
        const response = await api.checkAppleAuth(data);
        setSessionToken(response.token);
      }
    } catch (e) {
      setError('Apple authentication failed');
    }
    setPop(false);
  };

  if (user) {
    return <Redirect to="/garage" />;
  }

  return (
    <Wrapper>
      {choice === 'email' && <Email />}
      {choice === 'apple' && (
        <Content>
          <h2>Sign in with Apple</h2>

          {error ? (
            <span className="text-danger">{error}</span>
          ) : (
            <Loading text="Verifying..." />
          )}
        </Content>
      )}
      {choice === null ? (
        <Content>
          <h2>Sign in</h2>

          <AppleButton block className="mb10" onClick={appleClick}>
            <FontAwesomeIcon icon={['fab', 'apple']} fixedWidth /> Continue with
            Apple
          </AppleButton>

          <Button block className="mb20" onClick={() => setChoice('email')}>
            <FontAwesomeIcon icon={['far', 'envelope']} fixedWidth /> Continue
            with email
          </Button>

          {pop ? (
            <Loading
              text={
                <span>
                  Connecting to <FontAwesomeIcon icon={['fab', 'apple']} />
                </span>
              }
            />
          ) : (
            <p>
              If you don&apos;t have an account, we&apos;ll create one for you
            </p>
          )}
        </Content>
      ) : (
        <Footer>
          <ButtonLink active onClick={() => setChoice(null)}>
            <FontAwesomeIcon fixedWidth icon="chevron-left" /> All sign in
            options
          </ButtonLink>
        </Footer>
      )}
    </Wrapper>
  );
};
