import React from 'react';
import styled from 'styled-components';
import GreyBody from 'components/GreyBody';
import { useLocation, Link, Redirect } from 'react-router-dom';
import { Button } from 'components/az';
import Helmet from 'components/Helmet';
import { useStoreState } from 'easy-peasy';
import garage from 'assets/img/stephen-leonardi-WKBlYfFzsZU-unsplash.jpg';

const Wrapper = styled.section`
  display: grid;
  grid-template-areas: 'photo' 'text' 'extra';
  grid-template-columns: 1fr;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-areas: 'text photo' 'extra extra';

    grid-template-columns: 1fr 1fr;
  }
`;

const TextDiv = styled.div`
  grid-area: text;
  background: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px;
`;

const TextWrapper = styled.div`
  max-width: 400px;
`;

const PhotoDiv = styled.div`
  grid-area: photo;
  background: url(${garage}) no-repeat center center;
  background-size: cover;
  min-height: 300px;

  @media ${(props) => props.theme.devices.tablet} {
    min-height: 500px;
  }
`;

export default () => {
  const location = useLocation();

  const isAuthed = useStoreState((state) => state.session.isAuthed);

  if (isAuthed) return <Redirect to="/my/garage" />;

  return (
    <>
      <Helmet canonical>
        <title>My virtual garage</title>
      </Helmet>
      <GreyBody />
      <Wrapper>
        <TextDiv>
          <TextWrapper>
            <h1>My virtual garage</h1>

            <p>
              Your virtual garage is your tracker of everything that happened
              with your car.
            </p>
            <p>
              Share the history of your car with a mechanic or a potential buyer
              when the time comes.
            </p>

            <Button
              className="mt30"
              viewtype="primary"
              as={Link}
              to={{
                pathname: `/signin`,
                state: { background: location },
              }}
            >
              Sign in and add my car
            </Button>
          </TextWrapper>
        </TextDiv>
        <PhotoDiv />
      </Wrapper>
    </>
  );
};
