import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { get } from 'lodash';
import WhiteContent from 'components/WhiteContent';

const Content = styled.div`
  ul {
    list-style-type: none;
    column-count: 2;

    li {
      display: block;
      margin-bottom: 10px;
    }
  }
`;

export default ({ business }) => {
  const categories = useStoreState((state) => state.core.services);

  if (!business.user) return null;

  return (
    <WhiteContent>
      <Content>
        <h2>Specialisation</h2>
        <ul>
          {business.services.map((id) => (
            <li key={id}>{get(categories, ['byId', id, 'name'], '')}</li>
          ))}
        </ul>
      </Content>
    </WhiteContent>
  );
};
