import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import CarUserModel from 'models/caruser';
import { Button } from 'components/az';
import { defErrorToast } from 'utility';
import { useToggle } from 'react-use';
import { api } from 'api';
import { toast } from 'react-toastify';

const Wrapper = styled.header`
  padding: 30px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  display: grid;
  align-items: center;
  gap: 15px;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'avatar content';
`;

const AvatarDiv = styled.div`
  grid-area: avatar;

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: block;
  }
`;

const ContentDiv = styled.div`
  grid-area: content;
  h3 {
    margin-bottom: 5px;
  }
`;

export default ({ thread }) => {
  const user = useStoreState((store) => store.session.user);
  const setCarUser = useStoreActions((actions) => actions.session.setCarUser);
  const caruser = new CarUserModel(thread.caruser);
  const [sold, setSold] = useToggle();

  const opponent = thread.getOpponent(user);

  const [loading, setLoading] = useToggle();

  const transfer = async () => {
    try {
      setLoading(true);
      const response = api.postData(
        `/api/my/cars/${thread.caruser.slug}/transfer/`,
        { user: opponent.id }
      );
      setSold(true);
      setCarUser(response);
      toast.success('Your car marked as Sold');
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  const btnVis =
    thread.caruser &&
    caruser.status !== 'SOLD' &&
    caruser.isOwner(user) &&
    !sold;

  return (
    <Wrapper>
      <AvatarDiv>
        <img src={opponent.avatarUrl} alt={opponent.name} />
      </AvatarDiv>
      <ContentDiv>
        <h3>{opponent.name}</h3>
        {thread.caruser && (
          <>
            <p>
              <span className="text-secondary">Listing </span>
              <Link
                to={{
                  pathname: caruser.absoluteUrl,
                }}
              >
                {caruser.fullTitle}
              </Link>
            </p>
            {btnVis && (
              <Button
                viewtype="red"
                size="small"
                onClick={transfer}
                loading={loading}
              >
                Set as new owner
              </Button>
            )}
          </>
        )}
      </ContentDiv>
    </Wrapper>
  );
};
