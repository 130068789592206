import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Helmet from 'components/Helmet';
import { Button, Input } from 'components/az';
import { Field, Formik, Form } from 'formik';
import { ErrorMessage, FormItem } from 'components/form';
import * as Yup from 'yup';
import { api } from 'api';
import NativeSelect from 'components/NativeSelect';
import { useStoreActions } from 'easy-peasy';

const Container = styled.div`
  padding: 30px;

  h1 {
    font-size: 25px;
  }
`;

const NewBusiness = () => {
  const history = useHistory();
  const fetch = useStoreActions((action) => action.mybusiness.fetch);
  const setMyBusiness = useStoreActions(
    (action) => action.session.setMyBusiness
  );

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('We need your company name'),
  });

  const close = () => {
    history.push('/my/business');
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const response = await api.postData('/api/my/businesses/', values);
    setMyBusiness(response);
    history.push(`/my/business/${response.slug}`);
    fetch();
  };

  return (
    <Container>
      <Helmet>
        <title>Add my business</title>
      </Helmet>

      <h1>Add my business</h1>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormItem>
              <label htmlFor="company-name">Company name</label>
              <Field
                id="company-name"
                name="name"
                placeholder="Name"
                as={Input}
              />
              <ErrorMessage name="name" />
            </FormItem>
            <FormItem>
              <label htmlFor="company-type">Company type</label>
              <Field id="company-type" as={NativeSelect} name="subtype">
                <option value="DEALER">Dealer</option>
                <option value="SERVICE">Service</option>
              </Field>
              <ErrorMessage name="subtype" />
            </FormItem>
            <div className="mt40">
              <Button type="button" onClick={close}>
                Cancel
              </Button>{' '}
              <Button loading={isSubmitting} type="submit" viewtype="primary">
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default NewBusiness;
