import React, { useRef } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useToggle, useClickAway } from 'react-use';
import { ButtonLink, Button } from 'components/az';
import ProfileMenu from 'components/ProfileMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AvatarDiv = styled.div`
  position: relative;
  height: 40px;

  img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px !important; */
  box-shadow: ${(props) => props.theme.shadow.hover};
  width: 250px;
  z-index: 1100;
  padding: 15px;
  border-radius: 20px;

  ul {
    list-style-type: none;
    font-size: 15px;

    li {
      margin-bottom: 1px;
      a {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 0.6em;
        align-items: center;

        padding: 10px 10px 10px 15px;
        border-radius: 10px;
        svg {
          color: ${(props) => props.theme.colors.grey2};
        }

        span {
          color: ${(props) => props.theme.colors.grey};
          font-size: 12px;
          background: ${(props) => props.theme.colors.grey6};
          padding: 3px 10px;
          border-radius: 14px;
        }

        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.grey6};
          color: ${(props) => props.theme.colors.red};
          text-decoration: none;
          svg {
            color: ${(props) => props.theme.colors.red};
          }
        }
      }
    }
    li.separator {
      margin: 10px 15px;
      border-bottom: 1px solid ${(props) => props.theme.border.color};
    }
  }
`;

export default () => {
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const user = useStoreState((state) => state.session.user);
  const ref = useRef(null);
  const [on, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  if (!isAuthed) return null;

  return (
    <AvatarDiv>
      {user.avatar ? (
        <ButtonLink onClick={toggle}>
          <img src={user.avatarUrl} alt={user.name} />
        </ButtonLink>
      ) : (
        <Button shape="round" onClick={toggle}>
          <FontAwesomeIcon icon={['far', 'user']} />
        </Button>
      )}
      {on && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div ref={ref} onClick={toggle}>
          <Content>
            <ProfileMenu icons />
          </Content>
        </div>
      )}
    </AvatarDiv>
  );
};
