import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/img/newlogo.png';
import { useStoreState } from 'easy-peasy';
import { InitUserButton } from 'components/az/Button';
import Location from './Location';
import Menu from './Menu';
import MenuAccount from './MenuAccount';

const Container = styled.div`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: ${(props) => props.theme.shadow.medium};
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 1fr auto auto auto;
  grid-template-areas: 'logo menu location profile';
  padding: 15px 20px;

  a {
    color: ${(props) => props.theme.colors.text};
  }
`;

const ProfileBlock = styled.div`
  grid-area: profile;
  justify-self: end;

  input[type='image'] {
    height: 40px;
    border-radius: 40px;
    display: block;
  }
`;

const MenuBlock = styled.div`
  grid-area: menu;
`;

const LogoBlock = styled.div`
  grid-area: logo;
  img {
    height: 40px;
    display: block;
  }
`;

const LocationBlock = styled.div`
  grid-area: location;
`;

export default () => {
  const initialised = useStoreState((state) => state.session.initialised);
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const location = useLocation();

  return (
    <Container>
      <MenuBlock>
        <Menu />
      </MenuBlock>
      <LogoBlock>
        <Link to="/">
          <img src={logo} alt="AutoZealand" />
        </Link>
      </LogoBlock>
      <LocationBlock>
        <Location />
      </LocationBlock>
      <ProfileBlock>
        {isAuthed && <MenuAccount />}

        {!isAuthed &&
          (initialised ? (
            <Link
              to={{
                pathname: `/signin`,
                state: { background: location },
              }}
            >
              Sign in
            </Link>
          ) : (
            <InitUserButton />
          ))}
      </ProfileBlock>
    </Container>
  );
};
