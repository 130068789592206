import React from 'react';
import { api } from 'api';
import Loading from 'components/Loading';
import { useAsync } from 'react-use';
import styled from 'styled-components';
import GreyBody from 'components/GreyBody';
import moment from 'moment';
import Helmet from 'components/Helmet';

const Content = styled.div`
  padding: 30px 20px;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    margin-top: 20px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    padding: 0 0 20px 30px;

    li {
      margin-bottom: 10px;
    }
  }
`;

export default ({ slug }) => {
  const state = useAsync(async () => {
    const data = await api.getData(`/api/pages/${slug}/`);
    return data;
  }, [slug]);

  return (
    <Content>
      <GreyBody />
      {state.loading ? (
        <Loading padding={20} />
      ) : (
        <>
          <Helmet canonical>
            <title>{state.value.title}</title>
          </Helmet>
          <h1>{state.value.title}</h1>
          <p className="mb20">
            <strong>
              Last updated: {moment(state.value.updated_at).format('LL')}
            </strong>
          </p>
          <div dangerouslySetInnerHTML={{ __html: state.value.body }} />
        </>
      )}
    </Content>
  );
};
