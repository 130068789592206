import React from 'react';
import styled from 'styled-components';
import { useScrollToTopOnMount } from 'hooks';
import WrappedPagination from 'components/WrappedPagination';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import Records from './Records';
import Header from './Header';
import View from './View';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'records'
    'footer';
  grid-template-columns: 1fr;
`;

const HeaderBlock = styled.div`
  grid-area: header;
`;
const RecordsBlock = styled.div`
  grid-area: records;
`;
const FooterBlock = styled.div`
  grid-area: footer;
`;

const List = () => (
  <Container>
    <HeaderBlock>
      <Header />
    </HeaderBlock>
    <RecordsBlock>
      <Records />
    </RecordsBlock>
    <FooterBlock>
      <WrappedPagination store="mybusiness" />
    </FooterBlock>
  </Container>
);

export default () => {
  useScrollToTopOnMount();
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:slug`}>
        <View />
      </Route>
      <Route>
        <List />
      </Route>
    </Switch>
  );
};
