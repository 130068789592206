import React, { useState, useEffect, useContext, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, ButtonLink } from 'components/az';

import { ApiContext } from 'context/ApiContext';
import { validateEmail } from 'utility';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'field' 'button';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    grid-template-areas: 'field button';
  }
`;
const FieldDiv = styled.div`
  grid-area: field;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const ButtonDiv = styled.div`
  grid-area: button;
`;

export default () => {
  const refEmail = useRef(null);

  const user = useStoreState((state) => state.session.user);
  const setSessionToken = useStoreActions(
    (actions) => actions.session.setToken
  );

  const api = useContext(ApiContext);

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (refEmail.current) refEmail.current.focus();
  }, []);

  const stepBack = () => {
    setStep(1);
    setError(null);
  };

  async function authEmail() {
    if (!validateEmail(email)) {
      setError('Please provide a valid email address');
      return;
    }
    setLoading(true);
    setError(null);

    try {
      await api.sendAuthEmail(email);
      setLoading(false);
      setStep(2);
    } catch (e) {
      console.error(e);
    }
  }

  async function callbackAuth() {
    setLoading(true);
    try {
      const response = await api.checkAuthToken(token);
      setSessionToken(response.token);
    } catch (e) {
      setError(
        e.response
          ? e.response.data.token
          : 'An error occurred please refresh the page and try again'
      );
    }
    setLoading(false);
  }

  if (user) {
    return <Redirect to="/settings" />;
  }

  const Error = error && <small className="text-danger">{error}</small>;

  return (
    <>
      {step === 1 ? (
        <>
          <h2>Sign in with Email</h2>
          <p>
            Enter your email address, and we&apos;ll send you an authentication
            token
          </p>
          <FormDiv>
            <FieldDiv>
              <Input
                ref={refEmail}
                placeholder="Your email"
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onPressEnter={authEmail}
              />
              {Error}
            </FieldDiv>
            <ButtonDiv>
              <Button viewtype="primary" loading={loading} onClick={authEmail}>
                Continue
              </Button>
            </ButtonDiv>
          </FormDiv>
        </>
      ) : (
        <>
          <h2>Check your inbox</h2>
          <p>
            We just emailed a token to <strong>{email}</strong>. Enter it below,
            and you’ll be signed in.
          </p>
          {/* <Text>We just emailed a confirmation link to <strong>{email}</strong>. Click the link to complete your account set-up.</Text> */}
          <FormDiv>
            <FieldDiv>
              <Input
                placeholder="Token"
                id="token"
                autoComplete="off"
                type="number"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                onPressEnter={callbackAuth}
              />
              {Error}
            </FieldDiv>
            <ButtonDiv>
              <Button
                viewtype="primary"
                loading={loading}
                onClick={callbackAuth}
              >
                Verify
              </Button>
            </ButtonDiv>
          </FormDiv>
          <ButtonLink active onClick={stepBack}>
            Noticed a typo in email?
          </ButtonLink>
        </>
      )}
    </>
  );
};
