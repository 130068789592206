import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import FadeInDiv from './anim/FadeInDiv';

const Wrapper = styled.div`
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding}px;
    `};
`;

export default ({ text, padding }) => {
  return (
    <FadeInDiv>
      <Wrapper padding={padding}>
        {text || 'Loading...'}{' '}
        <FontAwesomeIcon icon={['far', 'compass']} spin />
      </Wrapper>
    </FadeInDiv>
  );
};
