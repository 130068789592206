import React from 'react';
import { NavLink } from 'react-router-dom';
import HorizontalMenu, { MenuItem } from 'components/HorizontalMenu';
import ItemCount from 'components/ItemCount';
import { get } from 'lodash';

export default ({ caruser }) => {
  const url = caruser.myAbsoluteUrl;

  const cntPhotos = get(caruser, ['photos'], []).length;
  const cntRequests = get(caruser, ['requests'], []).length;

  return (
    <HorizontalMenu>
      <MenuItem>
        <NavLink exact to={url}>
          Dashboard
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to={`${url}/services`}>
          Services
          <ItemCount number={cntRequests} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to={`${url}/photos`}>
          Photos
          <ItemCount number={cntPhotos} />
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink to={`${url}/settings`}>Settings</NavLink>
      </MenuItem>
      {caruser.listing && (
        <MenuItem>
          <NavLink to={`${url}/listing`}>Listing</NavLink>
        </MenuItem>
      )}
    </HorizontalMenu>
  );
};
