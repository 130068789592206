import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import CarModelData from './CarModelData';

export default () => {
  const setData = useStoreActions((actions) => actions.newcar.setData);
  const value = useStoreState((store) => store.newcar.body);
  const save = (body) => setData({ body, generation: null, percent: 25 });

  return (
    <CarModelData
      title="Body"
      dataType="bodies"
      value={value}
      paramKeys={['year']}
      save={save}
    />
  );
};
