import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.footer`
  margin: 0 auto;
  padding: 30px;
  font-size: 14px;
  display: grid;
  align-items: center;
  gap: 20px;
  color: ${(props) => props.theme.colors.secondary};
  grid-template-columns: 1fr;
  grid-template-areas: 'links' 'copyright';
  text-align: center;

  a {
    color: ${(props) => props.theme.colors.secondary};

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'copyright links';
  }
`;

const CopyrightDiv = styled.div`
  grid-area: copyright;

  @media ${(props) => props.theme.devices.tablet} {
    justify-self: start;
  }
`;

const LinksDiv = styled.div`
  text-align: right;
  line-height: 2;
  grid-area: links;

  @media ${(props) => props.theme.devices.tablet} {
    justify-self: end;
  }
`;

export default () => {
  return (
    <Container>
      <CopyrightDiv>&copy; {moment().year()}, Autozealand ltd.</CopyrightDiv>

      <LinksDiv>        
        <Link to="/privacy">Privacy policy</Link> ·{' '}
        <Link to="/terms">Terms of use</Link> ·{' '}
        <a
          href="https://twitter.com/autozealand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth />
        </a>{' '}
        ·{' '}
        <a
          href="https://www.facebook.com/autozealand"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} fixedWidth />
        </a>
        <br />
        <a className="" href="https://apps.apple.com/nz/app/autozealand/id1608837486?itsct=apps_box_badge&amp;itscg=30200" >
          <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1644537600&h=fb74481ecceb1f38a3470f65740ac390" alt="Download on the App Store" />
        </a>
      </LinksDiv>
    </Container>
  );
};
