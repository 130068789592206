import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/az';
import Helmet from 'components/Helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas: 'title new';
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export default () => (
  <>
    <Helmet canonical>
      <title>My listings</title>
    </Helmet>
    <Container>
      <h1>My listings</h1>

      <Button as={Link} to="/sell">
        <FontAwesomeIcon
          icon="plus-circle"
          className="text-danger"
          fixedWidth
        />{' '}
        Start new
      </Button>
    </Container>
  </>
);
