import React, { useEffect } from 'react';
import styled from 'styled-components';
import bgimg from 'assets/home/hannes-egler-unsplash-2000.jpg';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Alert } from 'components/az';
import GreyBody from 'components/GreyBody';
import { useStoreActions } from 'easy-peasy';
import Records from 'pages/Cars/Listings/Records';

const Content = styled.div`
  padding: 0 20px 20px;
  margin: 10px auto 30px;
  max-width: 1200px;

  @media ${(props) => props.theme.devices.tablet} {
    margin-top: 30px;
    padding: 0 30px 20px;
  }
`;

const Hero = styled.div`
  display: grid;
  align-items: center;

  text-align: center;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 500px;

  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${bgimg}) no-repeat center center;
  background-size: cover;
  line-height: 1.1;
  margin-bottom: 30px;

  h1 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  p {
    font-size: 22px;
    margin-bottom: 30px;
    color: #fff;
  }
  a {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    display: inline-block;

    &:hover {
      text-decoration: none;
      background: white;
      color: #333;
    }
  }
`;

const Explain = styled.div`
  display: grid;
  gap: 0 30px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'about'
    'private'
    'service'
    'dealer'
    'latest';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      '. . about about about about about . .'
      'private private private service service service dealer dealer dealer'
      '. latest latest latest latest latest latest latest .';
  }

  h2 {
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  h3 {
    font-size: 22px;
  }
`;

const CustomUl = styled.ul`
  margin: 1em 0;

  li {
    margin-left: 1em;
    margin-bottom: 0.5em;
  }
`;

const AboutDiv = styled.div`
  grid-area: about;
`;

const LatestDiv = styled.div`
  grid-area: latest;
  margin: 0 -20px;
`;

const TypeDiv = styled.div`
  border-radius: 20px;
  padding: 30px;
  gap: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  background: #fff;
  margin-bottom: 30px;

  h3 {
    margin-bottom: 30px;
  }
`;

const Icon = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  line-height: 60px;
  font-size: 30px;
  text-align: center;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.colors.yellow} 0%,
    ${(props) => props.theme.colors.red} 100%
  );
  color: white;
  margin-bottom: 20px;
`;

const PrivateDiv = styled(TypeDiv)`
  grid-area: private;
`;
const ServiceDiv = styled(TypeDiv)`
  grid-area: service;
`;
const DealerDiv = styled(TypeDiv)`
  grid-area: dealer;
`;

export default () => {
  const location = useLocation();
  const fetch = useStoreActions((actions) => actions.listings.fetch);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const to = {
    pathname: `/signin`,
    state: { background: location },
  };
  return (
    <>
      <GreyBody />
      <Hero>
        <div>
          <h1>Your car&apos;s digital passport</h1>
          <Link
            to={{
              pathname: `/signin`,
              state: { background: location },
            }}
          >
            Join us
          </Link>
        </div>
      </Hero>
      <Content>
        <Explain>
          <AboutDiv>
            <h2>A break through in New Zealand auto market</h2>
            <p>
              AutoZealand is not just an auto market - we are the history
              tracker of cars.
            </p>
            <p>Together we can build a transparent market where:</p>
            <CustomUl>
              <li>
                Buyers can see the car&apos;s mechanical history - who, when,
                and what did to the vehicle.
              </li>
              <li>
                Sellers can get a better price for a well-maintained vehicle and
                can prove it.
              </li>
            </CustomUl>
            <p>
              The mechanical records on the vehicle can be provided only by auto
              services.
            </p>
            <h3 className="mt30">We need your help!</h3>
            <p>
              Next time you&apos;ll go to your mechanic, tell them about us and
              offer them to create an account.
            </p>
            <Alert type="yellow" className="my30">
              The website is under active developement. Mistakes are possible.
              Please contact us{' '}
              <a href="mailto:hello@autozealand.co.nz">
                hello@autozealand.co.nz
              </a>
            </Alert>
            If you just want to sell a car &rarr;{' '}
            <Link to="/sell">create a sell listing</Link>
            <h2 className="mt50">
              What AutoZealand can do for you if you are...
            </h2>
          </AboutDiv>
          <PrivateDiv>
            <div>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'user']} />
              </Icon>
              <h3>...a private person</h3>

              <CustomUl>
                <li>Add your car into a virtual garage.</li>
                <li>Keep track of WoF, Licence, and Service due dates.</li>
                <li>
                  Service-time? Send a request to your mechanic and share the
                  history of the car. Ask them to record all the work done on
                  the vehicle. Give your mechanic an honest review.
                </li>
                <li>
                  Sell-time? Assign the price and share the mechanical and other
                  histories.
                </li>
                <li>Sold the car? Pass the car-history to the new owner.</li>
              </CustomUl>

              <strong>Sell less than two cars a year? - It&apos;s free!</strong>
            </div>
            <div>
              <Button block as={Link} viewtype="red" to={to}>
                Sign in
              </Button>
            </div>
          </PrivateDiv>
          <ServiceDiv>
            <div>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'oil-can']} />
              </Icon>
              <h3>...a technical service</h3>
              <CustomUl>
                <li>Create a business page.</li>
                <li>Provide all the details and upload photos.</li>
                <li>
                  Receive service requests from the website members and check
                  the mechanical history of the car.
                </li>
                <li>Record all the work you performed on the vehicle.</li>
                <li>Get your review.</li>
              </CustomUl>
              <strong>You can be reviewed only by your real customers.</strong>
            </div>
            <div>
              <Button block as={Link} viewtype="red" to={to}>
                Sign in
              </Button>
            </div>
          </ServiceDiv>
          <DealerDiv>
            <div>
              <Icon>
                <FontAwesomeIcon icon={['fas', 'kiwi-bird']} />
              </Icon>
              <h3>...a dealer</h3>
              <CustomUl>
                <li>Create a business page.</li>
                <li>Provide all the details and upload photos.</li>
                <li>Create listings.</li>
                <li>Sell.</li>
                <li>Profit!</li>
              </CustomUl>
              Later, we&apos;ll introduce a subscription model, but everything
              is free so far as we need to build for you the best possible
              platform first.
            </div>
            <div>
              <Button block as={Link} viewtype="red" to={to}>
                Sign in
              </Button>
            </div>
          </DealerDiv>
          <LatestDiv>
            <h2>Latest listings</h2>
            <Records />
          </LatestDiv>
        </Explain>
      </Content>
    </>
  );
};
