/* eslint-disable no-param-reassign */
import { action, thunk, computed, thunkOn } from 'easy-peasy';
import { normalize, schema } from 'normalizr';
import { clone, get, forEach, mapKeys, filter, keyBy, camelCase } from 'lodash';
import { api } from '../api';

const defaultSchema = new schema.Entity('items');
const stObj = { byId: {}, allIds: [], loading: true };

export default {
  makes: { list: [], byId: {}, bySlug: {}, allIds: [] },
  bodies: { list: [], byId: {}, bySlug: {}, allIds: [] },
  gearboxes: { list: [], byId: {}, bySlug: {}, allIds: [] },
  engineTypes: { list: [], byId: {}, bySlug: {}, allIds: [] },
  drives: { list: [], byId: {}, bySlug: {}, allIds: [] },
  colours: { list: [], byId: {}, bySlug: {}, allIds: [] },
  regions: { list: [], byId: {}, bySlug: {}, allIds: [] },
  districts: { list: [], byId: {}, allIds: [] },
  services: { list: [], byId: {}, bySlug: {}, allIds: [] },

  makeModels: {},
  modelGenerations: {},

  serviceBySlug: computed((state) => (slug) =>
    get(state, ['services', 'bySlug', slug], null)
  ),
  regionBySlug: computed((state) => (slug) =>
    get(state, ['regions', 'bySlug', slug], null)
  ),
  makeBySlug: computed((state) => (slug) =>
    get(state, ['makes', 'bySlug', slug], null)
  ),

  regionDistricts: computed((state) => (id) => {
    const d = filter(state.districts.list, ['region', id]);
    return {
      byId: keyBy(d, 'id'),
      allIds: d.map((x) => x.id),
    };
  }),

  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = value;
    });
  }),

  loadData: thunk(async (actions) => {
    const { districts, ...other } = await api.getData('/api/core/');

    actions.setData({
      districts: {
        list: districts,
        byId: keyBy(districts, 'id'),
        allIds: districts.map((x) => x.id),
      },
    });

    forEach(other, (list, key) => {
      actions.setData({
        [camelCase(key)]: {
          list,
          loading: false,
          byId: keyBy(list, 'id'),
          allIds: list.map((x) => x.id),
          bySlug: keyBy(list, 'slug'),
        },
      });
    });
  }),

  makeModelsById: computed((state) => (id) => get(state.makeModels, [id])),
  modelGenerationsById: computed((state) => (id) =>
    get(state.modelGenerations, [id])
  ),

  setMakeModels: action((state, { id, data }) => {
    state.makeModels[id] = data;
  }),
  setModelGenerations: action((state, { id, data }) => {
    state.modelGenerations[id] = data;
  }),

  loadModels: thunk(async (actions, { makeId, makeSlug }, { getState }) => {
    const state = getState();
    const id = makeId || get(state.makes, ['bySlug', makeSlug, 'id']);

    if (!id || id in state.makeModels) return;

    actions.setMakeModels({ id, data: clone(stObj) });

    const data = await api.getData(`/api/vehicles/makes/${id}/models/`);
    const nd = normalize(data, [defaultSchema]);
    actions.setMakeModels({
      id,
      data: {
        loading: false,
        byId: nd.entities.items,
        allIds: nd.result,
        bySlug: mapKeys(nd.entities.items, (value, key) => value.slug),
      },
    });
  }),

  loadGenerations: thunk(
    async (actions, { makeSlug, modelSlug, modelId }, { getState }) => {
      const state = getState();

      let id = null;
      if (modelId) {
        id = modelId;
      } else {
        const makeId = get(state.makes, ['bySlug', makeSlug, 'id']);
        id = get(state.makeModels, [makeId, 'bySlug', modelSlug, 'id']);
      }

      if (!id || id in state.modelGenerations) return;

      actions.setModelGenerations({ id, data: clone(stObj) });

      const data = await api.getCarModelData({
        model: id,
        dataType: 'generations',
        params: {},
      });
      const nd = normalize(data, [defaultSchema]);
      actions.setModelGenerations({
        id,
        data: {
          loading: false,
          byId: nd.entities.items,
          allIds: nd.result,
          // bySlug: mapKeys(nd.entities.items, (value, key) => value.slug)
        },
      });
    }
  ),

  onNewcarSetData: thunkOn(
    (_, storeActions) => storeActions.newcar.setData,
    async (actions, target) => {
      if ('make' in target.payload) {
        actions.loadModels({ makeId: target.payload.make });
      }
      if ('model' in target.payload) {
        actions.loadGenerations({ modelId: target.payload.model });
      }
    }
  ),
  onListingsSetData: thunkOn(
    (_, storeActions) => [
      storeActions.listings.setData,
      storeActions.listings.setParamsData,
    ],
    async (actions, target) => {
      if ('make' in target.payload) {
        actions.loadModels({ makeSlug: target.payload.make });
      }
      if ('model' in target.payload && 'makeSlug' in target.payload) {
        actions.loadGenerations({
          makeSlug: target.payload.makeSlug,
          modelSlug: target.payload.model,
        });
      }
    }
  ),
};
