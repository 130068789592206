import React, { useState, useEffect } from 'react';
import { get, filter, trimStart, countBy } from 'lodash';
import { Input, Button, SkeletonLine } from 'components/az';
import FadeInDiv from 'components/anim/FadeInDiv';
import styled from 'styled-components';
import { useWider } from 'hooks';

const StyledUl = styled.ul`
  padding: 0;
  column-count: 2;
  column-gap: 20px;

  li {
    display: inline-block;
    width: 100%;
    list-style-type: none;
    padding: 0;
  }

  @media ${(props) => props.theme.devices.tablet} {
    column-count: 3;
  }

  @media ${(props) => props.theme.devices.laptop} {
    column-count: 4;
  }
`;

const MenuButton = styled.div`
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  background: #fff;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover,
  &.active {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.error};
  }
`;

export default ({ placeholder, items, save }) => {
  const [q, setQ] = useState('');
  const loading = get(items, ['loading'], true);
  const [popularOnly, setPopularOnly] = useState(true);
  const isWider = useWider('tablet');

  const isVisible = (id) => {
    if (q === '') {
      if (popularOnly) {
        return items.byId[id].is_popular;
      }
      return true;
    }
    return items.byId[id].name.toLowerCase().indexOf(q.toLowerCase()) >= 0;
  };

  const filtered = filter(items.allIds, isVisible);
  const stat = countBy(items.byId, (item) => item.is_popular);

  useEffect(() => {
    if (!stat.false || stat.false < 10) setPopularOnly(false);
  }, [stat]);

  useEffect(() => {
    if (q !== '' && popularOnly) {
      setPopularOnly(false);
    }
  }, [q, popularOnly]);

  const onPressEnter = () => {
    if (filtered.length === 1) save(filtered[0]);
  };

  return (
    <FadeInDiv>
      <div className="mb20">
        {loading ? (
          <SkeletonLine />
        ) : (
          <Input
            autoFocus={isWider}
            autoComplete="off"
            placeholder={placeholder}
            value={q}
            onChange={(e) => setQ(trimStart(e.target.value))}
            onPressEnter={onPressEnter}
          />
        )}
      </div>

      {filtered.length > 0 ? (
        <StyledUl>
          {filtered.map((id) => (
            <li key={id}>
              <MenuButton onClick={() => save(id)}>
                {items.byId[id].name}
              </MenuButton>
            </li>
          ))}

          {popularOnly && (
            <li>
              <MenuButton
                className="text-red"
                onClick={() => {
                  setPopularOnly(false);
                }}
              >
                Show all
              </MenuButton>
            </li>
          )}
        </StyledUl>
      ) : (
        !loading && (
          <div>
            <p>
              No items found for <strong>{q}</strong>.
            </p>
            <Button viewtype="primary" onClick={() => setQ('')}>
              Clear filter
            </Button>
          </div>
        )
      )}
    </FadeInDiv>
  );
};
