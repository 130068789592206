import React from 'react';
import { NavLink } from 'react-router-dom';
import HorizontalMenu, { MenuItem } from 'components/HorizontalMenu';

export default ({ listing }) => {
  return (
    <HorizontalMenu>
      <MenuItem>
        <NavLink exact to={`${listing.myAbsoluteUrl}`}>
          Settings
        </NavLink>
      </MenuItem>
      {!listing.isArchived && (
        <>
          <MenuItem>
            <NavLink to={`${listing.myAbsoluteUrl}/details`}>Details</NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink to={`${listing.myAbsoluteUrl}/photos`}>Photos</NavLink>
          </MenuItem>
        </>
      )}
    </HorizontalMenu>
  );
};
