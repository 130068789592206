import React, { useRef } from 'react';
import { Button } from 'components/az';
import Popover from 'components/Popover';
import { useClickAway, useToggle } from 'react-use';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWider } from 'hooks';
import Modal from 'components/Modal';
import { ClearButton } from 'components/az/Button';

const Content = styled.div`
  position: relative;

  ul {
    padding: 0;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      margin-bottom: 20px;
    }
  }
`;

const ModalContent = styled.div`
  padding: 20px;

  ul {
    padding: 0;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      margin-bottom: 20px;
    }
  }
`;

const Footer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export default ({ name, storeName, itemName, filtered, content, onClear }) => {
  const ref = useRef(null);
  const [on, toggle] = useToggle(false);
  const updating = useStoreState((state) => state[storeName].updating);
  const count = useStoreState((state) => state[storeName].count);
  const isWider = useWider('tablet');

  useClickAway(ref, () => {
    toggle(false);
  });

  const button = (
    <Button onClick={toggle} active={filtered} shape="round">
      {name}
    </Button>
  );

  const body = (
    <>
      {content}
      <Footer>
        {filtered ? <ClearButton onClick={onClear} /> : <div />}
        <Button viewtype={count && 'primary'} onClick={toggle}>
          {count ? (
            <>
              Show{' '}
              {updating ? <FontAwesomeIcon icon="circle-notch" spin /> : count}{' '}
              {itemName}
              {count === 1 ? '' : 's'}
            </>
          ) : (
            `No ${itemName}s found`
          )}
        </Button>
      </Footer>
    </>
  );

  if (isWider)
    return (
      <Content ref={ref}>
        {button}
        {on && <Popover placement="bottomLeft">{body}</Popover>}
      </Content>
    );

  return (
    <>
      {button}
      {on && (
        <Modal close={toggle}>
          <ModalContent>{body}</ModalContent>
        </Modal>
      )}
    </>
  );
};
