import React from 'react';
import styled from 'styled-components';
import Helmet from 'components/Helmet';
import { Link } from 'react-router-dom';

const Container = styled.div`
  a {
    color: ${(props) => props.theme.colors.text};
  }
`;

export default ({ caruser }) => {
  return (
    <>
      <Helmet canonical>
        <title>{caruser.fullTitle}</title>
      </Helmet>
      <Container>
        <Link to="/my/garage">My garage</Link>
        <h1>{caruser.fullTitle}</h1>
      </Container>
    </>
  );
};
