import React from 'react';
import { get } from 'lodash';
import WhiteContent from 'components/WhiteContent';
import ReviewCard from 'components/Cards/Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Wrapper = styled.div`
  h2 {
    /* line-height: 1; */
    margin-bottom: 30px;

    svg {
      /* font-size: 0.8em; */
      color: ${(props) => props.theme.colors.red};
      margin-right: 0.2em;
    }
  }
`;

export default ({ business }) => {
  if (!('reviews' in business)) return null;

  const reviews = get(business, ['reviews'], []);

  if (reviews.length === 0)
    return (
      <WhiteContent>
        <h2>Reviews</h2>
        <span className="text-muted">No reviews so far.</span>
      </WhiteContent>
    );

  return (
    <Wrapper>
      <WhiteContent>
        <h2>
          <FontAwesomeIcon icon={['fas', 'star']} />
          {business.rating}{' '}
          <span className="text-secondary">
            ({business.reviews_count} review
            {business.reviews_count > 1 && 's'})
          </span>
        </h2>
        {reviews.map((review) => (
          <ReviewCard key={review.id} review={review} />
        ))}
      </WhiteContent>
    </Wrapper>
  );
};
