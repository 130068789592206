import React from 'react';
import { useStoreState } from 'easy-peasy';

export default () => {
  const count = useStoreState((state) => state.listings.count);

  if (!count) return null;

  return (
    <>
      {count} Offer{count > 1 && 's'}
    </>
  );
};
