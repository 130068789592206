import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Carousel } from 'components/az';
import moment from 'moment';
import { get } from 'lodash';
import styled, { css } from 'styled-components';
import WatchButtonLink from 'components/WatchButtonLink';

import RegionDistrict from 'components/RegionDistrict';
import DotSeparator from 'components/DotSeparator';

const Container = styled.div`
  display: grid;
  position: relative;
  grid-row-gap: 15px;
  grid-column-gap: 20px;
  align-items: start;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'title buttons'
    'images images'
    'description description'
    'location location';
`;

const OnePhotoDiv = styled.div`
  grid-area: images;

  img {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const PhotosDiv = styled.div`
  grid-area: images;
  margin: 0 -30px 0 -20px;

  img {
    max-width: calc(100% - 10px);
    border-radius: 10px;
  }

  ${(props) =>
    props.preview &&
    css`
      margin: 0;
      img {
        max-width: 100%;
      }
    `};
`;

const TitleDiv = styled.div`
  grid-area: title;
  h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 400;
  }
  span {
    font-size: 25px;
    font-weight: 500;
  }
  a {
    position: relative;
  }
`;

const LocationDiv = styled.div`
  font-size: 15px;
  grid-area: location;
  align-self: end;
  color: ${(props) => props.theme.colors.secondary};
`;

const DescriptionDiv = styled.div`
  font-size: 15px;
  grid-area: description;

  ul {
    padding: 0;
    column-count: 2;
    column-gap: 20px;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0 0 5px 0;
    }
  }
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;

  button {
    font-size: 20px;
  }
`;

export default ({ listing, preview }) => {
  const location = useLocation();
  const ref = useRef();

  const photos = listing.listingImages;

  const to = {
    pathname: listing.absoluteUrl,
    state: { background: location },
  };

  return (
    <Container>
      {!preview && <Link className="cover" to={to} />}
      {photos.length === 1 ? (
        <OnePhotoDiv>
          <figure>
            <img src={photos[0].photo800} alt={listing.shortTitle} />
          </figure>
        </OnePhotoDiv>
      ) : (
        <PhotosDiv preview={preview}>
          <Carousel
            ref={ref}
            small
            buttons={preview}
            infinite={false}
            easing="easeInOutCubic"
            centerMode={!preview}
            centerPadding="20px"
            swipeToSlide
          >
            {photos.map((photo) => (
              <div key={photo.id}>
                {preview ? (
                  <figure>
                    <img src={photo.photo800} alt={listing.shortTitle} />
                  </figure>
                ) : (
                  <Link to={to}>
                    <figure>
                      <img src={photo.photo800} alt={listing.shortTitle} />
                    </figure>
                  </Link>
                )}
              </div>
            ))}
          </Carousel>
        </PhotosDiv>
      )}
      <TitleDiv>
        <h3>{listing.fullTitle}</h3>
        <span>{listing.formattedPrice}</span>
        {listing.business && (
          <div className="mt10">
            <Link to={`/b/${listing.business.slug}`}>
              {listing.business.name}
            </Link>
          </div>
        )}
      </TitleDiv>
      <DescriptionDiv>
        <ul>
          <li>{listing.formattedMileage}</li>
          <li>{get(listing, ['car', 'nznew']) ? 'NZ new' : 'Imported'}</li>
          <li>{get(listing, ['car', 'trim', 'trim'], '')}</li>
          <li>{get(listing, ['car', 'body', 'name'], '')}</li>
          <li>{get(listing, ['car', 'colour', 'name'], '')}</li>
          <li>{get(listing, ['car', 'engine_type', 'name'], '')}</li>
          <li>{get(listing, ['car', 'drive', 'name'], '')}</li>
          <li>{get(listing, ['car', 'gearbox', 'name'], '')}</li>
        </ul>
      </DescriptionDiv>
      <LocationDiv>
        {listing.region && <RegionDistrict obj={listing} />}
        {listing.published_at && (
          <>
            <DotSeparator />
            <time
              title={
                listing.published_at &&
                moment(listing.published_at).format('LLLL')
              }
            >
              {listing.publishedFromNow}
            </time>
          </>
        )}
      </LocationDiv>
      <ButtonsDiv>{!preview && <WatchButtonLink obj={listing} />}</ButtonsDiv>
    </Container>
  );
};
