import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useLocation, Link } from 'react-router-dom';
import DotSeparator from 'components/DotSeparator';
import { get, times } from 'lodash';
import moment from 'moment';
import UserModel from 'models/user';
import CarModel from 'models/car';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  display: grid;
  align-items: center;
  gap: 20px 15px;
  grid-template-columns: 60px 1fr;
  grid-template-areas:
    'avatar details'
    'content content';
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.border.color};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

const ServiceAndTime = styled.div`
  margin-top: 2px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
`;

const AvatarDiv = styled.div`
  grid-area: avatar;

  img {
    display: block;
    width: 100%;
    border-radius: 50%;
  }
`;

const DetailsDiv = styled.div`
  grid-area: details;
`;

const ContentDiv = styled.div`
  grid-area: content;
`;

const ReviewCard = ({ review }) => {
  const categories = useStoreState((state) => state.core.services);
  const location = useLocation();

  const car = new CarModel(review.car);
  const user = new UserModel(review.user);

  const userTo = {
    pathname: user.absoluteUrl,
    state: { background: location },
  };

  return (
    <Container>
      <AvatarDiv>
        <Link to={userTo}>
          <img src={user.avatarUrl} alt={user.name} />
        </Link>
      </AvatarDiv>
      <DetailsDiv>
        <Link to={userTo}>{user.name}</Link>

        <small className="text-red ml20">
          {times(review.rating, () => (
            <FontAwesomeIcon icon={['fas', 'star']} />
          ))}
        </small>

        <ServiceAndTime>
          {get(categories, ['byId', review.service, 'name'], 'Other service')}
          <DotSeparator />
          {moment(review.created_at).format('MMMM YYYY')}
          {review.car && (
            <>
              <DotSeparator />
              {car.title}
            </>
          )}
        </ServiceAndTime>
      </DetailsDiv>
      <ContentDiv>{review.review}</ContentDiv>
    </Container>
  );
};

export default ReviewCard;
