import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { borderRadius } from 'polished';

const Wrapper = styled.div`
  display: grid;
  height: 400px;
  gap: 10px;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    'p1 p3'
    'p1 p2'
    'p1 p4'
    'p1 p5';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas:
      'p1 p2 p3'
      'p1 p4 p5';
  }
`;

const PB = styled.div`
  background: no-repeat center center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.grey5};
`;

const P1 = styled(PB)`
  ${borderRadius('left', '20px')}
  grid-area: p1;
`;
const P2 = styled(PB)`
  grid-area: p2;
`;
const P3 = styled(PB)`
  border-top-right-radius: 20px;
  grid-area: p3;
`;
const P4 = styled(PB)`
  grid-area: p4;
`;
const P5 = styled(PB)`
  border-bottom-right-radius: 20px;
  grid-area: p5;
`;

export default ({ business }) => {
  const { images } = business;
  const im1 = get(images, [0, 'photoHeight600']);
  const im2 = get(images, [1, 'photoHeight600']);
  const im3 = get(images, [2, 'photoHeight600']);
  const im4 = get(images, [3, 'photoHeight600']);
  const im5 = get(images, [4, 'photoHeight600']);
  return (
    <Wrapper>
      <P1 style={{ backgroundImage: im1 ? `url(${im1})` : undefined }} />
      <P2 style={{ backgroundImage: im2 ? `url(${im2})` : undefined }} />
      <P3 style={{ backgroundImage: im3 ? `url(${im3})` : undefined }} />
      <P4 style={{ backgroundImage: im4 ? `url(${im4})` : undefined }} />
      <P5 style={{ backgroundImage: im5 ? `url(${im5})` : undefined }} />
    </Wrapper>
  );
};
