import React, { useState, useEffect } from 'react';
import { useDebounce } from 'react-use';
import { useStoreState, useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import { Input } from 'components/az';

const Content = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export default () => {
  const mileageFrom = useStoreState((state) => state.listings.mileageFrom);
  const mileageTo = useStoreState((state) => state.listings.mileageTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const [from, setFrom] = useState(mileageFrom);
  const [to, setTo] = useState(mileageTo);

  useEffect(() => {
    setFrom(mileageFrom);
  }, [mileageFrom]);

  useEffect(() => {
    setTo(mileageTo);
  }, [mileageTo]);

  useDebounce(
    () => {
      setData({ mileageFrom: from, mileageTo: to });
    },
    200,
    [from, to]
  );

  return (
    <Content>
      <Input
        type="number"
        value={from}
        step={5000}
        min={0}
        placeholder="Mileage from"
        onChange={(e) => setFrom(e.target.value)}
      />
      <Input
        type="number"
        value={to}
        step={5000}
        min={0}
        placeholder="to, km"
        onChange={(e) => setTo(e.target.value)}
      />
    </Content>
  );
};
