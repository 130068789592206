import { useEffect } from 'react';
import qs from 'qs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffectOnce, useDeepCompareEffect } from 'react-use';
import { unset, get } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const useUrl = () => {
  const apiParamsObj = useStoreState((state) => state.services.apiParamsObj);
  const setData = useStoreActions((actions) => actions.services.setData);
  const fetch = useStoreActions((actions) => actions.services.fetch);
  const setParamsData = useStoreActions(
    (actions) => actions.services.setParamsData
  );

  const history = useHistory();
  const location = useLocation();
  const { region } = useParams();

  useEffectOnce(() => {
    unset(history.location, ['state', 'noParse']);

    return () => {
      setData({ location: null });
    };
  });

  useEffect(() => {
    const noParse = get(location, ['state', 'noParse']);
    if (!noParse) {
      const params = {
        region,
        ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      };
      setParamsData(params);
    }
  }, [location, region, setParamsData]);

  const storedLocation = useStoreState((state) => state.services.location);

  useDeepCompareEffect(() => {
    if (storedLocation) history.push(storedLocation);
  }, [storedLocation]);

  useDeepCompareEffect(() => {
    fetch();
  }, [apiParamsObj, fetch]);
};
