import React from 'react';
import SettingItem from 'components/SettingItem';
import WhiteContent from 'components/WhiteContent';
import Plate from './Plate';
import Address from './Address';
import Delete from './Delete';
import Visibility from './Visibility';
import Mileage from './Mileage';
import Price from './Price';
import Expire from './Expire';
import Comment from './Comment';

export default ({ caruser }) => (
  <WhiteContent>
    {caruser.isListing && (
      <>
        <SettingItem>
          <Price caruser={caruser} />
        </SettingItem>
        {caruser.expires_on && (
          <SettingItem>
            <Expire caruser={caruser} />
          </SettingItem>
        )}
        <SettingItem>
          <Comment caruser={caruser} />
        </SettingItem>
      </>
    )}
    <SettingItem>
      <Plate caruser={caruser} />
    </SettingItem>
    <SettingItem>
      <Mileage caruser={caruser} />
    </SettingItem>
    <SettingItem>
      <Address caruser={caruser} />
    </SettingItem>
    <SettingItem>
      <Visibility caruser={caruser} />
    </SettingItem>
    <SettingItem>
      <Delete caruser={caruser} />
    </SettingItem>
  </WhiteContent>
);
