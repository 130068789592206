import React, { useCallback } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';
import FilterButton from 'components/FilterButton';

const Make = () => {
  const value = useStoreState((state) => state.dealers.make);
  const items = useStoreState((state) => state.core.makes);
  const setData = useStoreActions((actions) => actions.dealers.setData);

  const onChange = useCallback((make) => setData({ make }), [setData]);

  return (
    <label>
      Make
      <DefSelect
        popular
        placeholder="Make"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
};

const MakeLogic = () => (
  <ul>
    <li>
      <Make />
    </li>
  </ul>
);

export default () => {
  const makeObj = useStoreState((state) => state.dealers.makeObj);
  const setData = useStoreActions((actions) => actions.dealers.setData);

  const onClear = () => {
    setData({ make: null });
  };

  return (
    <FilterButton
      name={makeObj ? makeObj.name : 'Make'}
      storeName="dealers"
      itemName="dealer"
      content={<MakeLogic />}
      filtered={makeObj !== null}
      onClear={onClear}
    />
  );
};
