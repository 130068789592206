import React from 'react';
import { SkeletonLine } from 'components/az';
import styled from 'styled-components';
import { breakpoints } from 'styles/config';
import { useMeasure } from 'react-use';
import classNames from 'classnames';

const GridDiv = styled.div`
  display: grid;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
  align-items: start;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'images images'
    'title price'
    'mileage year'
    'description description'
    'location location';

  &.expanded {
    margin: 0;
    grid-column-gap: 20px;
    grid-row-gap: 15px;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 250px 1.5fr 1.5fr auto 1fr 1fr;
    grid-template-areas:
      'images title title year mileage price'
      'images description description description . .'
      'images location location location . .';
  }
`;

const PhotosDiv = styled.div`
  grid-area: images;
  min-height: 180px;
  background: #f8f8f8;
`;

const TitleDiv = styled.div`
  grid-area: title;
`;

const YearDiv = styled.div`
  grid-area: year;
  min-width: 100px;
`;

const LocationDiv = styled.div`
  grid-area: location;
`;

const DescriptionDiv = styled.div`
  grid-area: description;

  ul {
    padding: 0;
    column-count: 2;
    column-gap: 20px;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0 0 5px 0;
    }
  }
`;

const PriceDiv = styled.div`
  grid-area: price;
`;

const MileageDiv = styled.div`
  grid-area: mileage;
`;

export default () => {
  const [ref, { width }] = useMeasure();

  return (
    <GridDiv
      ref={ref}
      className={classNames({ expanded: breakpoints.mobileL <= width })}
    >
      <PhotosDiv>
        <SkeletonLine fullHeight />
      </PhotosDiv>

      <TitleDiv>
        <SkeletonLine />
      </TitleDiv>

      <YearDiv>
        <SkeletonLine />
      </YearDiv>

      <PriceDiv>
        <SkeletonLine />
      </PriceDiv>

      <MileageDiv>
        <SkeletonLine />
      </MileageDiv>

      <DescriptionDiv>
        <ul>
          <li>
            <SkeletonLine />
          </li>
          <li>
            <SkeletonLine />
          </li>
          <li>
            <SkeletonLine />
          </li>
          <li>
            <SkeletonLine />
          </li>
          <li>
            <SkeletonLine />
          </li>
        </ul>
      </DescriptionDiv>

      <LocationDiv>
        <SkeletonLine />
      </LocationDiv>
    </GridDiv>
  );
};
