import React, { useCallback } from 'react';
import { useToggle } from 'react-use';
import { Alert, Button } from 'components/az';
import { useStoreActions } from 'easy-peasy';
import ListingDetailsForm from 'components/Listing/DetailsForm';

export default ({ listing }) => {
  const [on, toggle] = useToggle(false);
  const setListing = useStoreActions((actions) => actions.session.setListing);

  const callback = useCallback(
    (updatedListing) => {
      const { photos, caruser } = listing;
      setListing({ ...listing, ...updatedListing, photos, caruser });
      toggle();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle]
  );

  return on ? (
    <Alert type="green">
      <p>New changes were successfully saved.</p>
      <Button viewtype="green" size="small" onClick={toggle}>
        Edit again
      </Button>
    </Alert>
  ) : (
    <ListingDetailsForm listing={listing} callback={callback} />
  );
};
