import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useWider } from 'hooks';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProfileMenu from 'components/ProfileMenu';
import GreyBody from 'components/GreyBody';
import Saved from 'pages/Saved';
import Settings from 'pages/Settings';
import MyListings from 'pages/MyListings';
import Messages from 'pages/Messages';
import MyGarage from 'pages/MyGarage';
import MyBusiness from 'pages/MyBusiness';

const Wrapper = styled.div`
  max-width: 1000px;
  padding: 0 20px;
  margin: 30px auto;
  display: grid;
  gap: 30px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'content';

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 30px;
    grid-template-columns: 200px 1fr;
    grid-template-areas: 'menu content';
  }

  h1 {
    font-size: 26px;
    margin-bottom: 0;
  }
`;

const MenuDiv = styled.div`
  grid-area: menu;

  ul {
    position: sticky;
    top: ${(props) => props.theme.header.height + 30}px;
    margin-bottom: 20px;
    list-style-type: none;
    font-size: 15px;

    li {
      margin-bottom: 1px;
      a {
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 10px;
        align-items: center;
        color: ${(props) => props.theme.colors.text};
        padding: 10px 10px 10px 15px;
        border-radius: 10px;
        svg {
          color: ${(props) => props.theme.colors.grey2};
        }

        span {
          color: ${(props) => props.theme.colors.grey};
          font-size: 12px;
          background: ${(props) => props.theme.colors.grey5};
          padding: 2px 10px;
          border-radius: 14px;
        }

        &:hover,
        &.active {
          background: ${(props) => props.theme.colors.grey5};
          color: ${(props) => props.theme.colors.red};
          text-decoration: none;
          svg {
            color: ${(props) => props.theme.colors.red};
          }
        }
      }
    }
    li.separator {
      margin: 10px 15px;
      border-bottom: 1px solid ${(props) => props.theme.border.color};
    }
  }
`;

const ContentDiv = styled.div`
  grid-area: content;
`;

export default () => {
  const isAuthed = useStoreState((state) => state.session.isAuthed);
  const isWider = useWider('tablet');

  if (!isAuthed) return null;

  return (
    <Wrapper>
      <GreyBody />
      {isWider && (
        <MenuDiv>
          <ProfileMenu icons />
        </MenuDiv>
      )}
      <ContentDiv>
        <Switch>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/my/garage">
            <MyGarage />
          </Route>
          <Route path="/my/business">
            <MyBusiness />
          </Route>
          <Route path="/my/listings/">
            <MyListings />
          </Route>
          <Route path="/my">
            <Redirect to="/my/listings" />
          </Route>
          <Route path="/saved">
            <Saved />
          </Route>
          <Route path="/messages">
            <Messages />
          </Route>
        </Switch>
      </ContentDiv>
    </Wrapper>
  );
};
