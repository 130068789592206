import React, { useCallback } from 'react';
import PhotosForm from 'components/PhotosForm';
import { useStoreActions } from 'easy-peasy';
import WhiteContent from 'components/WhiteContent';

export default ({ business }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );

  const callback = useCallback((photos) => {
    setMyBusiness({ ...business, photos });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WhiteContent>
      <PhotosForm
        id={business.id}
        field={business.field}
        url={business.photosApiAbsoluteUrl}
        photos={business.photos}
        callback={callback}
      />
    </WhiteContent>
  );
};
