export const breakpoints = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
};

export const devices = {
  mobileS: `(min-width: ${breakpoints.mobileS}px)`,
  mobileM: `(min-width: ${breakpoints.mobileM}px)`,
  mobileL: `(min-width: ${breakpoints.mobileL}px)`,
  tablet: `(min-width: ${breakpoints.tablet}px)`,
  laptop: `(min-width: ${breakpoints.laptop}px)`,
  laptopL: `(min-width: ${breakpoints.laptopL}px)`,
};

export const colors = {
  primary: 'rgb(255, 59, 48)',
  secondary: 'rgb(142, 142, 147)',
  text: 'rgb(28, 28, 30)',
  error: 'rgb(255, 59, 48)',
  warning: 'rgb(255, 204, 0)',
  success: 'rgb(52, 199, 89)',

  green: 'rgb(52, 199, 89)',
  orange: 'rgb(255, 149, 0)',
  yellow: 'rgb(255, 204, 0)',
  red: 'rgb(255, 59, 48)',
  blue: 'rgb(0, 122, 255)',

  grey: 'rgb(142, 142, 146)',
  grey2: 'rgb(174, 174, 176)',
  grey3: 'rgb(199, 199, 201)',
  grey4: 'rgb(209, 209, 211)',
  grey5: 'rgb(229, 229, 231)',
  grey6: 'rgb(242, 242, 244)',

  background: 'rgb(242, 242, 244)',

  // background: 'rgb(248, 248, 250)',
};

export const darkcolors = {
  primary: 'rgb(255, 69, 58)',

  green: 'rgb(48, 209, 88)',
  orange: 'rgb(255, 159, 10)',
  yellow: 'rgb(255, 204, 0)',
  red: 'rgb(255, 69, 58)',
  blue: 'rgb(10, 132, 255)',
  grey: 'rgb(142, 142, 147)',
};

export const bgcolors = {
  green: 'rgba(52, 199, 89, .3)',
  orange: 'rgba(255, 149, 0, .3)',
  yellow: 'rgba(255, 204, 0, .3)',
  red: 'rgba(255, 59, 48, .3)',
  blue: 'rgba(0, 122, 255, .3)',
  grey: 'rgba(142, 142, 147, .3)',
};

const shadow = {
  light: '0 0 5px rgba(0, 0, 0, 0.07)',
  medium: '0 0 7px rgba(0, 0, 0, 0.1)',
  strong: '0 0 9px rgba(0, 0, 0, 0.15)',
  hover: '0 5px 10px rgba(142, 142, 146, .1)',
  hover2: '0 10px 15px rgba(142, 142, 146, .1)',
};

const border = {
  radius: '10px',
  color: 'rgb(242, 242, 244)',
};

export const theme = {
  colors,
  darkcolors,
  bgcolors,
  border,
  shadow,
  devices,
  header: {
    height: 80,
  },
};
