import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/az';
import { colors } from 'styles/config';
import { useRouteMatch } from 'react-router-dom';
import { truncate, get } from 'lodash';
import UserModel from 'models/user';
import { useToggle } from 'react-use';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import FadeInDiv from 'components/anim/FadeInDiv';
import { useWider } from 'hooks';
import WatchButton from 'components/WatchButton';
import WatchButtonLink from 'components/WatchButtonLink';
import Helmet from 'components/Helmet';

const ShareContent = styled.div`
  display: grid;
  align-items: center;
  padding: 20px;
  grid-column-gap: 30px;
  grid-template-columns: auto auto auto 1fr;
  border-top: 1px solid ${(props) => props.theme.border.color};
  background: #f5f5f5;

  .SocialMediaShareButton {
    cursor: pointer;
    outline: none;
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media ${(props) => props.theme.devices.tablet} {
    padding: 20px 30px;
  }
`;

const Content = styled.div`
  display: grid;
  align-items: center;
  padding: 20px 80px 20px 20px;
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'title title'
    'price buttons';

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr auto auto;
    grid-template-areas: 'title buttons price';
  }
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;
  white-space: nowrap;
  button {
    font-size: 20px;
  }
  button + button {
    margin-left: 10px;
  }
  @media ${(props) => props.theme.devices.tablet} {
    button {
      font-size: 16px;
    }
  }
`;

const TitleDiv = styled.div`
  grid-area: title;
  h1 {
    font-size: 18px;
    margin: 0;
    line-height: 1;
    font-weight: 400;
  }
  @media ${(props) => props.theme.devices.tablet} {
    h1 {
      font-size: 20px;
    }
  }
`;

const PriceDiv = styled.div`
  grid-area: price;
  font-size: 25px;
  font-weight: 500;

  @media ${(props) => props.theme.devices.tablet} {
    font-size: 20px;
    font-weight: 500;
  }
`;

export default ({ listing }) => {
  const [on, toggle] = useToggle();
  const { url } = useRouteMatch();
  const isWider = useWider('tablet');
  const description = truncate(get(listing, ['content'], ''), {
    length: 100,
  });

  const owner = new UserModel(listing.user);

  const fullTitle = `${listing.fullTitle} in ${listing.location}`;
  const fullUrl = `${process.env.REACT_APP_URL}${url}`;

  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>
        <link rel="canonical" href={fullUrl} />
        <meta name="description" content={description} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:url" content={fullUrl} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        {listing.mainPhoto && (
          <meta property="og:image" content={listing.mainPhoto.photo800} />
        )}
        <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Person",
            "name": "${owner.name}",
            "makesOffer": {
              "@type": "Offer",
              "priceSpecification": {
                "@type": "UnitPriceSpecification",
                "priceCurrency": "NZD",
                "price": "${listing.price}"
              },
              "itemOffered": {
                "@type": "Car",
                "name": "${listing.fullTitle}",
                "brand": "${get(listing, ['car', 'make', 'name'], '')}",
                "model": "${get(listing, ['car', 'model', 'name'], '')}",
                "description": "${description}",
                "productionDate": "${get(listing, ['car', 'year'], '')}",
                "mileageFromOdometer": {
                  "@type": "QuantitativeValue",
                  "value": "${listing.mileage}",
                  "unitCode": "KMT"
                },
                "vehicleConfiguration": "${get(
                  listing,
                  ['car', 'trim', 'trim'],
                  ''
                )}",
                "vehicleTransmission": "${get(
                  listing,
                  ['car', 'gearbox', 'name'],
                  ''
                )}",
                "fuelType": "${get(
                  listing,
                  ['car', 'engine_type', 'name'],
                  ''
                )}",
                "bodyType": "${get(listing, ['car', 'body', 'name'], '')}",
                "image": "${fullUrl}",
                "color": "${get(listing, ['car', 'colour', 'name'], '')}"
              }
            }
          }`}
        </script>
      </Helmet>
      <Content>
        <TitleDiv>
          <h1>{listing.fullTitle}</h1>
        </TitleDiv>

        <ButtonsDiv>
          {isWider ? (
            <>
              <WatchButton obj={listing} />
              <Button onClick={toggle}>
                <FontAwesomeIcon icon="share" /> Share
              </Button>
            </>
          ) : (
            <WatchButtonLink obj={listing} />
          )}
        </ButtonsDiv>

        <PriceDiv>{listing.formattedPrice}</PriceDiv>
      </Content>

      {on && (
        <FadeInDiv>
          <ShareContent>
            <FacebookShareButton
              url={fullUrl}
              hashtag="#autozealand"
              quote={description}
            >
              <FontAwesomeIcon
                style={{ color: 'rgb(66,103,178)' }}
                icon={['fab', 'facebook-f']}
                fixedWidth
              />{' '}
              Facebook
            </FacebookShareButton>
            <TwitterShareButton
              url={fullUrl}
              title={`Check out this ${listing.shortTitle} on AutoZealand!`}
              hashtags={[
                'autozealand',
                get(listing, ['car', 'make', 'name'], ''),
                get(listing, ['car', 'model', 'name'], ''),
              ]}
            >
              <FontAwesomeIcon
                style={{ color: 'rgb(29,161,242)' }}
                icon={['fab', 'twitter']}
                fixedWidth
              />{' '}
              Twitter
            </TwitterShareButton>
            <EmailShareButton
              url={fullUrl}
              subject={fullTitle}
              body="Interesting offer on AutoZealand"
            >
              <FontAwesomeIcon
                style={{ color: colors.secondary }}
                icon={['far', 'envelope']}
                fixedWidth
              />{' '}
              Email
            </EmailShareButton>
          </ShareContent>
        </FadeInDiv>
      )}
    </>
  );
};
