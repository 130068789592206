/* eslint-disable no-param-reassign */
import { action, computed, thunk } from 'easy-peasy';
import { get, keys, pick, forEach, isUndefined } from 'lodash';
import qs from 'qs';
import BusinessModel from 'models/business';
import { api } from '../api';

const matrix = {
  page: 'page',
  page_size: 'page_size',
};

const defSearchParams = {};

const defParams = {
  page: 1,
  page_size: 10,

  ...defSearchParams,
};

export default {
  ...defParams,
  records: [],
  updating: false,
  location: null,
  count: 0,

  apiParamsObj: computed((state) => {
    const toReturn = {};
    forEach(matrix, (value, key) => {
      toReturn[value] = state[key];
    });
    return toReturn;
  }),
  searchParamsObj: computed((state) => pick(state, keys(defSearchParams))),

  // use it when you need to set anything
  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = isUndefined(value) ? null : value;
    });
  }),

  // use it when other search (!) parameters should be reset
  setParamsData: action((state, payload) => {
    forEach(defParams, (value, key) => {
      state[key] = get(payload, [key], value);
    });
  }),

  buildLocation: action((state, _) => {
    const pathname = '/my/business';
    const searchParamsObj = pick(state, keys(defSearchParams));
    const search = qs.stringify(searchParamsObj, {
      skipNulls: true,
      addQueryPrefix: true,
    });
    state.location = { pathname, search, state: { noParse: true } };
  }),

  fetch: thunk(async (actions, _, { getState, getStoreActions }) => {
    actions.setData({ updating: true });
    // actions.buildLocation();
    const state = getState();
    const data = await api.getData('/api/my/businesses/', state.apiParamsObj);

    const { setMyBusiness } = getStoreActions().session;
    if (data.results) {
      data.results.forEach((item) => setMyBusiness(item));
    }

    actions.setData({
      records: data.results
        ? data.results.map((b) => new BusinessModel(b))
        : [],
      count: data.count,
      updating: false,
    });
  }),
};
