import { trim, get as _get, filter } from 'lodash';
import moment from 'moment';
import {
  buildImageUrl,
  currencyFormatter,
  mileageFormatter,
  nl2p,
} from 'utility';
import placholderImg from 'assets/img/placeholder.jpg';
import BaseModel from '.';
import UserModel from './user';

export default class CarUserModel extends BaseModel {
  constructor(...args) {
    super(...args);
    this.field = 'car';
    this.photosApiAbsoluteUrl = '/api/car-photos/';
  }

  get getUser() {
    if (!this.user) return null;
    return new UserModel(this.user);
  }

  get images() {
    if (!this.photos) return [];

    return this.photos.map((item) => {
      const edits400 = {
        resize: {
          width: 400,
          height: 300,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      const edits800 = {
        resize: {
          width: 800,
          height: 600,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      const edits1600 = {
        resize: {
          width: 1600,
          height: 1200,
          fit: 'contain',
          background: {
            r: 0,
            g: 0,
            b: 0,
            alpha: 1,
          },
        },
        rotate: null,
      };
      return {
        ...item,
        photo400: buildImageUrl(item.photo, edits400),
        photo800: buildImageUrl(item.photo, edits800),
        photo1600: buildImageUrl(item.photo, edits1600),
      };
    });
  }

  get listingImages() {
    const images = filter(this.images, 'is_listing');
    if (images.length === 0) return [{ id: 1, photo800: placholderImg }];
    return images;
  }

  get mainPhoto() {
    if (this.photos && this.photos.length > 0) return this.photos[0];
    return null;
  }

  get inGarageSince() {
    return this.created_at ? moment(this.created_at).format('LL') : 'Unknown';
  }

  get inGarageFromNow() {
    return this.created_at ? moment(this.created_at).fromNow(true) : '';
  }

  get shortTitle() {
    const m = _get(this, ['car', 'model', 'name'], '');
    const g = _get(this, ['car', 'generation', 'name'], '');

    let title = `${_get(this, ['car', 'make', 'name'], '')} ${m}`;
    if (m !== g) title = `${title} ${g}`;

    return trim(title);
  }

  get fullTitle() {
    const y = _get(this, ['car', 'year'], '');
    if (y) return `${this.shortTitle}, ${y}`;
    return this.shortTitle;
  }

  get name() {
    return this.fullTitle;
  }

  get wofMoment() {
    if (!this.wof) return null;
    return moment(this.wof);
  }

  get regoMoment() {
    if (!this.rego) return null;
    return moment(this.rego);
  }

  get servicedueMoment() {
    if (!this.servicedue) return null;
    return moment(this.servicedue);
  }

  get absoluteUrl() {
    return `/a/${this.slug}`;
  }

  get apiAbsoluteUrl() {
    return `/api/listings/${this.slug}/`;
  }

  get myAbsoluteUrl() {
    return `/my/garage/${this.slug}`;
  }

  get myApiAbsoluteUrl() {
    return `/api/my/cars/${this.slug}/`;
  }

  get formattedPrice() {
    return currencyFormatter.format(this.price);
  }

  get formattedMileage() {
    return `${mileageFormatter.format(this.mileage)} km`;
  }

  get formattedContent() {
    return nl2p(this.content);
  }

  get isNone() {
    return this.status === 'NONE';
  }

  get isPublished() {
    return this.status === 'PUBLISHED';
  }

  get isPaused() {
    return this.status === 'PAUSED';
  }

  get isDraft() {
    return this.status === 'DRAFT';
  }

  get isSold() {
    return this.status === 'SOLD';
  }

  get isArchived() {
    return this.status === 'ARCHIVED';
  }

  get isListing() {
    return this.isDraft || this.isPaused || this.isPublished;
  }

  isOwner(user) {
    return (
      user && user.id && this.user && this.user.id && this.user.id === user.id
    );
  }

  get publishedFromNow() {
    return this.published_at ? moment(this.published_at).fromNow() : 'Unknown';
  }

  get location() {
    const region = _get(this, ['region', 'name'], '');
    if (!region) return '';
    const district = _get(this, ['district', 'name'], '');
    if (!district) return region;
    return `${region}, ${district}`;
  }

  get businessLogo400() {
    if (!this.business || !this.business.logo) return null;

    const edits = {
      resize: {
        width: 400,
        height: 400,
        fit: 'contain',
        background: {
          r: 255,
          g: 255,
          b: 255,
          alpha: 1,
        },
      },
      rotate: null,
    };
    return buildImageUrl(this.business.logo, edits);
  }
}
