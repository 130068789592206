import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWider } from 'hooks';

const Map = styled.div`
  height: 500px;
`;

const Header = styled.div`
  margin-bottom: 20px;
`;

const { mapkit } = window;

export default ({ business }) => {
  const { location, name, address } = business;
  const coordinates = location ? location.coordinates : null;
  const isWider = useWider('tablet');
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    const newMap = new mapkit.Map(ref.current, {
      colorScheme: mapkit.Map.ColorSchemes.Light,
      mapType: mapkit.Map.MapTypes.MutedStandart,
    });
    setMap(newMap);
    return () => {
      newMap.destroy();
    };
  }, []);

  useEffect(() => {
    if (map && coordinates) {
      const coordinate = new mapkit.Coordinate(coordinates[0], coordinates[1]);
      const annotation = new mapkit.MarkerAnnotation(coordinate, {
        color: colors.red,
        title: name,
      });
      map.center = coordinate;
      map.cameraDistance = 5000;
      map.annotations = [annotation];
      map.isScrollEnabled = isWider;
    }
  }, [coordinates, name, map, isWider]);

  if (!(address || coordinates)) return null;

  return (
    <>
      <Header>
        <h2>Location</h2>

        {address && (
          <>
            <FontAwesomeIcon icon="map-pin" className="mr10" />
            {address}
          </>
        )}
      </Header>

      <Map ref={ref} />
    </>
  );
};
