import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { get, capitalize } from 'lodash';
import { formatPhoneNumber } from 'react-phone-number-input';
import WatchButton from 'components/WatchButton';
import RegionDistrict from 'components/RegionDistrict';
import { Button } from 'components/az';
import { Link } from 'react-router-dom';
import DotSeparator from 'components/DotSeparator';
import WhiteContent from 'components/WhiteContent';
import ReviewStar from 'components/ReviewStar';

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'logo'
    'header'
    'buttons'
    'details';

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 120px;
    grid-template-areas:
      'header logo'
      'buttons logo'
      'details logo';
  }
`;

const HeaderDiv = styled.div`
  grid-area: header;
  h1 {
    margin-bottom: 5px;
  }
`;

const SubRow = styled.div`
  font-size: 15px;
  /* color: ${(props) => props.theme.colors.secondary}; */
`;

const LogoDiv = styled.div`
  grid-area: logo;

  img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;
`;

const DetailsDiv = styled.div`
  grid-area: details;
`;

const Brands = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.secondary};

  strong {
    color: ${(props) => props.theme.colors.text};
  }
`;

const Slogan = styled.div`
  margin-bottom: 20px;
`;

const ContactUl = styled.ul`
  list-style-type: none;
  li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
  }
`;

export default ({ business }) => {
  const user = useStoreState((state) => state.session.user);
  const makes = useStoreState((state) => state.core.makes);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (makes && business) {
      const items = get(business, ['makes'], []);
      setBrands(
        items.map((id) => get(makes, ['byId', id, 'name'], '...')).sort()
      );
    }
  }, [business, makes]);

  return (
    <WhiteContent>
      <Wrapper>
        <HeaderDiv>
          <Brands>
            <strong className="mr10">
              {business.official
                ? `Official ${business.subtype.toLowerCase()}`
                : capitalize(business.subtype)}
            </strong>
            {brands.join(' · ')}
          </Brands>

          <h1>{business.name}</h1>
          <SubRow>
            {business.isService && <ReviewStar obj={business} />}
            {business.region && (
              <>
                {business.isService && <DotSeparator />}
                <span title={business.address}>
                  <RegionDistrict obj={business} showIcon />
                </span>
              </>
            )}
          </SubRow>
        </HeaderDiv>
        <LogoDiv>
          {business.logo && <img src={business.logo400} alt={business.name} />}
        </LogoDiv>
        <ButtonsDiv>
          <WatchButton obj={business} />
          {business.isOwner(user) && (
            <Button
              className="ml10"
              viewtype="yellow"
              as={Link}
              icon="pencil-alt"
              to={business.myAbsoluteUrl}
            >
              Edit
            </Button>
          )}
        </ButtonsDiv>
        <DetailsDiv>
          {business.slogan && (
            <Slogan className="mb20">{business.slogan}</Slogan>
          )}

          <ContactUl>
            {business.phone && (
              <li>
                <a href={`tel:${business.phone}`}>
                  {formatPhoneNumber(business.phone) || business.phone}
                </a>
              </li>
            )}
            {business.email && (
              <li>
                <a href={`mailto:${business.email}`}>
                  {business.email.toLowerCase()}
                </a>
              </li>
            )}
            {business.website && (
              <li>
                <a
                  href={business.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {business.website}
                </a>
              </li>
            )}
          </ContactUl>
        </DetailsDiv>
      </Wrapper>
    </WhiteContent>
  );
};
