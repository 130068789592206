import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import DefSelect from 'components/form/DefSelect';

export default () => {
  const value = useStoreState((state) => state.listings.engineType);
  const items = useStoreState((state) => state.core.engineTypes);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const onChange = (engineType) => setData({ engineType });

  return (
    <label>
      Engine
      <DefSelect
        placeholder="Engine"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
};
