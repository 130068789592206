import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Carousel } from 'components/az';
import moment from 'moment';
import { get } from 'lodash';
import styled from 'styled-components';
import WatchButtonLink from 'components/WatchButtonLink';
import RegionDistrict from 'components/RegionDistrict';
import DotSeparator from 'components/DotSeparator';

const Container = styled.div`
  display: grid;
  position: relative;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 250px 3fr 1fr 1fr;
  grid-template-areas:
    'images title year price'
    'images description business business'
    'images location location buttons';
`;

const PhotosDiv = styled.div`
  grid-area: images;

  .slick-list {
    border-radius: 10px;
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }
`;

const TitleDiv = styled.div`
  grid-area: title;
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
`;

const YearDiv = styled.div`
  grid-area: year;
  font-size: 18px;
`;

const PriceDiv = styled.div`
  font-weight: 500;
  font-size: 18px;
  grid-area: price;
  justify-self: end;
  text-align: right;
`;

const LocationDiv = styled.div`
  font-size: 15px;
  grid-area: location;
  align-self: end;
  color: ${(props) => props.theme.colors.secondary};
`;

const DescriptionDiv = styled.div`
  font-size: 14px;
  grid-area: description;

  ul {
    padding: 0;
    column-count: 2;
    column-gap: 20px;

    li {
      display: inline-block;
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0 0 5px 0;
    }
  }
`;

const BusinessDiv = styled.div`
  grid-area: business;
  font-size: 15px;
  a {
    position: relative;
  }
`;

const ButtonsDiv = styled.div`
  grid-area: buttons;
  justify-self: end;
`;

export default ({ listing }) => {
  const location = useLocation();
  const ref = useRef();

  const photos = listing.listingImages;

  const to = {
    pathname: listing.absoluteUrl,
    state: { background: location },
  };

  return (
    <Container>
      <Link className="cover" to={to} />
      <PhotosDiv>
        <Carousel
          ref={ref}
          small
          buttons={photos.length > 1}
          // lazyLoad="ondemand"
          easing="easeInOutCubic"
        >
          {photos.map((photo) => (
            <div key={photo.id}>
              <Link to={to}>
                <figure>
                  <img src={photo.photo800} alt={listing.shortTitle} />
                </figure>
              </Link>
            </div>
          ))}
        </Carousel>
      </PhotosDiv>
      <TitleDiv>
        <h3>
          <Link to={to}>{listing.shortTitle}</Link>
        </h3>
      </TitleDiv>
      <PriceDiv>{listing.formattedPrice}</PriceDiv>
      <YearDiv>{get(listing, ['car', 'year'], '')}</YearDiv>
      <DescriptionDiv>
        <ul>
          <li>{listing.formattedMileage}</li>
          <li>{get(listing, ['car', 'nznew']) ? 'NZ new' : 'Imported'}</li>
          <li>{get(listing, ['car', 'trim', 'trim'], '')}</li>
          <li>{get(listing, ['car', 'body', 'name'], '')}</li>
          <li>{get(listing, ['car', 'colour', 'name'], '')}</li>
          <li>{get(listing, ['car', 'engine_type', 'name'], '')}</li>
          <li>{get(listing, ['car', 'drive', 'name'], '')}</li>
          <li>{get(listing, ['car', 'gearbox', 'name'], '')}</li>
        </ul>
      </DescriptionDiv>
      <BusinessDiv>
        {listing.business && (
          <Link to={`/b/${listing.business.slug}`}>
            {listing.business.name}
          </Link>
        )}
      </BusinessDiv>
      <LocationDiv>
        {listing.region && <RegionDistrict obj={listing} />}
        {listing.published_at && (
          <>
            <DotSeparator />
            <time
              title={
                listing.published_at &&
                moment(listing.published_at).format('LLLL')
              }
            >
              {listing.publishedFromNow}
            </time>
          </>
        )}
      </LocationDiv>
      <ButtonsDiv>
        <WatchButtonLink obj={listing} />
      </ButtonsDiv>
    </Container>
  );
};
