import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';
import { ApiContext } from 'context/ApiContext';
import UserModel from 'models/user';
import CarUserModel from 'models/caruser';
import Helmet from 'components/Helmet';

const Container = styled.div`
  padding: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'content avatar';

  @media ${(props) => props.theme.devices.tablet} {
    padding: 30px;
  }
`;

const ContentDiv = styled.div`
  grid-area: content;

  ul {
    margin-top: 30px;
    list-style-type: none;
    li {
      margin-bottom: 5px;
    }
  }
`;

const AvatarDiv = styled.div`
  grid-area: avatar;

  img {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }

  @media ${(props) => props.theme.devices.tablet} {
    img {
      width: 120px;
      height: 120px;
      border-radius: 120px;
    }
  }
`;

export default () => {
  const { username } = useParams();
  const api = useContext(ApiContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let didCancel = false;

    async function fetchData() {
      const data = await api.getData(`/api/users/${username}/`, {});
      if (!didCancel) {
        setUser(new UserModel(data));
      }
    }

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [api, username]);

  if (!user) return <Loading padding={20} />;

  const description = `AutoZealand member since ${user.member_since}`;

  return (
    <Container>
      <Helmet canonical>
        <title>{user.name}</title>
        <meta name="description" content={description} />
      </Helmet>
      <ContentDiv>
        <h2>{user.name}</h2>
        AutoZealand member since {user.member_since}
        {user.public_cars.length > 0 && (
          <ul>
            <li className="text-secondary">Drives</li>
            {user.public_cars.map((item) => {
              const c = new CarUserModel(item);
              return <li key={c.id}>&mdash; {c.fullTitle}</li>;
            })}
          </ul>
        )}
      </ContentDiv>
      <AvatarDiv>
        <img src={user.avatarUrl} alt={user.name} />
      </AvatarDiv>
    </Container>
  );
};
