import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Upload from 'components/Upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, concat } from 'lodash';
import { Button } from 'components/az';
import { animated } from 'react-spring';
import { Transition } from 'react-spring/renderprops';
import { buildImageUrl } from 'utility';
import { api } from 'api';
import { useUpdate } from 'react-use';
import { toast } from 'react-toastify';

const Wrapper = styled.div``;

const transform = {
  resize: {
    width: 800,
    height: 600,
    fit: 'contain',
    background: {
      r: 0,
      g: 0,
      b: 0,
      alpha: 1,
    },
  },
  rotate: null,
};

const UploadSection = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
  margin-bottom: 20px;
  align-items: center;
`;

const IndicatorSection = styled.div``;

const PhotoGrid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const ImageBlock = styled(animated.div)`
  position: relative;

  img {
    display: block;
    max-width: 100%;
    border-radius: 10px;
  }
`;

const StyledDelButton = styled.button`
  position: absolute;
  font-size: 20px;
  color: #eee;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  outline: none;
  bottom: 10px;
  right: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

export default ({ callback, raw, id, url, field, photos: initPhotos }) => {
  const update = useUpdate();
  const [count, setCount] = useState(0);
  const [photos, setPhotos] = useState(initPhotos || []);

  useEffect(() => {
    callback && callback(photos);
  }, [callback, photos]);

  const deletePhoto = async (photo) => {
    setPhotos((state) => filter(state, (x) => x.id !== photo.id));
    await api.deleteData(`${url}${photo.id}/`);
  };

  const uploadProps = {
    name: 'photo',
    accept: '.jpg, .jpeg, .png',
    multiple: true,
    onStart(file) {
      setCount((state) => state + 1);
    },
    onSuccess(response, file) {
      setCount((state) => state - 1);
      setPhotos((state) => concat(state, response));
    },
    onError(err) {
      setCount((state) => state - 1);
      toast.error('An error happened while uploading one of the photos.');
    },
    onProgress({ percent }, file) {
      // nothing so far
    },
    customRequest({ file, filename, onError, onProgress, onSuccess }) {
      const formData = new FormData();
      formData.append(filename, file);
      formData.append(field, id);

      api.axios
        .post(url, formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            );
            update();
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(onError);

      return {
        abort() {
          // console.log('upload progress is aborted.');
        },
      };
    },
  };

  return (
    <Wrapper>
      <UploadSection>
        <Upload {...uploadProps}>
          <Button icon="plus-circle">Upload photos</Button>
        </Upload>
        <IndicatorSection>
          {count > 0 && (
            <>
              <FontAwesomeIcon icon="circle-notch" spin /> Uploading {count}{' '}
              photo{count > 1 && 's'}
            </>
          )}
        </IndicatorSection>
      </UploadSection>
      <PhotoGrid>
        <Transition
          items={photos}
          keys={(item) => item.id}
          trail={50}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(item) => (props) => (
            <ImageBlock style={props}>
              <img
                src={raw ? item.photo : buildImageUrl(item.photo, transform)}
                alt={item.id}
              />
              <StyledDelButton
                onClick={() => deletePhoto(item)}
                title="Delete photo"
              >
                <FontAwesomeIcon icon="trash-alt" />
              </StyledDelButton>
            </ImageBlock>
          )}
        </Transition>
      </PhotoGrid>
    </Wrapper>
  );
};
