import React from 'react';
import FilterButton from 'components/FilterButton';

export default ({ name, content, filtered, onClear }) => (
  <FilterButton
    storeName="listings"
    itemName="offer"
    name={name}
    content={content}
    filtered={filtered}
    onClear={onClear}
  />
);
