import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { get, capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpring, animated } from 'react-spring';
import { Button, Alert } from 'components/az';
import { formatPhoneNumber } from 'react-phone-number-input';
import RegionDistrict from 'components/RegionDistrict';
import WatchButtonLink from 'components/WatchButtonLink';
import PendingAlert from './PendingAlert';
import ReviewStar from 'components/ReviewStar';

const Wrapper = styled(animated.div)`
  background: #fff;
  border-radius: 20px;
  margin: 0 -20px 20px;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${(props) => props.theme.shadow.hover};
    /* transform: translateY(-1px); */
  }

  @media ${(props) => props.theme.devices.tablet} {
    margin: 0 0 20px;
  }
`;

const ExtraContent = styled.div`
  font-size: 14px;
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.border.color};
`;

const Content = styled.div`
  padding: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 80px 1fr auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header header header'
    'logo details details'
    'logo rating heart';
  gap: 10px 20px;
  font-size: 16px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 100px 1fr;
  }
`;

const HeaderBlock = styled.div`
  grid-area: header;

  h2 {
    font-size: 22px;
    margin-bottom: 0;

    a {
      position: relative;
      pointer-events: auto;
    }
  }
`;

const LogoBlock = styled.div`
  grid-area: logo;

  img {
    display: block;
    width: 100%;
    border-radius: 10px;
  }

  div {
    height: 80px;
    border: 1px solid ${(props) => props.theme.border.color};
    border-radius: 10px;
    line-height: 80px;
    text-align: center;
    font-size: 40px;

    color: ${(props) => props.theme.border.color};
  }

  @media ${(props) => props.theme.devices.tablet} {
    div {
      height: 100px;
      line-height: 100px;
    }
  }
`;

const DetailsBlock = styled.div`
  font-size: 15px;
  grid-area: details;
  a {
    position: relative;
    pointer-events: auto;
  }
`;

const RatingBlock = styled.div`
  font-size: 15px;
  grid-area: rating;
`;

const HeartBlock = styled.div`
  grid-area: heart;
`;

const Brands = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};

  strong {
    color: ${(props) => props.theme.colors.text};
  }
`;

const Extra = ({ business }) => {
  return (
    <ExtraContent>
      <Button
        viewtype="yellow"
        size="small"
        as={Link}
        to={business.myAbsoluteUrl}
      >
        Settings
      </Button>
    </ExtraContent>
  );
};

const Notifications = ({ business }) => {
  return (
    <>
      {business.isDraft && (
        <div className="mb20 px20">
          <Alert type="yellow">
            The business is in the <strong>DRAFT</strong> status. Check all the
            settings and apply for moderation.
          </Alert>
        </div>
      )}
      {business.isPending && (
        <div className="mb20 px20">
          <PendingAlert />
        </div>
      )}
    </>
  );
};

const BusinessCard = ({ business }) => {
  const user = useStoreState((state) => state.session.user);

  const { opacity } = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  });

  const makes = useStoreState((state) => state.core.makes);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (makes && business) {
      const items = get(business, ['makes'], []);
      setBrands(
        items.map((id) => get(makes, ['byId', id, 'name'], '...')).sort()
      );
    }
  }, [business, makes]);

  const to = {
    pathname: business.absoluteUrl,
  };

  return (
    <Wrapper
      style={{
        opacity,
      }}
    >
      <Content>
        <Link className="cover" to={to} />
        <HeaderBlock>
          <Brands>
            <strong className="mr10">
              {business.official
                ? `Official ${business.subtype.toLowerCase()}`
                : capitalize(business.subtype)}
            </strong>
            {brands && brands.join(' · ')}
          </Brands>
          <h2>
            <Link to={to}>{business.name}</Link>
          </h2>
        </HeaderBlock>
        <LogoBlock>
          {business.logo ? (
            <img src={business.logo400} alt={business.name} />
          ) : (
            <div>
              <FontAwesomeIcon icon="camera" />
            </div>
          )}
        </LogoBlock>
        <DetailsBlock>
          {business.region && (
            <p title={business.address}>
              <RegionDistrict obj={business} showIcon />
            </p>
          )}
          <p>
            {business.phone && (
              <a href={`tel:${business.phone}`}>
                {formatPhoneNumber(business.phone) || business.phone}
              </a>
            )}

            {business.email && (
              <>
                {business.phone && ' · '}
                <a href={`mailto:${business.email.toLowerCase()}`}>
                  {business.email}
                </a>
              </>
            )}
            {business.website && (
              <>
                {' · '}
                <a
                  href={business.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Website
                </a>
              </>
            )}
          </p>
        </DetailsBlock>
        <RatingBlock>
          {business.isService && <ReviewStar obj={business} />}
          {business.isDealer && (
            <>
              {business.cars_count} car{business.cars_count !== 1 && 's'} for
              sale
            </>
          )}
        </RatingBlock>
        <HeartBlock>
          <WatchButtonLink obj={business} />
        </HeartBlock>
      </Content>
      {business.isOwner(user) && (
        <>
          <Notifications business={business} />
          <Extra business={business} />
        </>
      )}
    </Wrapper>
  );
};

export default BusinessCard;
