import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
  faLocationArrow,
  faCircle,
  faTimes,
  faTimesCircle,
  faArrowLeft,
  faSlidersH,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faBars,
  faShare,
  faCar,
  faHome,
  faEllipsisH,
  faHeart as faSolidHeart,
  faCamera,
  faMapPin,
  faSignOutAlt,
  faRedo,
  faParking,
  faWarehouse,
  faCertificate,
  faTrashAlt,
  faExternalLinkAlt,
  faPencilAlt,
  faPlusCircle,
  faCogs,
  faComments,
  faKiwiBird,
  faThList,
  faEject,
  faOilCan,
  faDigitalTachograph,
  faUser as faSolidUser,
  faStar as faSolidStar,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBookmark,
  faBell,
  faUser,
  faUserCircle,
  faHeart,
  faEnvelope,
  faCompass,
  faAddressCard,
  faStar,
  faQuestionCircle,
  faEye,
  faPaperPlane,
  faSave,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faTwitter,
  faApple,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faCircleNotch,
  faLocationArrow,
  faCircle,
  faTimes,
  faTimesCircle,
  faArrowLeft,
  faSlidersH,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faBars,
  faShare,
  faCar,
  faHome,
  faEllipsisH,
  faSolidHeart,
  faCamera,
  faMapPin,
  faSignOutAlt,
  faRedo,
  faParking,
  faWarehouse,
  faCertificate,
  faTrashAlt,
  faExternalLinkAlt,
  faPencilAlt,
  faPlusCircle,
  faCogs,
  faComments,
  faKiwiBird,
  faThList,
  faEject,
  faOilCan,
  faDigitalTachograph,
  faSolidUser,
  faSolidStar,

  faBookmark,
  faBell,
  faUser,
  faUserCircle,
  faHeart,
  faEnvelope,
  faCompass,
  faAddressCard,
  faStar,
  faQuestionCircle,
  faEye,
  faPaperPlane,
  faSave,

  faFacebookF,
  faTwitter,
  faApple
);
