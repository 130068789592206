import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { darken, borderRadius } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tag from 'components/az/Tag';

const Wrapper = styled.div`
  position: relative;
  min-height: 300px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'photo' 'body';
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  box-shadow: ${(props) => props.theme.shadow.hover};

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${(props) => props.theme.shadow.hover2};
  }
`;

const PhotoDiv = styled.div`
  padding: 20px;
  background: linear-gradient(
    135deg,
    ${(props) => props.carcolour} 10%,
    ${(props) => darken(0.4, props.carcolour)} 100%
  );
  ${(props) =>
    props.photo &&
    css`
      background: url(${props.photo}) no-repeat center center;
      background-size: cover;
    `};

  grid-area: photo;
  ${borderRadius('top', '20px')}
  text-align: right;

  span {
    background: rgba(255, 255, 255, 0.9);
    color: ${(props) => props.theme.colors.text};
    display: inline-block;
    border-radius: 10px;
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: 600;
  }
`;

const BodyDiv = styled.div`
  grid-area: body;
  display: grid;
  gap: 5px;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-areas: 'name' 'text' 'tags';
  background: #fff;
  padding: 20px;
  ${borderRadius('bottom', '20px')}
`;

const NameDiv = styled.div`
  grid-area: name;

  h3 {
    margin-bottom: 0;
  }
`;

const TextDiv = styled.div`
  grid-area: text;
  color: ${(props) => props.theme.colors.secondary};
  font-size: 15px;
`;

const TagsDiv = styled.div`
  padding-top: 5px;
  grid-area: tags;
`;

const CarUserCard = ({ caruser }) => {
  return (
    <Wrapper>
      <Link className="cover" to={caruser.myAbsoluteUrl} />
      <PhotoDiv
        carcolour={caruser.car.colour.hexcode}
        photo={
          caruser.images &&
          caruser.images.length > 0 &&
          caruser.images[0].photo800
        }
      >
        {caruser.plate && (
          <span>
            <strong>{caruser.plate}</strong>
          </span>
        )}
      </PhotoDiv>
      <BodyDiv>
        <NameDiv>
          <h3>{caruser.fullTitle}</h3>
        </NameDiv>
        <TextDiv>
          <FontAwesomeIcon icon="parking" className="mr05em text-red" />
          {caruser.inGarageSince} ({caruser.inGarageFromNow})
        </TextDiv>
        <TagsDiv>
          {caruser.business && (
            <Tag>
              <FontAwesomeIcon icon="kiwi-bird" />
            </Tag>
          )}
          {caruser.is_public && (
            <Tag>
              <FontAwesomeIcon
                icon={['far', 'address-card']}
                flip="horizontal"
                fixedWidth
              />
            </Tag>
          )}
          {caruser.isDraft && <Tag color="yellow">Draft</Tag>}
          {caruser.isPaused && <Tag color="yellow">Paused</Tag>}
          {caruser.isPublished && <Tag color="green">Published</Tag>}
          {caruser.isSold && <Tag color="grey">Sold</Tag>}
        </TagsDiv>
      </BodyDiv>
    </Wrapper>
  );
};

export default CarUserCard;
