import React, { useRef, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Tooltip from 'components/az/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { useSpring } from 'react-spring';
import Loading from 'components/Loading';
import { darken } from 'polished';

const Wrapper = styled.div`
  ul {
    list-style-type: none;
    li {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 15px;
    }
  }
`;

const StyledButton = styled.button`
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 40px;
  border: 1px solid;
  font-size: 16px;
  color: white;
  outline: none;
  cursor: pointer;

  ${(props) =>
    props.bg &&
    css`
      background: linear-gradient(
        135deg,
        ${props.bg} 30%,
        ${darken(0.2, props.bg)} 100%
      );
      border: none;
    `}

  i {
    color: #d9d9d9;
  }
`;

export default () => {
  const ref = useRef();
  const colour = useStoreState((store) => store.newcar.colour);
  const colours = useStoreState((store) => store.core.colours);
  const setData = useStoreActions((actions) => actions.newcar.setData);

  const save = (value) => {
    setData({ colour: value });
  };

  const [, setY] = useSpring(() => ({ y: 0 }));
  useEffect(() => {
    setY({
      y: ref.current.offsetTop,
      reset: true,
      from: { y: window.scrollY },
      onFrame: (props) => window.scroll(0, props.y),
    });
  }, [setY]);

  return (
    <Wrapper ref={ref}>
      <h3>Colour</h3>

      {colours.length === 0 ? (
        <Loading />
      ) : (
        <ul>
          {colours.allIds.map((id) => (
            <li key={id}>
              <Tooltip title={colours.byId[id].name}>
                <StyledButton
                  bg={colours.byId[id].hexcode}
                  onClick={() => save(id)}
                >
                  {colour === id ? (
                    <FontAwesomeIcon icon="circle" />
                  ) : (
                    <>&nbsp;</>
                  )}
                </StyledButton>
              </Tooltip>
            </li>
          ))}
        </ul>
      )}
    </Wrapper>
  );
};
