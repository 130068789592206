import React from 'react';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import ListingOwnerButtons from 'components/Listing/OwnerButtons';

const Wrapper = styled.div`
  background: #f5f5f5;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  padding: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 20px 30px;
  }
`;

const Content = styled.div`
  font-size: 14px;
`;

export default ({ listing }) => {
  const user = useStoreState((state) => state.session.user);

  if (!listing.isOwner(user)) return null;

  return (
    <Wrapper>
      <Content>
        {listing.expires_on && (
          <p>
            Listing expires on {moment(listing.expires_on).format('LL')} (
            {moment(listing.expires_on).endOf('day').fromNow()})
          </p>
        )}
        {listing.photos.length === 0 && listing.isDraft && (
          <p className="text-danger">
            Add some photos to be able to publish your listing.
          </p>
        )}
        {listing.isPaused && (
          <p className="text-danger">
            Listing is paused and only visible to you
          </p>
        )}
        <ListingOwnerButtons listing={listing} />
      </Content>
    </Wrapper>
  );
};
