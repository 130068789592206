import React from 'react';
import { useStoreState } from 'easy-peasy';
import { useScrollToTopOnMount } from 'hooks';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

export default () => {
  useScrollToTopOnMount();
  const step = useStoreState((state) => state.newcar.step);
  const slug = useStoreState((state) => state.newcar.slug);

  const caruser = useStoreState((state) => state.session.carUserBySlug(slug));

  if (step === 1 || !caruser) return <Step1 />;

  return (
    <>
      {step === 2 && <Step2 caruser={caruser} />}
      {step === 3 && <Step3 caruser={caruser} />}
    </>
  );
};
