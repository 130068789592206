import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useDebounce } from 'react-use';
import styled from 'styled-components';
import { Input } from 'components/az';

const Content = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
`;

export default () => {
  const priceFrom = useStoreState((state) => state.listings.priceFrom);
  const priceTo = useStoreState((state) => state.listings.priceTo);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const [from, setFrom] = useState(priceFrom);
  const [to, setTo] = useState(priceTo);

  useEffect(() => {
    setFrom(priceFrom);
  }, [priceFrom]);

  useEffect(() => {
    setTo(priceTo);
  }, [priceTo]);

  useDebounce(
    () => {
      setData({ priceFrom: from, priceTo: to });
    },
    200,
    [from, to]
  );

  return (
    <Content>
      <Input
        type="number"
        value={from}
        step={1000}
        min={0}
        placeholder="Price from"
        onChange={(e) => setFrom(e.target.value)}
      />{' '}
      <Input
        type="number"
        value={to}
        step={1000}
        min={0}
        placeholder="to, NZD"
        onChange={(e) => setTo(e.target.value)}
      />
    </Content>
  );
};
