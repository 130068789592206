import React from 'react';
import SettingItem from 'components/SettingItem';
import Delete from './Delete';
import Archive from './Archive';
import Status from './Status';
import Expire from './Expire';

export default ({ listing }) => (
  <>
    <SettingItem>
      <Status listing={listing} />
    </SettingItem>
    {(listing.isPublished || listing.isPaused) && (
      <>
        <SettingItem>
          <Expire listing={listing} />
        </SettingItem>
        <SettingItem>
          <Archive listing={listing} />
        </SettingItem>
      </>
    )}
    {listing.isDraft && (
      <SettingItem>
        <Delete listing={listing} />
      </SettingItem>
    )}
  </>
);
