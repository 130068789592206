import React, { useState } from 'react';
import { Button, Textarea } from 'components/az';
import { useToggle } from 'react-use';
import { useStoreActions } from 'easy-peasy';
import { api } from 'api';
import { defErrorToast } from 'utility';
import Stars from 'components/Stars';

export default ({ request }) => {
  const setRequest = useStoreActions((action) => action.session.setRequest);
  const [loading, setLoading] = useToggle();

  const [rating, setRating] = useState(5);
  const [review, setReview] = useState('');

  const save = async () => {
    setLoading(true);
    try {
      const response = await api.postData(
        `/api/car-services/${request.id}/review/`,
        { review, rating }
      );
      setRequest(response);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  return (
    <div>
      <h3>Review</h3>
      <Button
        viewtype={rating === 1 ? 'red' : undefined}
        onClick={() => setRating(1)}
        className="mb10 mr10"
      >
        <Stars n={1} />
      </Button>
      <Button
        viewtype={rating === 2 ? 'red' : undefined}
        onClick={() => setRating(2)}
        className="mb10 mr10"
      >
        <Stars n={2} />
      </Button>
      <Button
        viewtype={rating === 3 ? 'red' : undefined}
        onClick={() => setRating(3)}
        className="mb10 mr10"
      >
        <Stars n={3} />
      </Button>
      <Button
        viewtype={rating === 4 ? 'red' : undefined}
        onClick={() => setRating(4)}
        className="mb10 mr10"
      >
        <Stars n={4} />
      </Button>
      <Button
        viewtype={rating === 5 ? 'red' : undefined}
        onClick={() => setRating(5)}
        className="mb10 mr10"
      >
        <Stars n={5} />
      </Button>

      <Textarea
        rows="5"
        className="mb10"
        value={review}
        onChange={(e) => setReview(e.target.value)}
        placeholder="Give your honest review"
      />
      <Button
        viewtype="red"
        loading={loading}
        onClick={save}
        disabled={!review}
        className="mr10"
      >
        Save
      </Button>
    </div>
  );
};
