import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import { useWider } from 'hooks';
import FadeInDiv from 'components/anim/FadeInDiv';
import GreyBody from 'components/GreyBody';
import WrappedPagination from 'components/WrappedPagination';
import Title from './Title';
import Locations from './Locations';
import Makes from './Makes';
import Models from './Models';
import { useUrl } from './hooks';
import Records from './Records';
import Filter from './Filter';

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;

  @media ${(props) => props.theme.devices.tablet} {
    padding: 0 20px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'main';
  align-items: stretch;

  @media ${(props) => props.theme.devices.laptop} {
    grid-template-columns: 3fr 2fr;
    grid-template-areas: 'main secondary';
  }
`;

const MainDiv = styled.div`
  grid-area: main;
  padding-top: 20px;

  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    'title'
    'filter'
    'listings'
    'pagination';

  @media ${(props) => props.theme.devices.laptop} {
    overflow: visible;
  }
`;

const SecondaryDiv = styled.div`
  grid-area: secondary;
  padding-left: 40px;
  padding-top: 30px;
  position: relative;
`;

const TitleDiv = styled.div`
  grid-area: title;
`;

const FilterDiv = styled.div`
  grid-area: filter;
`;

const ListingsDiv = styled.div`
  grid-area: listings;
`;

const PaginationDiv = styled.div`
  grid-area: pagination;
  justify-self: center;
  padding: 0 0 40px;
`;

const QuickFilterDiv = styled.div`
  position: sticky;
  top: 100px;
`;

const QFItem = styled.div`
  margin-bottom: 30px;
`;

export default () => {
  const isWider = useWider('laptop');
  const { make, model, region } = useStoreState((state) => state.listings);
  useUrl();

  return (
    <Wrapper>
      <Container>
        <GreyBody />
        <MainDiv>
          <TitleDiv>
            <Title />
          </TitleDiv>
          <FilterDiv>
            <Filter />
          </FilterDiv>

          <ListingsDiv>
            <Records />
          </ListingsDiv>

          <PaginationDiv>
            <WrappedPagination store="listings" />
          </PaginationDiv>
        </MainDiv>

        {isWider && (
          <SecondaryDiv>
            <QuickFilterDiv>
              {!region && (
                <QFItem>
                  <FadeInDiv>
                    <Locations />
                  </FadeInDiv>
                </QFItem>
              )}
              {!make && (
                <QFItem>
                  <FadeInDiv>
                    <Makes />
                  </FadeInDiv>
                </QFItem>
              )}
              {make && !model && (
                <QFItem>
                  <FadeInDiv>
                    <Models />
                  </FadeInDiv>
                </QFItem>
              )}
            </QuickFilterDiv>
          </SecondaryDiv>
        )}
      </Container>
    </Wrapper>
  );
};
