import React, { useState } from 'react';
import { Button } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { dumbErrorHandler } from 'utility';
import Input from 'react-phone-number-input/input';
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'code input'
    'button button' 'help help';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'code input button' 'help help help';
    width: 80%;
  }
`;

const FCode = styled.div`
  grid-area: code;
`;
const FInput = styled.div`
  grid-area: input;
`;
const FButton = styled.div`
  grid-area: button;
`;
const FHelp = styled.div`
  grid-area: help;
`;

const StyledInput = styled(Input)`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  background: #fff;
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 10px 15px;
  outline: none;
`;

const Form = ({ business, setEditMode }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );

  const [value, setValue] = useState(business.phone);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patchData(business.myApiAbsoluteUrl, {
        phone: value || '',
      });
      setMyBusiness(response);
      setEditMode(false);
      toast.success('The phone number has been saved');
    } catch (e) {
      setLoading(false);
      setError(dumbErrorHandler(e, 'phone'));
    }
  };

  return (
    <FormDiv>
      <FCode>+64</FCode>
      <FInput>
        <StyledInput
          placeholder="Phone"
          defaultCountry="NZ"
          value={value}
          onChange={setValue}
        />
        {error && <small className="text-danger">{error}</small>}
      </FInput>
      <FButton>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </FButton>
      <FHelp>
        <small className="text-secondary">
          National: {value && formatPhoneNumber(value)}
          <br />
          International: {value && formatPhoneNumberIntl(value)}
        </small>
      </FHelp>
    </FormDiv>
  );
};

export default ({ business }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Phone</h3>
        {on && <Form business={business} setEditMode={toggle} />}
        {!on &&
          (business.phone ? (
            <a href={`tel:${business.phone}`}>
              {formatPhoneNumberIntl(business.phone)}
            </a>
          ) : (
            <span className="text-secondary">n/a</span>
          ))}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
