import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useToggle, useClickAway, useDebounce } from 'react-use';
import { api } from 'api';
import { Button, Input } from 'components/az';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { colors } from 'styles/config';
import { get } from 'lodash';
import Popover from 'components/Popover';
import Loading from 'components/Loading';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;
const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
  }
`;
const HelpBlock = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 5px;
`;
const InputWrapper = styled.div`
  position: relative;
`;
const NoAddresses = styled.div`
  padding: 20px;
`;
const AddressesUl = styled.ul`
  list-style-type: none;
  li {
    border-bottom: 1px solid ${(props) => props.theme.border.color};
    button {
      display: block;
      border: none;
      padding: 15px 20px;
      background: white;
      text-align: left;
      font-size: 14px;
      color: ${(props) => props.theme.colors.text};
      cursor: pointer;

      &:hover {
        background: ${(props) => props.theme.bgcolors.yellow};
      }
    }
  }
`;

const { mapkit } = window;

const FormSection = ({ business, setEditMode }) => {
  const setMyBusiness = useStoreActions(
    (actions) => actions.session.setMyBusiness
  );

  const markerRef = useRef(null);
  const inputRef = useRef();
  const [value, setValue] = useState(business.address);
  const [map, setMap] = useState(null);
  const [coordinate, setCoordinate] = useState();
  const [mapid] = useState(uuid());
  const [on, toggle] = useToggle();
  const [addresses, setAddresses] = useState(null);
  const [loading, setLoading] = useState(false);

  const geocoderRef = useRef(
    new mapkit.Geocoder({
      language: 'en-GB',
      getsUserLocation: false,
    })
  );

  useClickAway(inputRef, () => {
    toggle(false);
  });

  const save = async () => {
    setLoading(true);
    try {
      const data = {
        address: value,
      };
      if (coordinate)
        data.location = `POINT (${coordinate.latitude} ${coordinate.longitude})`;
      const response = await api.patchData(business.myApiAbsoluteUrl, data);
      setMyBusiness(response);
      setEditMode(false);
      toast.success('The address has been saved');
    } catch (e) {
      setLoading(false);
    }
  };

  useDebounce(
    () => {
      if (value.length > 10 && business.address !== value) lookup();
    },
    2000,
    [value]
  );

  const lookup = () => {
    setAddresses(null);
    toggle(true);
    geocoderRef.current.lookup(
      value,
      (err, data) => {
        setAddresses(data.results);
      },
      { limitToCountries: 'NZ' }
    );
  };

  const saveAddress = (addr) => {
    toggle(false);
    setValue(addr.formattedAddress);
    setCoordinate(addr.coordinate);
  };

  useEffect(() => {
    const ll = get(business, ['location', 'coordinates']);
    if (ll) {
      setCoordinate(new mapkit.Coordinate(ll[0], ll[1]));
    }
  }, [business]);

  useEffect(() => {
    const newCoord = () => {
      setCoordinate(markerRef.current.coordinate);
    };

    if (coordinate) {
      if (markerRef.current !== null) {
        markerRef.current.coordinate = coordinate;
        if (map) map.setCenterAnimated(coordinate);
      } else {
        markerRef.current = new mapkit.MarkerAnnotation(coordinate, {
          draggable: true,
          color: colors.red,
          title: business.name,
        });
        map.showItems([markerRef.current]);
      }
      markerRef.current.addEventListener('drag-end', newCoord);
    }
    return () => {
      if (markerRef.current) {
        markerRef.current.removeEventListener('drag-end', newCoord);
      }
    };
  }, [business.name, coordinate, map]);

  useEffect(() => {
    const center = new mapkit.Coordinate(-40.0906, 174.886);
    const newMap = new mapkit.Map(mapid, {
      center,
      colorScheme: mapkit.Map.ColorSchemes.Light,
      mapType: mapkit.Map.MapTypes.MutedStandart,
    });
    newMap.cameraDistance = 1000000;
    newMap.center = center;
    setMap(newMap);
    const clickListener = (event) => {
      if (!event.shiftKey) {
        return;
      }

      setCoordinate(
        newMap.convertPointOnPageToCoordinate(
          new DOMPoint(event.pageX, event.pageY)
        )
      );
    };
    newMap.element.addEventListener('click', clickListener);
    return () => {
      newMap.element.removeEventListener('click', clickListener);
      newMap.destroy();
    };
  }, [mapid]);

  useEffect(() => {
    if (markerRef.current) {
      map.markers = [];
      map.showItems([markerRef.current]);
    }
  }, [map]);

  return (
    <>
      <FormDiv>
        <InputWrapper ref={inputRef}>
          <Input
            placeholder="Address"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onPressEnter={lookup}
          />
          {on && (
            <Popover noPadding top={50} placement="bottomLeft">
              {addresses === null ? (
                <Loading padding={20} />
              ) : (
                <>
                  <AddressesUl>
                    {addresses.map((addr) => (
                      <li key={addr.muid || addr.formattedAddress}>
                        <button type="button" onClick={() => saveAddress(addr)}>
                          {addr.formattedAddress}
                        </button>
                      </li>
                    ))}
                  </AddressesUl>
                  {addresses.length === 0 && (
                    <NoAddresses>No matches...</NoAddresses>
                  )}
                </>
              )}
            </Popover>
          )}
        </InputWrapper>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </FormDiv>
      <HelpBlock>
        To drop a marker use Shift+Click. To move a marker &mdash; Click and
        hold.
      </HelpBlock>
      <div id={mapid} style={{ height: 400 }} />
    </>
  );
};

export default ({ business }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Address and marker</h3>
        {on && <FormSection business={business} setEditMode={toggle} />}
        {!on &&
          (business.address ? (
            business.address
          ) : (
            <span className="text-secondary">n/a</span>
          ))}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
