import React from 'react';
import { useStoreState } from 'easy-peasy';
import styled from 'styled-components';
import Helmet from 'components/Helmet';
import { useScrollToTopOnMount } from 'hooks';

const Wrapper = styled.div`
  padding: 0 20px;

  @media ${(props) => props.theme.devices.tablet} {
    padding-top: 10px;
  }
`;

export default () => {
  useScrollToTopOnMount();

  const makeObj = useStoreState((state) => state.listings.makeObj);
  const modelObj = useStoreState((state) => state.listings.modelObj);
  const regionObj = useStoreState((state) => state.session.regionObj);

  let name = makeObj ? makeObj.name : 'a car';
  if (modelObj) {
    name = `${name} ${modelObj.name}`;
  }

  const what = makeObj ? makeObj.name : 'cars';
  const where = regionObj ? ` in ${regionObj.name}` : '';

  const title = makeObj ? name : 'Explore all cars';
  const subtitle = `Buy ${name}${where}`;

  const description = `Find used ${what} for sale${where} on AutoZealand. Buy and sell ${what}${where}. Transparent automarket for everyone.`;

  return (
    <>
      <Helmet canonical>
        <title>{subtitle}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Wrapper>
        {subtitle}
        <h1 className="mb0">{title}</h1>
      </Wrapper>
    </>
  );
};
