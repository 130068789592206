import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import DefSelect from "components/form/DefSelect";

export default () => {
  const value = useStoreState(state => state.listings.generation);
  const items = useStoreState(state => state.listings.generations);
  const setData = useStoreActions(actions => actions.listings.setData);

  const onChange = generation => setData({ generation });

  return (
    <label>
      Generation
      <DefSelect
        placeholder="Generation"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
};
