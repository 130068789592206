import React from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { transparentize } from 'polished';

const StyledSelect = styled.select`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  padding: ${(props) => props.sizeParams.padding};
  height: ${(props) => props.sizeParams.height};
  background: #fff;
  font-size: ${(props) => props.sizeParams.fontSize};
  display: block;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23CCCCCC%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  outline: none;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.block &&
    css`
      width: 100%;
      box-sizing: border-box;
    `};

  ::-ms-expand {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    &:not([disabled]) {
      border-color: ${(props) => props.theme.colors.grey4};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.colors.secondary};
    background: ${(props) => transparentize(0.5, props.theme.colors.grey6)};
    cursor: not-allowed;
  }
`;

const sizes = {
  default: {
    height: '40px',
    padding: '8px 30px 8px 15px',
    fontSize: '16px',
  },
  small: {
    height: '30px',
    padding: '5px 30px 5px 15px',
    fontSize: '16px',
  },
};

export default React.forwardRef((props, ref) => {
  const { size } = props;
  const sizeParams = get(sizes, size, sizes.default);
  return <StyledSelect ref={ref} sizeParams={sizeParams} {...props} />;
});
