import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { colors } from 'styles/config';

const StyledSpan = styled.span`
  color: ${colors.secondary};
`;

export default () => {
  return (
    <StyledSpan>
      <FontAwesomeIcon icon="circle-notch" spin />
    </StyledSpan>
  );
};
