import React from 'react';
import ListingCard from 'components/Cards/Listing/Mobile';
import styled from 'styled-components';
import Card from 'components/Card';
import { useStoreState } from 'easy-peasy';

const Container = styled.div`
  padding: 0 20px;
  min-height: 100%;
`;

const ListingDiv = styled.div`
  position: sticky;
  top: 30px;
`;

export default () => {
  const slug = useStoreState((state) => state.newcar.slug);
  const caruser = useStoreState((state) => state.session.carUserBySlug(slug));

  if (!caruser) return null;

  return (
    <Container>
      <ListingDiv>
        <Card className="mb20">
          <ListingCard listing={caruser} preview />
        </Card>
      </ListingDiv>
    </Container>
  );
};
