import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/az';
import { api } from 'api';
import { useStoreActions } from 'easy-peasy';

const Content = styled.div``;

export default ({ listing }) => {
  const setListing = useStoreActions((actions) => actions.session.setListing);

  const onClick = async () => {
    try {
      const response = await api.postData(
        `${listing.myApiAbsoluteUrl}archive/`
      );
      setListing(response);
    } catch (error) {
      // message
    }
  };

  return (
    <Content>
      <h3>Archive listing</h3>

      <Button onClick={onClick} viewtype="primary" size="small">
        Archive listing
      </Button>
      <p className="text-secondary mt10">
        <small>
          Archive lets you remove the listing without completely deleting it.
        </small>
      </p>
    </Content>
  );
};
