import React from 'react';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button } from 'components/az';
import { useParams } from 'react-router-dom';
import Helmet from 'components/Helmet';
import { api } from 'api';
import { useToggle } from 'react-use';
import { defErrorToast } from 'utility';
import ServiceStatusSections from 'components/Service/StatusSections';
import ServiceMessages from 'components/Service/Messages';
import ServiceContent from 'components/Service/Description';
import ServiceDetails from 'components/Service/Details';
import Card from './Card';
import NewDetails from './NewDetails';

const Container = styled.div`
  padding: 30px 0;
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
`;

const Section = styled.div`
  padding: 0 30px;
`;

const RequestView = () => {
  const { id } = useParams();
  const request = useStoreState((state) => state.session.requestById(id));
  const setRequest = useStoreActions((action) => action.session.setRequest);
  const [loading, setLoading] = useToggle();

  if (!request || !request.caruser) return null;

  const setStatus = async (action) => {
    setLoading(true);
    try {
      const response = await api.postData(
        `/api/service-requests/${request.id}/${action}/`
      );
      setRequest(response);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Service request</title>
      </Helmet>
      <Container>
        <Section>
          <Card request={request} />
        </Section>
        <ServiceContent request={request} />
        {request.status === 'PENDING' && (
          <Section>
            <Button
              viewtype="green"
              className="mr10"
              loading={loading}
              onClick={() => setStatus('accept')}
            >
              Accept
            </Button>
            <Button
              viewtype="red"
              loading={loading}
              onClick={() => setStatus('decline')}
            >
              Decline
            </Button>
          </Section>
        )}
        {['ACCEPTED', 'ARCHIVED'].includes(request.status) && (
          <div>
            <ServiceDetails
              request={request}
              nodel={request.status === 'ARCHIVED'}
            />
            {request.status === 'ACCEPTED' && <NewDetails request={request} />}
          </div>
        )}
        <ServiceStatusSections request={request} />
      </Container>
      <ServiceMessages request={request} />
    </>
  );
};

export default RequestView;
