/* eslint-disable no-param-reassign */
import { computed, action, thunkOn, thunk } from 'easy-peasy';
import { get, forEach } from 'lodash';
import UserModel from 'models/user';
import BusinessModel from 'models/business';
import CarUserModel from 'models/caruser';
import ThreadModel from 'models/thread';
import { api } from '../api';

const tokenKey = 'token';

const sessionModel = {
  initialised: false,
  user: null,
  myBusinesses: {},
  carusers: {},
  requests: {},
  threads: {},

  businesses: {},
  listings: {},

  token: null,
  region: null,

  // myDealers: computed((state) =>
  //   pickBy(state.myBusinesses, (value) => value.subtype === 'DEALER')
  // ),

  listingBySlug: computed((state) => (slug) => get(state.listings, [slug])),
  businessBySlug: computed((state) => (slug) => get(state.businesses, [slug])),

  myBusinessBySlug: computed((state) => (slug) =>
    get(state.myBusinesses, [slug])
  ),
  carUserBySlug: computed((state) => (slug) => get(state.carusers, [slug])),
  requestById: computed((state) => (id) => get(state.requests, [id])),
  threadById: computed((state) => (id) => get(state.threads, [id])),

  setMyBusiness: action((state, business) => {
    state.myBusinesses[business.slug] = new BusinessModel(business);
    const requests = get(business, ['requests'], []);
    requests.forEach((request) => {
      state.requests[request.id] = request;
    });
  }),
  setBusiness: action((state, business) => {
    state.businesses[business.slug] = new BusinessModel(business);
  }),
  setCarUser: action((state, payload) => {
    state.carusers[payload.slug] = new CarUserModel(payload);
    const requests = get(payload, ['requests'], []);
    requests.forEach((request) => {
      state.requests[request.id] = request;
    });
  }),
  setListing: action((state, payload) => {
    state.listings[payload.slug] = new CarUserModel(payload);
  }),
  setRequest: action((state, payload) => {
    state.requests[payload.id] = payload;
  }),
  setUser: action((state, user) => {
    state.user = new UserModel(user);
  }),
  setThread: action((state, payload) => {
    state.threads[payload.id] = new ThreadModel(payload);
  }),

  setCarPhotos: action((state, payload) => {
    const { slug } = payload;
    if (state.carUserBySlug(slug)) {
      state.carusers[slug].photos = payload.photos;
      state.carusers[slug] = new CarUserModel(state.carusers[slug]);
    }
  }),

  messages: computed((state) => (id) => get(state.threads, [id], [])),

  regionObj: computed(
    [(state, storeState) => storeState.core.regionBySlug(state.region)],
    (obj) => obj
  ),
  regionUrlPrefix: computed((state) =>
    state.region ? `/${state.region}` : ''
  ),

  isAuthed: computed((state) => state.user !== null),

  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = value;
    });
  }),

  doCnt: action((state, payload) => {
    if (state.isAuthed) {
      state.user[payload.field] =
        get(state, ['user', payload.field], 0) + payload.number;
      state.user = new UserModel(state.user);
    }
  }),

  setThreadsData: action((state, { id, messages }) => {
    state.threads[id] = messages;
  }),

  setToken: action((state, payload) => {
    state.token = payload;
    api.updateToken(payload);
    localStorage.setItem(tokenKey, payload);
  }),

  signOut: action((state, _) => {
    state.user = null;
    state.token = null;
    state.threads = {};
    state.businesses = {};
    state.carusers = {};
    state.listings = {};
    localStorage.removeItem(tokenKey);
  }),

  loadProfile: thunk(async (actions, _) => {
    const data = await api.getData('/api/profile/');
    actions.setUser(data);
  }),
  loadThread: thunk(async (actions, id) => {
    const data = await api.getData(`/api/threads/${id}/`);
    actions.setThread(data);
  }),
  loadListing: thunk(async (actions, slug) => {
    const data = await api.getData(`/api/listings/${slug}/`);
    actions.setListing(data);
  }),
  loadBusiness: thunk(async (actions, slug) => {
    const data = await api.getData(`/api/businesses/${slug}/`);
    actions.setBusiness(data);
  }),
  loadCarUser: thunk(async (actions, slug) => {
    const data = await api.getData(`/api/my/cars/${slug}/`);
    actions.setCarUser(data);
  }),
  loadMyBusiness: thunk(async (actions, slug) => {
    const data = await api.getData(`/api/my/businesses/${slug}/`);
    actions.setMyBusiness(data);
  }),

  setCarUserStatus: thunk(async (actions, payload) => {
    const caruser = await api.postData(
      `/api/my/cars/${payload.slug}/${payload.status}/`
    );
    actions.setCarUser(caruser);
  }),

  onSetToken: thunkOn(
    (actions) => actions.setToken,
    async (actions, target) => {
      await actions.loadProfile();
    }
  ),
};

export default sessionModel;
