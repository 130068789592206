import React from 'react';
import { useSpring, animated } from 'react-spring';

export default ({ children, slow }) => {
  const style = useSpring({
    config: { duration: slow ? 600 : 300 },
    opacity: 1,
    from: { opacity: 0 },
  });

  return <animated.div style={style}>{children}</animated.div>;
};
