import React from 'react';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';
import { colors } from 'styles/config';

const Component = styled.div`
  color: ${colors.error};
  font-size: 14px;
  padding: 5px 0;
`;

export default props => <ErrorMessage component={Component} {...props} />;
