/* eslint-disable no-param-reassign */
import { action, computed, thunk } from 'easy-peasy';
import { get, keys, pick, forEach, isUndefined } from 'lodash';
import qs from 'qs';
import { carListingsUrl } from 'utility';
import { api } from 'api';
import CarUserModel from 'models/caruser';

const matrix = {
  region: 'region_slug',
  make: 'make_slug',
  model: 'model_slug',
  generation: 'generation',
  body: 'body_slug',
  gearbox: 'gearbox_slug',
  drive: 'drive_slug',
  engineType: 'engine_type_slug',
  priceFrom: 'price_from',
  priceTo: 'price_to',
  mileageFrom: 'mileage_from',
  mileageTo: 'mileage_to',
  yearFrom: 'year_from',
  yearTo: 'year_to',
  page: 'page',
  page_size: 'page_size',
};

const defSearchParams = {
  generation: null,

  body: null,
  gearbox: null,
  drive: null,
  engineType: null,

  priceFrom: null,
  priceTo: null,

  mileageFrom: null,
  mileageTo: null,

  yearFrom: null,
  yearTo: null,
};

const defParams = {
  make: null, // slug
  model: null,
  page: 1,
  page_size: 10,

  ...defSearchParams,
};

export default {
  makesCnt: [],
  modelsCnt: [],

  regionStats: {},
  makeStats: {},
  modelStats: {},

  ...defParams,

  records: null,
  loading: false,
  updating: false,
  location: null,
  count: 0,

  region: computed([(_, storeState) => storeState.session.region], (x) => x),
  makeObj: computed(
    [(state, storeState) => storeState.core.makeBySlug(state.make)],
    (x) => x
  ),

  mmgName: computed((state) => {
    if (!state.makeObj) return null;

    let name = `${state.makeObj.name}`;
    if (state.modelObj) {
      name = `${name} ${state.modelObj.name}`;
      if (
        state.generationObj &&
        state.generationObj.name !== state.modelObj.name
      ) {
        name = `${name} ${state.generationObj.name}`;
      }
    }
    return name;
  }),

  apiParamsObj: computed(
    [(state) => state, (_, storeState) => storeState.session.region],
    (state, region) => {
      const toReturn = {};
      forEach(matrix, (value, key) => {
        toReturn[value] = key === 'region' ? region : state[key];
      });
      return toReturn;
    }
  ),

  searchParamsObj: computed((state) => pick(state, keys(defSearchParams))),

  modelObj: computed(
    [(state) => state.model, (state) => state.models],
    (model, models) => {
      return get(models, ['bySlug', model], null);
    }
  ),

  makeId: computed(
    [(state) => state.make, (_, storeState) => storeState.core.makes],
    (make, makes) => get(makes, ['bySlug', make, 'id'], null)
  ),
  modelId: computed(
    [
      (state) => state.makeId,
      (state) => state.model,
      (_, storeState) => storeState.core.makeModels,
    ],
    (makeId, model, makeModels) =>
      get(makeModels, [makeId, 'bySlug', model, 'id'], null)
  ),
  models: computed(
    [(state) => state.makeId, (_, storeState) => storeState.core.makeModels],
    (makeId, makeModels) => get(makeModels, [makeId], null)
  ),
  generations: computed(
    [
      (state) => state.modelId,
      (_, storeState) => storeState.core.modelGenerations,
    ],
    (modelId, modelGenerations) => get(modelGenerations, [modelId], null)
  ),

  // use it when you need to set anything
  setData: action((state, payload) => {
    forEach(payload, (value, key) => {
      state[key] = isUndefined(value) ? null : value;
    });
  }),

  // use it when other search (!) parameters should be reset
  setParamsData: action((state, payload) => {
    forEach(defParams, (value, key) => {
      state[key] = get(payload, [key], value);
    });
  }),

  buildLocation: action((state, _) => {
    const { region, make, model } = state;
    const pathname = carListingsUrl(region, make, model);
    const searchParamsObj = pick(state, keys(defSearchParams));
    const search = qs.stringify(searchParamsObj, {
      skipNulls: true,
      addQueryPrefix: true,
    });
    state.location = { pathname, search, state: { noParse: true } };
  }),

  fetch: thunk(async (actions, _, { getState, getStoreActions }) => {
    actions.setData({ updating: true });
    actions.buildLocation();
    const state = getState();
    const data = await api.getData('/api/listings/', state.apiParamsObj);

    const { setCarUser } = getStoreActions().session;
    if (data.results) {
      data.results.forEach((item) => setCarUser(new CarUserModel(item)));
    }

    actions.setData({
      records: data.results
        ? data.results.map((item) => new CarUserModel(item))
        : [],
      count: data.count,
      updating: false,
    });
    const stats = await api.getData('/api/listings/count/', state.apiParamsObj);
    actions.setData({
      regionStats: stats.regions || {},
      makeStats: stats.makes || {},
      modelStats: stats.models || {},
    });
  }),
};
