import React from 'react';
import { useStoreState } from 'easy-peasy';
import { get } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ obj, showIcon }) => {
  const regions = useStoreState((state) => state.core.regions);
  const districts = useStoreState((state) => state.core.districts);

  const region = get(regions, ['byId', obj.region, 'name'], '');
  if (!region) return null;

  const district = get(districts, ['byId', obj.district, 'name'], '');

  return (
    <>
      {showIcon && (
        <FontAwesomeIcon icon="map-pin" className="mr05em text-red" />
      )}
      {region}
      {district && `, ${district}`}
    </>
  );
};
