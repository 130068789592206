import React from 'react';
import styled from 'styled-components';
import Helmet from 'components/Helmet';
import { Link } from 'react-router-dom';

const Container = styled.div`
  a {
    color: ${(props) => props.theme.colors.text};
  }
`;

export default ({ listing }) => {
  return (
    <Container>
      <Helmet canonical>
        <title>{listing.fullTitle}</title>
      </Helmet>
      <Link to="/my/listings">My listings</Link>
      <h1>{listing.fullTitle}</h1>
    </Container>
  );
};
