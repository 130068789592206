import React from 'react';
import { Alert } from 'components/az';

export default () => {
  return (
    <Alert type="yellow">
      The business is in the <strong>PENDING</strong> status. We will check and
      activate it soon.
    </Alert>
  );
};
