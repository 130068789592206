import React, { useCallback } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import DefSelect from "components/form/DefSelect";

export default function Model() {
  const make = useStoreState(state => state.listings.make);
  const value = useStoreState(state => state.listings.model);
  const items = useStoreState(state => state.listings.models);
  const setData = useStoreActions(actions => actions.listings.setData);

  const onChange = useCallback(
    model => {
      setData({ makeSlug: make, model, generation: null });
    },
    [setData, make]
  );

  return (
    <label>
      Model
      <DefSelect
        popular
        placeholder="Model"
        value={value}
        items={items}
        onChange={onChange}
      />
    </label>
  );
}
