import React from 'react';
import styled from 'styled-components';
// import { colors } from 'styles/config';

const Component = styled.div`
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
    display: block;
  }
`;

export default props => <Component {...props} />;
