import React, { useState } from 'react';
import { Button, Input } from 'components/az';
import styled from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { get } from 'lodash';
import { useToggle } from 'react-use';
import { api } from 'api';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'first' 'last' 'button';
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr 1fr auto;
    grid-template-areas: 'first last button';
  }
`;
const FirstNameDiv = styled.div`
  grid-area: first;
`;
const LastNameDiv = styled.div`
  grid-area: last;
`;
const ButtonDiv = styled.div`
  grid-area: button;
`;

const Form = ({ currentFirstName, currentLastName, toggle }) => {
  const setUser = useStoreActions((actions) => actions.session.setUser);

  const [firstName, setFirstName] = useState(currentFirstName);
  const [lastName, setLastName] = useState(currentLastName);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.patchData('/api/profile/', {
        first_name: firstName,
        last_name: lastName,
      });
      setUser(response);
      toggle(false);
      toast.success('The name has been saved');
    } catch (e) {
      setLoading(false);
      setError(
        get(
          e,
          ['response', 'data', 'non_field_errors'],
          ['An error occurred please try again']
        ).join(', ')
      );
    }
  };

  const Error = error && <small className="text-danger">{error}</small>;

  return (
    <FormDiv>
      <FirstNameDiv>
        <Input
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          onPressEnter={save}
        />
        {Error}
      </FirstNameDiv>
      <LastNameDiv>
        <Input
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          onPressEnter={save}
        />
        {Error}
      </LastNameDiv>
      <ButtonDiv>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </ButtonDiv>
    </FormDiv>
  );
};

export default () => {
  const [on, toggle] = useToggle();
  const user = useStoreState((state) => state.session.user);

  return (
    <Content>
      <div>
        <h3>Name</h3>
        {on ? (
          <Form
            currentFirstName={user.first_name}
            currentLastName={user.last_name}
            toggle={toggle}
          />
        ) : (
          user.realname || <span className="text-secondary">n/a</span>
        )}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
