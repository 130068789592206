import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Make from './Fields/Make';
import Model from './Fields/Model';
import Generation from './Fields/Generation';
import FilterButton from './FilterButton';

export const MakeLogic = () => {
  const make = useStoreState((state) => state.listings.make);
  const model = useStoreState((state) => state.listings.model);

  return (
    <ul>
      <li>
        <Make />
      </li>
      {make && (
        <li>
          <Model />
        </li>
      )}
      {model && (
        <li>
          <Generation />
        </li>
      )}
    </ul>
  );
};

export default () => {
  const mmgName = useStoreState((state) => state.listings.mmgName);
  const setData = useStoreActions((actions) => actions.listings.setData);

  const name = mmgName || 'Make';
  const onClear = () => {
    setData({ make: null, model: null, generation: null });
  };

  return (
    <FilterButton
      name={name}
      content={<MakeLogic />}
      filtered={mmgName !== null}
      onClear={onClear}
    />
  );
};
