import React, { useState } from 'react';
import { Button, Input } from 'components/az';
import styled from 'styled-components';
import { useStoreActions } from 'easy-peasy';
import { useToggle } from 'react-use';
import { api } from 'api';
import { dumbErrorHandler } from 'utility';
import { toast } from 'react-toastify';

const Content = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr auto;
`;

const FormDiv = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media ${(props) => props.theme.devices.tablet} {
    grid-template-columns: 1fr auto;
    width: 66.6%;
  }
`;

const Form = ({ caruser, toggle }) => {
  const setCarUser = useStoreActions((actions) => actions.session.setCarUser);

  const [value, setValue] = useState(caruser.mileage);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    setError(null);
    try {
      const newCarUser = await api.postData(`${caruser.myApiAbsoluteUrl}mileage/`, {
        mileage: value,
      });
      setCarUser(newCarUser);
      toggle(false);
      toast.success('The mileage has been saved');
    } catch (e) {
      setLoading(false);
      setError(dumbErrorHandler(e, 'mileage'));
    }
  };

  const Error = error && <small className="text-danger">{error}</small>;

  return (
    <FormDiv>
      <div>
        <Input
          placeholder="Mileage"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={save}
          step={1}
          autoComplete="off"
          type="number"
          min={0}
        />
        {Error}
      </div>
      <div>
        <Button viewtype="primary" loading={loading} onClick={save}>
          Save
        </Button>
      </div>
    </FormDiv>
  );
};

export default ({ caruser }) => {
  const [on, toggle] = useToggle();

  return (
    <Content>
      <div>
        <h3>Mileage, km</h3>
        {on ? (
          <Form caruser={caruser} toggle={toggle} />
        ) : (
          caruser.formattedMileage
        )}
      </div>
      <div>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </div>
    </Content>
  );
};
