import React from 'react';
import styled from 'styled-components';
import { transparentize } from 'polished';

const StyledTextarea = styled.textarea`
  border-radius: ${(props) => props.theme.border.radius};
  border: 1px solid ${(props) => props.theme.border.color};
  background: #fff;
  display: block;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  outline: none;
  transition: all 0.2s ease-in-out;
  &::placeholder {
    color: ${(props) => props.theme.colors.grey2};
  }

  &:hover,
  &:focus,
  &:active {
    &:not([disabled]) {
      border-color: ${(props) => props.theme.colors.grey4};
    }
  }

  &:disabled {
    color: ${(props) => props.theme.colors.grey2};
    background: ${(props) => transparentize(0.5, props.theme.colors.grey6)};
    cursor: not-allowed;
  }
`;

export default React.forwardRef((props, ref) => {
  return <StyledTextarea ref={ref} {...props} />;
});
