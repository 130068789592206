import React from 'react';
import { Button } from 'components/az';
import styled from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useToggle, useMeasure } from 'react-use';
import EmailAuth from 'components/EmailAuth';
import { toast } from 'react-toastify';
import { ellipsis } from 'polished';

const Content = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto;
`;

const EmailDiv = styled.div``;

const EllipsisDiv = styled.div`
  ${ellipsis()}
  width: ${(props) => props.mw}px;
`;

const ButtonDiv = styled.div`
  justify-self: end;
`;

export default () => {
  const [on, toggle] = useToggle();
  const [ref, { width }] = useMeasure();

  const user = useStoreState((state) => state.session.user);
  const callback = () => {
    toggle();
    toast.success('The email has been saved');
  };
  return (
    <Content>
      <EmailDiv ref={ref}>
        <h3>Email</h3>
        {on ? (
          <EmailAuth
            currentEmail={user.email}
            type="verify"
            callback={callback}
          />
        ) : (
          <>
            <EllipsisDiv mw={width - 20}>{user.email}</EllipsisDiv>
            <br />
            <small className="text-secondary">
              Your email is confidential and we won&apos;t share it with anyone
            </small>
          </>
        )}
      </EmailDiv>
      <ButtonDiv>
        <Button onClick={toggle}>{on ? 'Cancel' : 'Edit'}</Button>
      </ButtonDiv>
    </Content>
  );
};
