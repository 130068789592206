import React from 'react';
import { get } from 'lodash';
import styled from 'styled-components';

const Wrapper = styled.div`
  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 40%;
    color: ${(props) => props.theme.colors.secondary};
    margin-bottom: 5px;
  }

  dd {
    width: 60%;
    margin-bottom: 5px;
  }
`;

export default (props) => {
  const car = get(props, ['caruser', 'car']);

  return (
    <Wrapper>
      <dl>
        <dt>Make</dt>
        <dd>{get(car, ['make', 'name'])}</dd>

        <dt>Model</dt>
        <dd>{get(car, ['model', 'name'])}</dd>

        <dt>Generation</dt>
        <dd>{get(car, ['generation', 'name'])}</dd>

        <dt>Year</dt>
        <dd>{get(car, 'year')}</dd>

        <dt>Colour</dt>
        <dd>{get(car, ['colour', 'name'])}</dd>

        <dt>Body</dt>
        <dd>{get(car, ['body', 'name'])}</dd>

        <dt>Engine</dt>
        <dd>{get(car, ['trim', 'trim'])}</dd>

        <dt>Engine type</dt>
        <dd>{get(car, ['engine_type', 'name'])}</dd>

        <dt>Drive</dt>
        <dd>{get(car, ['drive', 'name'])}</dd>

        <dt>Transmission</dt>
        <dd>{get(car, ['gearbox', 'name'])}</dd>

        <dt>Origin</dt>
        <dd>{get(car, ['nznew']) ? 'NZ new' : 'Imported'}</dd>
      </dl>
    </Wrapper>
  );
};
