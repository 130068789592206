import React, { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/az';
import { useToggle, useClickAway } from 'react-use';
import styled from 'styled-components';
import { ClearButton } from 'components/az/Button';

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  top: 54px;
  left: 0;
  background: white;
  /* box-shadow: ${(props) => props.theme.shadow.strong}; */
  box-shadow: ${(props) => props.theme.shadow.hover};
  width: 360px;
  z-index: 1100;
  padding: 15px;
  border-radius: 20px;

  ul {
    padding: 0;
    margin: 0;
    column-count: 2;

    li {
      /* display: inline-block; */
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;

      svg {
        margin-right: 0.5em;
      }
    }
  }
  footer {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
`;

const MenuButton = styled.div`
  display: block;
  padding: 10px 15px;
  border-radius: 10px;
  background: #fff;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover,
  &.active {
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.error};
  }
`;

export default () => {
  const regionObj = useStoreState((state) => state.session.regionObj);
  const regions = useStoreState((state) => state.core.regions);
  const setData = useStoreActions((actions) => actions.session.setData);

  const ref = useRef(null);
  const [on, toggle] = useToggle(false);

  useClickAway(ref, () => {
    toggle(false);
  });

  const save = (region) => {
    if (regionObj && region === regionObj.slug) {
      setData({ region: null });
    } else {
      setData({ region });
    }
  };

  return (
    <Wrapper ref={ref}>
      <Button onClick={toggle} active={!!regionObj} shape="round">
        {regionObj ? (
          regionObj.name
        ) : (
          <>
            <FontAwesomeIcon icon="map-pin" /> All
          </>
        )}
      </Button>
      {on && (
        <div>
          <Content>
            <ul>
              {regions.allIds.map((id) => (
                <li key={id}>
                  <MenuButton
                    className={regionObj && regionObj.id === id ? 'active' : ''}
                    onClick={() => save(regions.byId[id].slug)}
                  >
                    {regionObj && regionObj.id === id && (
                      <FontAwesomeIcon icon="map-pin" />
                    )}
                    {regions.byId[id].name}
                  </MenuButton>
                </li>
              ))}
            </ul>

            <footer>
              {regionObj ? <ClearButton onClick={() => save(null)} /> : <div />}
              <Button viewtype="primary" onClick={toggle}>
                Save
              </Button>
            </footer>
          </Content>
        </div>
      )}
    </Wrapper>
  );
};
