import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import styled from 'styled-components';
import Upload from 'components/Upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, concat } from 'lodash';
import { Button } from 'components/az';
import { animated } from 'react-spring';
import { Transition } from 'react-spring/renderprops';
import { buildImageUrl } from 'utility';
import { api } from 'api';
import { useUpdate } from 'react-use';
import { toast } from 'react-toastify';
import { borderRadius } from 'polished';
import Switch from 'components/Switch';
import WhiteContent from 'components/WhiteContent';

const transform = {
  resize: {
    width: 800,
    height: 600,
    fit: 'contain',
    background: {
      r: 0,
      g: 0,
      b: 0,
      alpha: 1,
    },
  },
  rotate: null,
};

const UploadSection = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
  margin-bottom: 30px;
  align-items: center;
`;

const IndicatorSection = styled.div``;

const PhotoGrid = styled.div`
  display: grid;
  gap: 30px 20px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const ImageBlock = styled(animated.div)`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
`;

const PictureSection = styled.picture`
  img {
    ${borderRadius('top', '10px')}
    display: block;
    max-width: 100%;
  }
`;
const ExtraSection = styled.div`
  padding: 0 10px;
  ${borderRadius('bottom', '10px')}
  background: ${(props) => props.theme.colors.background};
  display: grid;
  grid-template-columns: 1fr;
  font-size: 14px;
`;

const ExtraRow = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${(props) => props.theme.border.color};
  align-items: center;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr auto;
`;

const ButtonRow = styled.div`
  padding: 10px 0;
`;

export default ({ caruser, sellmode }) => {
  const setCarPhotos = useStoreActions(
    (actions) => actions.session.setCarPhotos
  );
  const url = caruser.photosApiAbsoluteUrl;
  const [photos, setPhotos] = useState(caruser.photos || []);
  const update = useUpdate();
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCarPhotos({ slug: caruser.slug, photos });
  }, [caruser.slug, photos, setCarPhotos]);

  const publicVis = async (photo) => {
    setPhotos((state) =>
      state.map((x) =>
        x.id !== photo.id ? x : { ...x, is_public: !x.is_public }
      )
    );
    await api.patchData(`${url}${photo.id}/`, { is_public: !photo.is_public });
  };

  const listingVis = async (photo) => {
    setPhotos((state) =>
      state.map((x) =>
        x.id !== photo.id ? x : { ...x, is_listing: !x.is_listing }
      )
    );
    await api.patchData(`${url}${photo.id}/`, {
      is_listing: !photo.is_listing,
    });
  };

  const deletePhoto = async (photo) => {
    setPhotos((state) => filter(state, (x) => x.id !== photo.id));
    await api.deleteData(`${url}${photo.id}/`);
  };

  const uploadProps = {
    name: 'photo',
    accept: '.jpg, .jpeg, .png',
    multiple: true,
    onStart(file) {
      setCount((state) => state + 1);
    },
    onSuccess(response, file) {
      setCount((state) => state - 1);
      setPhotos((state) => concat(state, response));
    },
    onError(err) {
      setCount((state) => state - 1);
      toast.error('An error happened while uploading one of the photos.');
    },
    onProgress({ percent }, file) {
      // nothing so far
    },
    customRequest({ file, filename, onError, onProgress, onSuccess }) {
      const formData = new FormData();
      formData.append(filename, file);
      formData.append(caruser.field, caruser.car.id);
      if (sellmode) {
        formData.append('is_listing', true);
      }

      api.axios
        .post(url, formData, {
          onUploadProgress: ({ total, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file
            );
            update();
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(onError);

      return {
        abort() {
          // console.log('upload progress is aborted.');
        },
      };
    },
  };

  return (
    <WhiteContent>
      <UploadSection>
        <Upload {...uploadProps}>
          <Button icon="plus-circle">Upload photos</Button>
        </Upload>
        <IndicatorSection>
          {count > 0 && (
            <>
              <FontAwesomeIcon icon="circle-notch" spin /> Uploading {count}{' '}
              photo{count > 1 && 's'}
            </>
          )}
        </IndicatorSection>
      </UploadSection>
      <PhotoGrid>
        <Transition
          items={photos}
          keys={(item) => item.id}
          trail={50}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
        >
          {(item) => (props) => (
            <ImageBlock style={props}>
              <PictureSection>
                <img src={buildImageUrl(item.photo, transform)} alt={item.id} />
              </PictureSection>

              <ExtraSection>
                {!sellmode && (
                  <>
                    <ExtraRow>
                      Visible in my profile
                      <Switch
                        size="small"
                        checked={item.is_public}
                        onChange={() => publicVis(item)}
                      />
                    </ExtraRow>
                    {!caruser.isNone && (
                      <ExtraRow>
                        Use for the listing
                        <Switch
                          size="small"
                          checked={item.is_listing}
                          onChange={() => listingVis(item)}
                        />
                      </ExtraRow>
                    )}
                  </>
                )}
                <ButtonRow>
                  <Button
                    viewtype="red"
                    size="small"
                    icon="trash-alt"
                    onClick={() => deletePhoto(item)}
                  >
                    Delete
                  </Button>
                </ButtonRow>
              </ExtraSection>
            </ImageBlock>
          )}
        </Transition>
        {photos.length === 0 && (
          <span className="text-secondary">
            You have not uploaded any photos yet.
          </span>
        )}
      </PhotoGrid>
    </WhiteContent>
  );
};
