import React, { useState } from 'react';
import styled from 'styled-components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import Helmet from 'components/Helmet';
import ServiceStatusSections from 'components/Service/StatusSections';
import ServiceMessages from 'components/Service/Messages';
import { api } from 'api';
import { defErrorToast } from 'utility';
import { Button } from 'components/az';
import ServiceContent from 'components/Service/Description';
import Review from './Review';
import Card from './Card';
import ServiceDetails from 'components/Service/Details';

const Container = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
  padding: 30px 0;
`;

const Section = styled.div`
  padding: 0 30px;
`;

const ServiceView = () => {
  const { id } = useParams();
  const request = useStoreState((state) => state.session.requestById(id));
  const setRequest = useStoreActions((action) => action.session.setRequest);
  const [loading, setLoading] = useState(false);

  const cancel = async () => {
    setLoading(true);
    try {
      const response = await api.postData(
        `/api/car-services/${request.id}/cancel/`
      );
      setRequest(response);
    } catch (error) {
      defErrorToast();
    }
    setLoading(false);
  };

  if (!request || !request.caruser) return null;

  return (
    <>
      <Helmet>
        <title>Service request</title>
      </Helmet>
      <Container>
        <Section>
          <Card request={request} />
        </Section>
        <ServiceContent request={request} />
        {['PENDING', 'ACCEPTED'].includes(request.status) && (
          <Section>
            <Button
              icon={['fas', 'times-circle']}
              loading={loading}
              className="mr10"
              onClick={cancel}
            >
              Withdraw request
            </Button>
          </Section>
        )}
        <ServiceDetails nodel request={request} />
        <ServiceStatusSections request={request} />
        {request.status === 'ARCHIVED' && request.rating === 0 && (
          <Section>
            <Review request={request} />
          </Section>
        )}
      </Container>
      <ServiceMessages request={request} />
    </>
  );
};

export default ServiceView;
